import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";

import {
  ContactViewerAction,
  OthersProfileAction,
  ProfileAction,
} from "../../store/actions";

import CardBottomButton from "../../components/dashboardcard/cardbottombutton";
import Spacer from "../../components/spacer/spacer";
import { WhiteButton, PrimaryButton } from "../../components/button";

import AboutMeContact from "./aboutmecontact";

import images from "../../assets";
import "./styles.css";

export default function AboutMe(props) {
  const dispatch = useDispatch();

  const activePlan = useSelector(
    (state) => state?.profile?.profile?.activePlan
  );

  const [isOpen, setOpen] = useState(false);

  const handleViewContact = () => {
    const payload = { viewedId: props?.othersProfileData?.username };

    dispatch(
      ContactViewerAction.setContactView(payload, () => {
        const payloadContactView = {
          filter: "Contacts Viewed by Me",
        };
        const payload = { user: props?.othersProfileData?.username };
        dispatch(ContactViewerAction.getAllContactView(payloadContactView));
        dispatch(OthersProfileAction.getOthersProfile(payload));
        dispatch(ProfileAction.getProfile({}));
      })
    );
  };

  return (
    <div className="about-me-card">
      <div className="about-me-main">
        <div className="about-me">
          <h2>About {props?.othersProfileData?.firstName}</h2>
          <p>Profile created by {props?.othersProfileData?.profileCreatedBy}</p>
        </div>
        <div className="edit-profile"></div>
      </div>
      <div className="about-me-number-email">
        <AboutMeContact viewed={props?.contactViewed}>
          <CardBottomButton icon={images.phoneCallIcon} />
          <div className="otherpro-phone-call-icon">
            <p>
              {props?.othersProfileData?.phone}
              {props?.othersProfileData?.gender === "female" &&
                props?.othersProfileData?.isContactHidden && (
                  <>
                    <br />
                    <span>
                      The user has chosen to keep her number hidden, but you can
                      still use the chat and send her an email to reach out.
                    </span>
                  </>
                )}
            </p>
          </div>
        </AboutMeContact>
        <hr />
        <Spacer height="15px" />
        {!activePlan?.plan && (
          <>
            <AboutMeContact>
              <CardBottomButton icon={images.fullProfileIcon} />
              <p>Full Profile Access</p>
            </AboutMeContact>
            <hr />
            <Spacer height="15px" />
          </>
        )}
        {!activePlan?.plan && (
          <>
            <AboutMeContact>
              <CardBottomButton icon={images.cardMessage} />
              <p>Unlimited Messaging</p>
            </AboutMeContact>
            <hr />
            <Spacer height="15px" />
          </>
        )}
        <AboutMeContact viewed={props?.contactViewed}>
          <CardBottomButton icon={images.emailIcon} />
          <p>{props?.othersProfileData?.email}</p>
        </AboutMeContact>
        {!props?.contactViewed && (
          <>
            <hr />
            <div className="promo-code-discount">
              {!activePlan?.plan ? //   <span> // <div>
              //     Enter Promo Code
              //     <br />
              //   </span>
              //   <span
              //     style={{
              //       color: "#BA0060",
              //       fontWeight: 600,
              //     }}
              //   >
              //     Fast20
              //   </span>
              //   <span> to get discount!</span>
              // </div>
              null : (
                <div>
                  {activePlan?.plan !== "PP03" ? (
                    <>
                      {!props?.contactViewed
                        ? `Only ${activePlan?.contactViewLimit} contacts view left`
                        : null}
                    </>
                  ) : null}
                </div>
              )}
              {!activePlan?.plan ? (
                <a
                  className="w-[157px] h-10 px-4 bg-fuchsia-950 rounded-xl shadow justify-center items-center flex"
                  href="/membership-plan"
                >
                  <div className="text-white font-medium">View Plans</div>
                </a>
              ) : (
                <div
                  className="w-[157px] h-10 px-4 bg-fuchsia-950 rounded-xl shadow justify-center items-center flex cursor-pointer"
                  onClick={() => setOpen(true)}
                >
                  <div className="text-white font-medium">View Contact</div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {props?.othersProfileData?.profileDescription && (
        <div
          className="about-me-desc-area"
          title={props?.othersProfileData?.profileDescription}
        >
          {props?.othersProfileData?.profileDescription && (
            <p
              className="about-me-content"
              title={props?.othersProfileData?.profileDescription}
            >
              {props?.othersProfileData?.profileDescription}
            </p>
          )}
        </div>
      )}
      <Modal
        centered={true}
        open={isOpen}
        onCancel={() => setOpen(false)}
        footer={false}
        closable={true}
      >
        <div className="gap-4 set-profile-photo-content">
          <div className="set-profile-photo-icon">
            <img style={{ maxWidth: "40px" }} src={images?.deleteIcon} alt="" />
          </div>
          <div className="set-profile-photo-heading">
            <h2>Confirm Contact View?</h2>
            <p>
              After viewing this contact, you will have{" "}
              {activePlan?.contactViewLimit} more contacts view left from your
              limit.
            </p>
          </div>
        </div>
        <Spacer height="16px" />
        <div className="flex w-full justify-end">
          <div className="flex gap-4 set-profile-photo-btn">
            <WhiteButton text="Cancel" onClick={() => setOpen(false)} />
            <PrimaryButton
              style={{ background: "#F31260" }}
              text="View"
              onClick={handleViewContact}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
