import ImproveMatchInfo from "../../components/profilecard/improvematchinfo";
import PrimaryButton from "../../components/button/primarybutton";

import getMaritalStatusLabel from "../../utils/getmaritalstatuslabel";
import convertedCentoFeet from "../../utils/convertdenttofeet";
import calculateage from "../../utils/calculateage";

import images from "../../assets";
import "./styles.css";

const renderValues = (array) => {
  const value = array?.map((item, index) => {
    if (index === 0) {
      return item;
    } else {
      return `, ${item}`;
    }
  });
  return value;
};

export default function ImproveMatchCard(props) {
  const birthDate = calculateage(props?.othersProfileData?.dateOfBirth);

  const feetDataMin = convertedCentoFeet(props?.myMatchPrefData?.heightMin);
  const feetDataMax = convertedCentoFeet(props?.myMatchPrefData?.heightMax);

  return (
    <div className="improve-match-card-area">
      <div
        style={{
          background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
          backgroundSize: "100% 100%",
          height: "140px",
        }}
        className="improve-match-card"
      >
        <div className="other-improve-match-head">
          <img
            src={
              props?.profileData?.profilePicture ||
              (props?.profileData?.gender === "male"
                ? images.defaultMaleIcon
                : images.defaultFemaleIcon)
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                props?.profileData?.gender === "male"
                  ? images?.defaultMaleIcon
                  : images?.defaultFemaleIcon;
            }}
            className="h-[80px] w-[80px] object-cover aspect-square rounded-lg"
            alt=""
          />
          <div className="improve-match-title text-center">My Preferences</div>
        </div>
        <a href="/match-preference">
          <PrimaryButton
            text="Improve Match"
            style={{
              width: "auto",
              height: "32px",
              borderRadius: "8px",
              fontWeight: "500",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          />
        </a>

        <div className="other-improve-match-head">
          <img
            src={
              props?.othersProfileData?.profilePicture ||
              (props?.othersProfileData?.gender === "male"
                ? images.defaultMaleIcon
                : images.defaultFemaleIcon)
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                props?.othersProfileData?.gender === "male"
                  ? images?.defaultMaleIcon
                  : images?.defaultFemaleIcon;
            }}
            className="h-[80px] w-[80px] object-cover aspect-square rounded-lg"
            alt=""
          />
          <div className="improve-match-title text-center">
            {props?.othersProfileData?.gender === "female"
              ? "Her Match"
              : "His Match"}
          </div>
        </div>
      </div>
      <div className="improve-match-info">
        <div className="improve-match-info-inner">
          {birthDate >= props?.myMatchPrefData?.ageMin &&
          birthDate <= props?.myMatchPrefData?.ageMax ? (
            <ImproveMatchInfo
              title="Age"
              subtext={`${props?.myMatchPrefData?.ageMin} to ${props?.myMatchPrefData?.ageMax}`}
            />
          ) : null}
          {props?.othersProfileData?.height >=
            props?.myMatchPrefData?.heightMin &&
          props?.othersProfileData?.height <=
            props?.myMatchPrefData?.heightMax ? (
            <ImproveMatchInfo
              title="Height"
              subtext={`${feetDataMin} (${props?.myMatchPrefData?.heightMin}cm) to ${feetDataMax} (${props?.myMatchPrefData?.heightMax}cm)`}
            />
          ) : null}
          {props?.myMatchPrefData?.maritalStatus &&
            props?.myMatchPrefData?.maritalStatus?.length > 0 &&
            props?.othersProfileData?.maritalStatus &&
            props?.myMatchPrefData?.maritalStatus?.includes(
              props?.othersProfileData?.maritalStatus
            ) && (
              <ImproveMatchInfo
                title="Marital Status"
                subtext={props?.myMatchPrefData?.maritalStatus?.map(
                  (item, index) => {
                    if (index === 0) {
                      return getMaritalStatusLabel(item);
                    } else {
                      return `, ${getMaritalStatusLabel(item)}`;
                    }
                  }
                )}
              />
            )}
          {props?.myMatchPrefData?.religiousCommitment &&
            props?.myMatchPrefData?.religiousCommitment?.length > 0 &&
            props?.othersProfileData?.religiousCommitment &&
            props?.myMatchPrefData?.religiousCommitment?.includes(
              props?.othersProfileData?.religiousCommitment
            ) && (
              <ImproveMatchInfo
                title="Religious Commitment Level"
                subtext={renderValues(
                  props?.myMatchPrefData?.religiousCommitment
                )}
              />
            )}
          {props?.myMatchPrefData?.religiousHistory &&
            props?.myMatchPrefData?.religiousHistory?.length > 0 &&
            props?.othersProfileData?.religiousHistory &&
            props?.myMatchPrefData?.religiousHistory?.includes(
              props?.othersProfileData?.religiousHistory
            ) && (
              <ImproveMatchInfo
                title="Religion / Community"
                subtext={renderValues(props?.myMatchPrefData?.religiousHistory)}
              />
            )}
          {props?.myMatchPrefData?.spokenLanguage &&
            props?.myMatchPrefData?.spokenLanguage?.length > 0 &&
            props?.othersProfileData?.spokenLanguage &&
            props?.myMatchPrefData?.spokenLanguage?.includes(
              props?.othersProfileData?.spokenLanguage
            ) && (
              <ImproveMatchInfo
                title="Mother Tongue"
                subtext={renderValues(props?.myMatchPrefData?.spokenLanguage)}
              />
            )}
          {props?.myMatchPrefData?.country &&
            props?.myMatchPrefData?.country?.length > 0 &&
            props?.othersProfileData?.country &&
            props?.myMatchPrefData?.country?.includes(
              props?.othersProfileData?.country
            ) && (
              <ImproveMatchInfo
                title="Country Living in"
                subtext={renderValues(props?.myMatchPrefData?.country)}
              />
            )}
          {props?.othersProfileData?.incomeCurrency ===
            props?.myMatchPrefData?.incomeCurrency &&
          props?.othersProfileData?.annualIncomeMin >=
            props?.myMatchPrefData?.annualIncomeMin &&
          props?.othersProfileData?.annualIncomeMax <=
            props?.myMatchPrefData?.annualIncomeMax ? (
            <ImproveMatchInfo
              title="Annual Income"
              subtext={`${
                props?.myMatchPrefData?.incomeCurrency
              } ${props?.myMatchPrefData?.annualIncomeMin?.toLocaleString()} to ${props?.myMatchPrefData?.annualIncomeMax?.toLocaleString()}`}
            />
          ) : null}
          {props?.myMatchPrefData?.bloodGroup &&
            props?.myMatchPrefData?.bloodGroup?.length > 0 &&
            props?.othersProfileData?.bloodGroup &&
            props?.myMatchPrefData?.bloodGroup?.includes(
              props?.othersProfileData?.bloodGroup
            ) && (
              <ImproveMatchInfo
                title="Blood Group"
                subtext={renderValues(props?.myMatchPrefData?.bloodGroup)}
              />
            )}
        </div>
      </div>
    </div>
  );
}
