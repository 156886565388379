import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";

import {
  OthersProfileAction,
  InterestAction,
  ProfileViewerAction,
} from "../../store/actions";

import { ViewProfileLayout } from "../../components/layout";
import CardBottomButton from "../../components/dashboardcard/cardbottombutton";
import { PrimaryButton, WhiteButton } from "../../components/button";
import Spacer from "../../components/spacer/spacer";

import useScreenSize from "../../utils/usescreensize";

import BasicInfoCard from "./basicinfocard";
import PersonalityHobbiCard from "./personalityhobbicard";
import PhysicalAppeCard from "./physicalappecard";
import ReligiousStatus from "./religiousstatus";
import AboutMe from "./aboutme";
import MyGallery from "./mygallery";
import FamilyDetails from "./familydetails";
import EducationCareer from "./educationcareer";
import ImproveMatchCard from "./improvematchcard";
import MyIdealMatchCard from "./myidealmatchcard";

import images from "../../assets";
import "./styles.css";

const ViewProfile = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const user = location.pathname.split("/")[2];
  const { contactViewAll } = useSelector((state) => state?.contactView);
  const othersProfileData = useSelector(
    (state) => state?.othersProfile?.profile
  );
  const otherMatchPrefData = useSelector(
    (state) => state?.othersProfile?.profile?.matchPreference
  );
  const myMatchPrefData = useSelector(
    (state) => state?.profile?.profile?.matchPreference
  );
  const profileData = useSelector((state) => state?.profile?.profile);

  const [open, setOpen] = useState(false);
  const [contactViewed, setContactViewed] = useState(false);

  useEffect(() => {
    const viewedContact = contactViewAll.some(
      (profile) => profile.username === user
    );
    setContactViewed(viewedContact);
  }, [contactViewAll]);

  useEffect(() => {
    const payload = { user };
    const payload2 = { viewedId: user };

    dispatch(OthersProfileAction.getOthersProfile(payload));
    dispatch(ProfileViewerAction.setProfileView(payload2));
  }, [user]);

  useEffect(() => {
    const payloadInterest = {
      filter: "My Interests",
    };
    dispatch(
      InterestAction.getInterests(payloadInterest, (res) => {
        const includes = res?.data?.some(
          (interest) => interest.username === user
        );
        const timer = setTimeout(() => {
          if (!includes) setOpen(true);
        }, 7000);
        return () => clearTimeout(timer);
      })
    );
  }, []);

  const handleClose = () => setOpen(false);

  const handleSendInterest = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(
      InterestAction.sendInterest(payload, () => {
        handleClose();
      })
    );
  };

  return (
    <>
      <ViewProfileLayout>
        <div className="profile-body-content other-body-content">
          <div className="profile-body-left">
            {screenSize.width < 1023 &&
              othersProfileData?.imageGallery?.length > 0 && (
                <>
                  <MyGallery othersProfileData={othersProfileData} />
                  <Spacer height="30px" />
                </>
              )}
            <AboutMe
              othersProfileData={othersProfileData}
              contactViewed={contactViewed}
            />
            {(othersProfileData?.gender ||
              othersProfileData?.dateOfBirth ||
              (othersProfileData?.stateProvince &&
                othersProfileData?.country) ||
              othersProfileData?.homeType ||
              othersProfileData?.relocationPlan ||
              othersProfileData?.ethnicOrigin ||
              othersProfileData?.residencyStatus ||
              othersProfileData?.maritalStatus ||
              othersProfileData?.spokenLanguage?.length > 0) && (
              <BasicInfoCard othersProfileData={othersProfileData} />
            )}

            {(othersProfileData?.height ||
              othersProfileData?.hairColor ||
              othersProfileData?.hairType ||
              othersProfileData?.hairLength ||
              othersProfileData?.complexion ||
              othersProfileData?.bloodGroup ||
              othersProfileData?.foodHabit?.length > 0 ||
              othersProfileData?.healthCondition ||
              othersProfileData?.bodyType ||
              othersProfileData?.physicalStatus ||
              othersProfileData?.smoking ||
              othersProfileData?.beard) && (
              <PhysicalAppeCard othersProfileData={othersProfileData} />
            )}

            {(othersProfileData?.fatherStatus ||
              othersProfileData?.motherStatus ||
              othersProfileData?.financialStatus ||
              (othersProfileData?.familyCountry &&
                othersProfileData?.familyStateProvince) ||
              othersProfileData?.fatherEthnicOrigin ||
              othersProfileData?.motherEthnicOrigin ||
              othersProfileData?.maleSiblingMarried ||
              othersProfileData?.maleSiblingUnmarried ||
              othersProfileData?.femaleSiblingMarried ||
              othersProfileData?.femaleSiblingUnmarried ||
              othersProfileData?.maleChildren ||
              othersProfileData?.femaleChildren ||
              othersProfileData?.familyType ||
              othersProfileData?.familyValues) && (
              <FamilyDetails othersProfileData={othersProfileData} />
            )}

            {(othersProfileData?.religiousHistory ||
              othersProfileData?.religiousSect ||
              othersProfileData?.polygamy ||
              othersProfileData?.dailyPrayer ||
              othersProfileData?.readQuran ||
              othersProfileData?.readIslamicBook ||
              othersProfileData?.religiousCommitment) && (
              <ReligiousStatus othersProfileData={othersProfileData} />
            )}

            {(othersProfileData?.education ||
              othersProfileData?.instituteName ||
              othersProfileData?.profession ||
              othersProfileData?.occupationStatus ||
              othersProfileData?.annualIncomeMin) && (
              <EducationCareer
                othersProfileData={othersProfileData}
                contactViewed={contactViewed}
              />
            )}

            {otherMatchPrefData?.idealMatchDescription && (
              <MyIdealMatchCard
                otherMatchPrefData={otherMatchPrefData}
                othersProfileData={othersProfileData}
              />
            )}
          </div>

          <div className="profile-body-right">
            {screenSize.width > 1024 &&
              othersProfileData?.imageGallery?.length > 0 && (
                <MyGallery othersProfileData={othersProfileData} />
              )}

            {(othersProfileData?.foodPreference?.length > 0 ||
              othersProfileData?.musicPreference?.length > 0 ||
              othersProfileData?.personalityTraits?.length > 0 ||
              othersProfileData?.visitedCountries?.length > 0 ||
              othersProfileData?.weekendPreference?.length > 0 ||
              othersProfileData?.bookPreference?.length > 0 ||
              othersProfileData?.hobbies?.length > 0) && (
              <PersonalityHobbiCard othersProfileData={othersProfileData} />
            )}

            <ImproveMatchCard
              othersProfileData={othersProfileData}
              myMatchPrefData={myMatchPrefData}
              otherMatchPrefData={otherMatchPrefData}
              profileData={profileData}
            />
          </div>
        </div>

        <Modal
          centered={true}
          open={open}
          onClose={handleClose}
          footer={false}
          closable={false}
          className="forgot-password-modal"
        >
          <img
            src={images?.closeIcon}
            className="modal-close-icon"
            alt="close"
            onClick={() => handleClose(false)}
          />
          <div className="send-interests-popup">
            <div className="send-interests-popup-icon">
              <CardBottomButton icon={images.cardmatch} />
            </div>
            <div className="send-interests-popup-content">
              <div className="send-interests-popup-title">
                Do you like {othersProfileData?.firstName}&nbsp;
                {othersProfileData?.lastName}'s profile?
              </div>
              <div className="send-interests-popup-btn">
                <WhiteButton
                  style={{
                    width: "120px",
                    border: "2px solid #EAE6ED",
                  }}
                  text={"Skip"}
                  onClick={() => handleClose(false)}
                />
                <PrimaryButton
                  style={{
                    width: "157px",
                    background: "#F31260",
                  }}
                  text={"Send Interest"}
                  onClick={() => handleSendInterest(user)}
                />
              </div>
            </div>
          </div>
        </Modal>
      </ViewProfileLayout>
    </>
  );
};

export default ViewProfile;
