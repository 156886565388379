const RemoveEmptyKeys = (data) => {
  if (Array.isArray(data)) {
    return data
      .filter((item) => item !== null && item !== undefined && item !== "")
      .map((item) => RemoveEmptyKeys(item));
  }

  if (typeof data === "object" && data !== null) {
    const filteredData = {};

    for (let key in data) {
      const value = RemoveEmptyKeys(data[key]);

      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        filteredData[key] = value;
      }
    }

    return filteredData;
  }

  return data;
};

export default RemoveEmptyKeys;
