import React, { useState, useEffect } from "react";

import {
  education,
  profession,
  incomeCurrency,
  incomeValue,
} from "../../utils/arrayitems";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import Spacer from "../../components/spacer/spacer";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import CheckboxGroup from "../../components/checkbox/checkboxgroup";
import ErrorText from "../../components/errortext/errortext";

import "./styles.css";

export default function EducationCareerForm(props) {
  const [incomeMatterStatus, setIncomeMatterStatus] =
    useState("Doesn't Matter");

  useEffect(() => {
    if (!incomeMatterStatus || incomeMatterStatus === "Doesn't Matter") {
      props?.setState({
        ...props?.state,
        incomeCurrency: "",
        annualIncomeMin: "",
        annualIncomeMax: "",
      });
    }
  }, [incomeMatterStatus]);

  return (
    <div className="adv-search-basic-info-area">
      <div
        style={{
          background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
          backgroundSize: "100%",
          backgroundPosition: "center",
        }}
        className="adv-search-basic-info"
      >
        <h2 className="adv-search-info-title">Education & Career</h2>
      </div>
      <div className="adv-search-from-content">
        <div className="adv-search-from-left">
          {/* Education */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Education" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Education"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...education,
            ]}
            onChange={(e) => props?.setState({ ...props?.state, education: e })}
            value={props?.state.education || undefined}
          />

          <Spacer height="12px" />

          {/* profession Type */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Profession Type" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Profession Type"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...profession,
            ]}
            onChange={(e) =>
              props?.setState({ ...props?.state, profession: e })
            }
            value={props?.state.profession || undefined}
          />
          <Spacer height="12px" />
          {/* Annual Income */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Annual Income" />
          </div>
          <Spacer height="8px" />
          <div className="adv-search-has-photo">
            <CheckboxGroup
              items={[
                { label: "Doesn't Matter", value: "Doesn't Matter" },
                {
                  label: " Select an Income range",
                  value: " Select an Income range",
                },
              ]}
              value={incomeMatterStatus || undefined}
              onChange={(e) => setIncomeMatterStatus(e[e?.length - 1])}
            />
          </div>
          <Spacer height="12px" />
          <EditProfileDropdown
            placeholder="Income Currency"
            data={incomeCurrency}
            onChange={(e) =>
              props?.setState({ ...props?.state, incomeCurrency: e })
            }
            value={props?.state.incomeCurrency || undefined}
            disabled={
              !incomeMatterStatus || incomeMatterStatus === "Doesn't Matter"
                ? true
                : false
            }
          />
          <Spacer height="8px" />
          <div className="edit-profile-annual-income-container">
            <div style={{ width: "45%" }}>
              <EditProfileDropdown
                placeholder="Minimum annual income"
                data={incomeValue}
                onChange={(e) =>
                  props?.setState({ ...props?.state, annualIncomeMin: e })
                }
                value={props?.state.annualIncomeMin || undefined}
                disabled={
                  !incomeMatterStatus || incomeMatterStatus === "Doesn't Matter"
                    ? true
                    : false
                }
              />
            </div>
            <div style={{ width: "10%", textAlign: "center" }}>to</div>
            <div style={{ width: "45%" }}>
              <EditProfileDropdown
                placeholder="Maximum annual income"
                data={incomeValue}
                onChange={(e) =>
                  props?.setState({ ...props?.state, annualIncomeMax: e })
                }
                value={props?.state.annualIncomeMax || undefined}
                disabled={
                  !incomeMatterStatus || incomeMatterStatus === "Doesn't Matter"
                    ? true
                    : false
                }
              />
            </div>
          </div>
          {props?.incomeError && <ErrorText text={props?.incomeError} />}
          <Spacer height="12px" />
        </div>

        <div className="adv-search-from-right"></div>
      </div>
      <Spacer height="40px" />
    </div>
  );
}
