const faqsQuestions = {
  "Account and Registration": [
    {
      key: "1",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Is NobleMarriage only suitable for practicing Muslims?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          NobleMarriage is the World's most reliable Muslim marriage platform,
          which provides the most reliable and diverse portfolio of Muslims
          across the World. It caters to all Muslims who are looking to start a
          married life. It is equally suitable for those who have never married,
          divorcees, new Muslims / reverts. While we cater to the specific needs
          of practicing Muslims, we highly recommend every Muslim seeking
          marriage to join this platform.
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Is it actually FREE to sign up?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          We do not require any payment or credit / debit card information if
          you want to sign up and explore the profiles of your interest. We only
          require you to give true information about yourself, so that we can
          find a suitable match for you through our rich search algorithm.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          What is the use of the user ID?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          A user ID is a unique number given to a subscriber and no two
          subscribers can have the same ID. It helps us to give you a quicker
          service whenever we get an enquiry. Furthermore, multiple users can
          have same name but their user IDs will be different and unique. If you
          save or keep record of any user's ID, you can search by the ID and it
          will direct to the precise profile you are looking for.
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          I forgot my password. How can I reset it?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Click on the "Forgot Password" link on the login page, and we'll guide
          you through the steps to reset your password securely.
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Can I deactivate or delete my account?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, you have the option to deactivate or delete your account. Refer
          to the account settings for the step-by-step process.
        </p>
      ),
    },
    {
      key: "6",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How can I customize my profile to stand out?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Upload a clear profile picture, share your interests, and write a
          captivating bio. The more detailed your profile, the better your
          chances of meaningful connections.
        </p>
      ),
    },
    {
      key: "7",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Can I have more than one account?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          No, each user is allowed only one account to ensure a genuine and
          secure user experience.
        </p>
      ),
    },
  ],
  "Profile Manage": [
    {
      key: "1",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Why can I not see photos of any female profiles in the display?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          A female user has two general options for the display pictures- fully
          hidden or blurred. This is to give more privacy to our sisters by
          ensuring random users will not be able to see their photos without
          their consent.
          <br />
          <br />
          There is an option to send a photo exchange request to any female
          subscriber who has uploaded photos. If she accepts the request, you
          will be able to access her photos for a valid time period. We
          recommend users to only send photo exchange request after some basic
          compatibility is established. This helps a user to earn credibility,
          as it is a more respectful approach.
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          I am a convert / revert Muslim, can I find a Muslim spouse through
          NobleMarriage?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          First of all, congratulations for choosing Islam as your way of life
          and salvation. We not only accommodate revert brothers and sisters to
          open profiles here but we actively promote our services to the revert
          segment to help them find an appropriate spouse through NobleMarriage.
          If you are a revert, it is extremely important for you to find a
          practicing Muslim spouse which will facilitate your great journey into
          Islam. Hurry up to register with NobleMarriage and we will give you
          the best platform to find your Muslim spouse in this life and in the
          hereafter in shā Allāh.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          I am a divorcee. Does Noble Marriage provide divorcee matrimony
          services?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, we do! We encourage people to get married whether you are a
          divorcee or seeking marriage for the first time. We promote ourselves
          heavily in the divorcee segment, and we encourage you to get your
          subscription ASAP!
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How can I edit information on my profile?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          To edit your profile, log in and navigate to the Edit Profile. From
          there, you can update details such as your bio, interests, and photos.
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Can I add or remove photos from my profile?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, you can. In your profile, there will be an option to manage
          photos. Follow the instructions to add or remove images.
        </p>
      ),
    },
    {
      key: "6",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Is there a limit to the number of photos I can upload?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, there is a limit. A user can upload a total of 5 photos,
          including the profile photo. Ensure to select and upload your photos
          thoughtfully to present a well-rounded picture of yourself.
        </p>
      ),
    },
    {
      key: "7",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Can I change my profile visibility settings?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, you can adjust your profile visibility in the settings. Choose
          whether you want your profile to be visible to everyone or only to
          certain preferences.
        </p>
      ),
    },
    {
      key: "8",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How can I verify my profile for authenticity?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Profile verification adds credibility. Follow the verification process
          in the "Trust Badge" to confirm your identity.
        </p>
      ),
    },
    {
      key: "9",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          What should I do if I come across a fake profile?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Report any suspicious profiles immediately. We take user safety
          seriously and investigate reports promptly.
        </p>
      ),
    },
    {
      key: "10",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Can I hide my contact number?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, you can. If you are a female member, navigate to Edit Profile,
          and under Contact number, tick the checkbox to keep it hidden. This
          feature provides an added layer of privacy for female members.
        </p>
      ),
    },
  ],
  "Matching and Connection": [
    {
      key: "1",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How does NobleMarriage's matching algorithm work?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Our algorithm considers factors like interests, values, and
          preferences to suggest compatible matches. The more information you
          provide, the better the match suggestions.
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How does the messaging feature work?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          To send messages and engage in conversations, you'll need to upgrade
          to our Premium membership. It unlocks unlimited messaging and enhances
          your overall experience.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Can I block or report a user if I feel uncomfortable?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, your safety is our priority. Utilize the block and report
          features, available to all members, to ensure a secure and respectful
          community.
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          What happens if my connection doesn't respond to messages?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Be patient! Meaningful interactions can lead to more successful
          connections.
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Can I search for specific criteria in potential matches?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Absolutely! Use our advanced search filters to specify criteria such
          as age, location, and interests to find matches that align with your
          preferences.
        </p>
      ),
    },
    {
      key: "6",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How can I access contact details like phone numbers and email
          addresses?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Contact details access is a premium feature. Silver members can access
          15 contacts, Gold members get 45, and Platinum members enjoy unlimited
          access. Upgrade your membership to unlock this feature and connect
          more directly.
        </p>
      ),
    },
  ],
  "Privacy and Security": [
    {
      key: "1",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Is my information kept private and secure?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          We take your privacy seriously. Your data is encrypted and secured
          using industry-standard measures. Our dedicated team continually
          monitors and updates security protocols to ensure your information
          remains safe.
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Many sites claim to be Muslim-friendly matchmaking sites, but in
          reality, we see quite the opposite- how is NobleMarriage different
          from this?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Our main concept is to provide privacy, real data, diverse portfolio
          and an environment that helps Muslims seek their spouses in a halal
          manner. A team of Muslim experts worked behind the design and
          architecture of this product, which helped us to build a strong
          platform adhering to the basic principles of Islam for online Muslim
          matrimony. At the operational level, we also have a halal monitoring
          policy, which ensures we let the users engage in a modest and
          permissible way under the purview of Islam.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Is my communication secure on NobleMarriage?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, all communications on our platform are encrypted to protect your
          messages and ensure private conversations between you and your
          matches.
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How do I report suspicious activity or a fake profile?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          If you come across any suspicious activity or a fake profile, please
          report it immediately. Our team investigates reports promptly to
          maintain a secure environment.
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Do you share user data with third parties?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          We prioritize user privacy and do not share personal information with
          third parties for marketing purposes. Refer to our privacy policy for
          detailed information on data handling practices.
        </p>
      ),
    },
    {
      key: "6",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          What should I do if someone asks for my bank or card details or
          requests money on NobleMarriage?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          NobleMarriage strictly prohibits the solicitation of financial
          information or requests for money. If someone makes such a request, do
          not provide any personal or financial details. Report the user
          immediately, and our team will take prompt action to ensure a safe
          community environment.
        </p>
      ),
    },
  ],
  "Payment and Subscription": [
    {
      key: "1",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Why do I need a premium subscription?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          NobleMarriage charges a very nominal amount for premium membership,
          which helps you to find your potential spouse very easily. If you are
          a premium member, then you can send messages to and read messages from
          any premium members. You can also view pictures of any premium members
          with permission and your success is almost assured in shā Allāh.
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          What are the different packages of memberships available?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          We have monthly, quarterly and lifetime subscription options. Visit
          our membership section to explore more and to find the benefits that
          you will enjoy in premium membership compared to basic membership.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How can I upgrade my membership on NobleMarriage?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          To upgrade your membership, navigate to the "Membership Plans" section
          in your account. Choose your desired plan, and follow the prompts to
          complete the upgrade securely.
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          What payment methods are accepted for premium subscriptions?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          We accept various payment methods, including credit/debit cards and
          other secure online payment options. Check the "Membership Plan" page
          for a list of supported methods.
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Is my payment information secure on NobleMarriage?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, we prioritize the security of your payment information. Our
          platform uses encryption and follows industry standards to safeguard
          your financial data.
        </p>
      ),
    },
    {
      key: "6",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Can I get a refund if I have already found a spouse or I am no longer
          interested?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Once you purchase a subscription plan, it cannot be refunded. But if
          you want to transfer your subscription plan to a friend or a family
          member, please write to us and we will review your request.
        </p>
      ),
    },
    {
      key: "7",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How can I stop the auto-recurring payment option?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          At the time of selecting your payment options, you can simply choose
          not to auto recur your membership payment.
        </p>
      ),
    },
    {
      key: "8",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Can I recommend others to NobleMarriage and get a discount for my
          subscription?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Noble Marriage believes that our subscribers are our biggest marketing
          tools! Your positive feedback means a lot to us. If you have
          recommended our services to another person who purchases a premium
          membership plan, please write to us with your recommended profile's
          details. We will verify and give you a discount in your upcoming
          month's subscription fees.
        </p>
      ),
    },
  ],
  "Technical Issues": [
    {
      key: "1",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          I'm having trouble logging into my account. What should I do?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          If you're facing login issues, ensure your username and password are
          correct. If the problem persists, use the "Forgot Password" link on
          the login page to reset your password securely.
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          I have viewed too many profiles and I am overwhelmed by the amount of
          information. How can I sort my information to help me review the
          profiles in an organized way?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          We have great practical tools to help our members in their pursuit of
          marriage. If you like a profile, you can add that to your shortlist.
          Also, you can add a personal note about a profile, which will help you
          to remember the points that you noted about a profile.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Why am I experiencing slow loading times on the NobleMarriage website?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Slow loading times may be due to internet connectivity issues. Try
          refreshing the page or check your internet connection. If the problem
          persists, contact our support team for assistance.
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          I'm unable to upload photos to my profile. How can I fix this?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Ensure your photos meet the required specifications and formats. If
          the issue persists, try a different browser or clear your browser
          cache. If the problem continues, reach out to our support team for
          further assistance.
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Why am I not receiving email notifications from NobleMarriage?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Check your email spam folder, and mark our emails as "Not Spam" to
          ensure proper delivery. Verify that your email notification settings
          on NobleMarriage are configured correctly in your account settings.
        </p>
      ),
    },
    {
      key: "6",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Is NobleMarriage compatible with mobile devices?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, NobleMarriage is designed to be mobile-friendly. Ensure you have
          the latest version of the app or access the website through a mobile
          browser for an optimal experience.
        </p>
      ),
    },
    {
      key: "7",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          I've encountered a technical bug on the platform. How can I report it?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          If you come across any technical bugs or glitches, report them to our
          support team with details about the issue, including any error
          messages you receive. We appreciate your help in maintaining a smooth
          user experience.
        </p>
      ),
    },
    {
      key: "8",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          My premium features are not working correctly. What should I do?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          If you're experiencing issues with premium features, contact our
          support team. Ensure your subscription is active and that you are
          using the latest version of the app or website.
        </p>
      ),
    },
    {
      key: "9",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Does NobleMarriage have an Android App?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Yes, NobleMarriage has an Android App available for download on the
          Google Play Store. You can enjoy a seamless and mobile-friendly
          experience by installing the app on your Android device.
        </p>
      ),
    },
  ],
  "Safety Tips": [
    {
      key: "1",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How can I ensure my online safety while using NobleMarriage?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Protect your personal information, avoid sharing financial details,
          and be cautious about sharing contact information. Report any
          suspicious activity, and only communicate within the platform.
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          What steps can I take to verify the authenticity of a user?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Encourage users to complete their profiles thoroughly and undergo the
          verification process offered by NobleMarriage.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How do I identify and report a fake profile or scam attempts?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Look for red flags such as inconsistent information or requests for
          money. Report any suspicious activity immediately, and NobleMarriage
          will investigate promptly.
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          What information should I avoid sharing in my profile for enhanced
          privacy?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          While authenticity is crucial, avoid sharing sensitive information
          like your home address or financial details on your profile. Focus on
          providing a snapshot of your personality and interests.
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How can I block or report a user who makes me uncomfortable?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Trust your instincts. If someone makes you uncomfortable, use the
          block and report features on NobleMarriage. Our team will review the
          report and take appropriate action.
        </p>
      ),
    },
    {
      key: "6",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          What should I do if someone insists on sharing personal contact
          details too soon?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Be cautious if someone pressures you to share personal contact
          details. Politely decline and remind them of the importance of getting
          to know each other within the platform. Report any persistent
          behavior.
        </p>
      ),
    },
    {
      key: "7",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How does NobleMarriage prioritize user safety on the platform?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          NobleMarriage employs a range of security measures, including
          encryption, account verification, and a dedicated support team to
          monitor and address safety concerns promptly.
        </p>
      ),
    },
  ],
  "Success Stories": [
    {
      key: "1",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How to share a success story?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          We urge all our members to share their success story with us as it
          helps others who are looking for marriage. You can go to contact us
          section and submit your story to us.
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          What should I include in my success story to make it compelling?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Share details about your journey, what drew you to NobleMarriage, and
          how you found your match. Include anecdotes and advice that might help
          inspire others on their own journey.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Do I need to provide photos with my success story?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          While not mandatory, sharing photos can add a personal touch to your
          success story. From an Islamic perspective, it's advised to be mindful
          of modesty, and therefore, you may choose to avoid showing the female
          face. Feel free to include pictures that capture special moments while
          adhering to these guidelines.
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Can I remain anonymous in my success story if I prefer?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Absolutely! Your privacy is important to us. You can choose to share
          your success story anonymously or use a pseudonym if you prefer not to
          reveal your identity.
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          Are there specific guidelines for success stories on NobleMarriage?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Ensure your success story follows our community guidelines. Focus on
          positivity, respect, and the celebration of finding love on our
          platform. Avoid sharing sensitive or inappropriate details.
        </p>
      ),
    },
    {
      key: "6",
      label: (
        <h2 className="text-lg leading-7 font-semibold">
          How are success stories featured on NobleMarriage?
        </h2>
      ),
      children: (
        <p className="text-base leading-6 text-left">
          Selected success stories are featured in our success stories section
          to inspire and motivate others. Our team reviews and curates stories
          to ensure they align with our guidelines.
        </p>
      ),
    },
  ],
};
export default faqsQuestions;
