import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
} from "../constants";

const initialState = {
  countries: [],
  states: [],
  isCountriesLoading: false,
  isStatesLoading: false,
  error: null,
};

export default function CountriesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES:
      state = {
        ...state,
        isCountriesLoading: true,
        error: null,
      };
      break;
    case GET_STATES:
      state = {
        ...state,
        isStatesLoading: true,
        error: null,
      };
      break;
    case GET_COUNTRIES_SUCCESS:
      state = {
        ...state,
        countries: action.payload,
        isCountriesLoading: false,
      };
      break;
    case GET_STATES_SUCCESS:
      state = {
        ...state,
        states: action.payload,
        isStatesLoading: false,
      };
      break;

    case GET_COUNTRIES_FAILURE:
      state = {
        ...state,
        isCountriesLoading: false,
        error: action.payload,
      };
      break;
    case GET_STATES_FAILURE:
      state = {
        ...state,
        isStatesLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
