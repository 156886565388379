import { put } from "redux-saga/effects";

import UserApiCaller from "../../config/api/userapicaller";

import { CountriesAction } from "../actions";

export default class CountriesMiddleware {
  static *getCountries({ cb }) {
    try {
      const res = yield UserApiCaller.Get(`/user/country`);

      if (res?.status === 200) {
        yield put(CountriesAction.getCountriesSuccess(res?.data?.data));
      } else {
        yield put(CountriesAction.getCountriesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(CountriesAction.getCountriesFailure());
    }
  }
  static *getStates({ payload, cb }) {
    try {
      const res = yield UserApiCaller.Post(`/user/state`, payload);

      if (res?.status === 200) {
        yield put(CountriesAction.getStatesSuccess(res?.data?.data));
      } else {
        yield put(CountriesAction.getStatesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(CountriesAction.getStatesFailure());
    }
  }
}
