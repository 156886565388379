import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import { Drawer } from "antd";

import config from "../../aws-exports";

import { ProfileAction, MessageAction } from "../../store/actions";

import UnderHeaderMenu from "../../components/underheadermenu";
import NavHeader from "../../components/layout/dashboard/navheader";

import useScreenSize from "../../utils/usescreensize";
import getImageName from "../../utils/getImageName";

import Messages from "./messages";
import MessagesSidebar from "./messagessidebar";
import MessageHeader from "./messageheader";
import MessageSendButton from "./messagesendbutton";
// import PresetMessags from "./presetmessags";

import { listMessagesForRoom, listRooms } from "../../graphql/queries";
import { createRoom } from "../../graphql/mutations";
import {
  onCreateMessageByRoomId,
  onMessageStatusUpdate,
} from "../../graphql/subscriptions";

import "./styles.css";

Amplify.configure(config);

const apiKey =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_APPSYNC_API_KEY_PROD
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_APPSYNC_API_KEY_STAGING
    : process.env.REACT_APP_APPSYNC_API_KEY_LOCAL;

const client = generateClient();

const MessagesLayout = () => {
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const user = useSelector((state) => state.auth?.user);
  const profileData = useSelector((state) => state?.profile?.profile);

  const { userList, isLoading } = useSelector((state) => state?.message);

  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState({
    tab: "All",
    activeUser: {},
    search: "",
  });
  const [filteredUserList, setFilteredUserList] = useState([]);

  const [lastMessage, setLastMessage] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!profileData?.username) {
      dispatch(ProfileAction.getProfile());
    }
  }, [user]);

  useEffect(() => {
    if (state?.search) {
      const updatedList = userList.filter(
        (user) =>
          user?.receiverName
            ?.toLowerCase()
            ?.includes(state?.search?.toLowerCase()) ||
          user?.senderName
            ?.toLowerCase()
            ?.includes(state?.search?.toLowerCase())
      );

      setFilteredUserList(updatedList);
    } else {
      setFilteredUserList(userList);
    }
  }, [state?.search, userList]);

  useEffect(() => {
    if (profileData?.username && state?.activeUser) {
      getMessagesOfRoom(true);
    }
  }, [profileData, state?.activeUser]);

  useEffect(() => {
    if (
      profileData?.username &&
      searchParams.get("username") &&
      !isNull(searchParams.get("username")) &&
      searchParams.get("firstName") &&
      !isNull(searchParams.get("firstName")) &&
      searchParams.get("lastName") &&
      !isNull(searchParams.get("lastName"))
    ) {
      const username = searchParams.get("username");
      const firstName = searchParams.get("firstName");
      const lastName = searchParams.get("lastName");
      const photo = searchParams.get("photo")
        ? getImageName(searchParams.get("photo"))
        : "";

      setSearchParams("");
      if (userList && userList.length > 0) {
        createSingleRoom(username, firstName, lastName, photo);
      } else {
        getRoomList(username, firstName, lastName, photo);
      }
    }
  }, [profileData, userList]);

  useEffect(() => {
    const msgsubscription = client
      .graphql({
        query: onCreateMessageByRoomId,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          setMessages((prevMsgs) => [
            ...prevMsgs,
            data?.data?.onCreateMessageByRoomId,
          ]);
          setLastMessage(data?.data?.onCreateMessageByRoomId);
        },
      });
    return () => msgsubscription.unsubscribe();
  }, []);

  useEffect(() => {
    const statusUpdateSubscription = client
      .graphql({
        query: onMessageStatusUpdate,
        variables: {},
      })
      .subscribe({
        next: async (data) => {
          await getMessagesOfRoom(false);
        },
      });
    return () => statusUpdateSubscription.unsubscribe();
  }, [profileData, state?.activeUser]);

  function isNull(text) {
    if (!text || text === "null" || text === "undefined") {
      return true;
    } else {
      return false;
    }
  }

  const findIds = (id1, id2) => {
    let found = { status: false, user: {} };
    for (let i = 0; i < userList?.length; i++) {
      if (
        (userList[i].senderId === id1 && userList[i].receiverId === id2) ||
        (userList[i].senderId === id2 && userList[i].receiverId === id1)
      ) {
        found.status = true;
        found.user = userList[i];
        break;
      }
    }
    return found;
  };

  const getRoomList = async (username, firstName, lastName, photo) => {
    try {
      const payload = {
        isLoading: true,
      };
      dispatch(
        MessageAction.setMessageList(payload, async () => {
          const result = await client.graphql({
            query: listRooms,
            variables: {
              searchId: profileData?.username,
            },
          });
          const sortedUserlist = result?.data?.listRooms?.items?.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );
          const payload = {
            userList: sortedUserlist,
            isLoading: false,
          };
          dispatch(
            MessageAction.setMessageList(payload, () => {
              createSingleRoom(username, firstName, lastName, photo);
            })
          );
        })
      );
    } catch (e) {
      console.error("in get Room list error", e);
    }
  };

  const getMessagesOfRoom = async (showLoading) => {
    try {
      if (state?.activeUser?.id) {
        setMessagesLoading(showLoading);
        const result = await client.graphql({
          query: listMessagesForRoom,
          variables: {
            roomId: state?.activeUser?.id,
          },
          headers: {
            "x-api-key": apiKey,
          },
        });
        setMessagesLoading(false);
        const fetchedMessages = result?.data?.listMessagesForRoom?.items || [];
        setMessages(fetchedMessages);
        setLastMessage(fetchedMessages[fetchedMessages.length - 1] || null);
      }
    } catch (e) {
      setMessagesLoading(false);
    }
  };

  const createSingleRoom = async (
    receiverUsername,
    firstName,
    lastName,
    photo
  ) => {
    try {
      if (userList) {
        const findExisting = findIds(profileData?.username, receiverUsername);

        if (findExisting.status === true) {
          setState({ ...state, activeUser: findExisting?.user });
        } else {
          const result = await client.graphql({
            query: createRoom,
            variables: {
              input: {
                senderId: profileData?.username,
                receiverId: receiverUsername,
                senderName: `${profileData?.firstName} ${profileData?.lastName}`,
                senderImageUrl: profileData?.profilePicture
                  ? getImageName(profileData?.profilePicture)
                  : "",
                receiverName: `${firstName} ${lastName}`,
                receiverImageUrl: photo,
              },
            },
            headers: {
              "x-api-key": apiKey,
            },
          });

          if (result) {
            setState({ ...state, activeUser: result?.data?.createRoom });
          } else {
            const findExisting = findIds(
              profileData?.username,
              receiverUsername
            );

            setState({ ...state, activeUser: findExisting?.user });
          }
        }
      }
    } catch (e) {
      console.error("in create room error", e);
    }
  };

  return (
    <>
      <div className="messages-layout">
        {screenSize.width > 1023 && (
          <MessagesSidebar
            state={state}
            setState={setState}
            userList={filteredUserList}
            userListLoading={isLoading}
          />
        )}
        <div className="messages-right-side">
          {screenSize.width > 1023 ? (
            <NavHeader />
          ) : (
            !state?.activeUser?.id && open && <NavHeader />
          )}

          {!state?.activeUser?.id && screenSize.width < 1023 && (
            <UnderHeaderMenu titlename="Messages" />
          )}

          {screenSize.width < 1023 && !state?.activeUser?.id && (
            <Drawer
              className="messages-drawer"
              closable={false}
              mask={false}
              placement="left"
              width="100%"
              open={open}
            >
              <MessagesSidebar
                state={state}
                setState={setState}
                userList={filteredUserList}
                userListLoading={isLoading}
              />
            </Drawer>
          )}

          {!state?.activeUser?.id ? (
            <div className="h-full flex justify-center items-center text-[32px] font-bold text-basePrimary">
              Please select a user to start chatting 👋👋
            </div>
          ) : (
            <>
              <MessageHeader
                activeUser={state?.activeUser}
                state={state}
                setState={setState}
              />
              <div className="messages-content">
                <Messages
                  state={state}
                  setState={setState}
                  messages={messages}
                  setMessages={setMessages}
                  activeUser={state?.activeUser}
                  messagesLoading={messagesLoading}
                />
              </div>
              <div className="message-bottom-bar">
                {/* <PresetMessags
                  messages={messages}
                  setMessages={setMessages}
                  activeUser={state?.activeUser}
                  apiKey={apiKey}
                  client={client}
                /> */}
                <MessageSendButton
                  messages={messages}
                  setMessages={setMessages}
                  activeUser={state?.activeUser}
                  apiKey={apiKey}
                  client={client}
                  lastMsgObj={lastMessage}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MessagesLayout;
