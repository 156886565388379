import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import ModalForm from "../../components/modalform/modalform";
import EditProfilePhoneInput from "../../components/phoneinput/editprofilephoneinput";

const PhoneForm = (props) => {
  const profileData = useSelector((state) => state?.profile?.profile);
  const { countries } = useSelector((state) => state.countries);

  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    const countryCode = countries?.find(
      (country) => country?.name === profileData?.country
    );

    setCountryCode(countryCode?.sortname);
  }, [profileData]);

  return (
    <ModalForm
      titletext="Change Phone Number"
      subtitle={[
        "Enter your new phone number.",
        <br />,
        " We'll send you an OTP to verify.",
      ]}
      btntext="Send OTP"
      onClick={props?.handlePhoneSubmit}
    >
      <div>Phone</div>
      <EditProfilePhoneInput
        placeholder="Please enter your new phone number"
        country={countryCode?.toLowerCase()}
        onChange={(e) => props?.setPhone(e)}
      />
    </ModalForm>
  );
};

export default PhoneForm;
