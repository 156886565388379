import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Progress } from "antd";

import { documentType } from "../../utils/arrayitems";

import { ProfileAction } from "../../store/actions";

import Spacer from "../../components/spacer/spacer";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import FileUploaderButton from "../../components/imageuploader/fileuploadbutton";
import truncateString from "../../utils/truncatestring";

import images from "../../assets";
import { PrimaryButton } from "../../components/button";

const TrustBadgeForm = () => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state?.profile?.profile);

  const [state, setState] = useState({
    documentType: "",
    fileList: [],
  });

  const removeFile = (file) => {
    const newFileList = state?.fileList.filter((item) => item.uid !== file.uid);
    setState({ ...state, fileList: newFileList });
  };

  const handleSubmit = () => {
    if (!state?.documentType) {
      message.error("Select a document type");
    } else if (state?.fileList?.length === 0) {
      message.error("Please select a file");
    } else {
      const payload = new FormData();

      payload.append("username", profileData?.username);
      payload.append("documentType", state?.documentType);
      payload.append("fileData", state?.fileList[0]?.originFileObj);

      dispatch(
        ProfileAction?.uploadTrustBadge(payload, (res) => {
          dispatch(ProfileAction?.getProfile());
        })
      );
    }
  };

  return (
    <>
      <div className="change-email-head">
        <h2 className="change-email-title">Trust Badge Verification</h2>
      </div>
      <div className="change-email-body">
        <p>Please upload a verified document to proceed.</p>
        <Spacer height="32px" />
        <div>Document Type</div>
        <EditProfileDropdown
          placeholder="Please Select"
          data={documentType}
          onChange={(e) => setState({ ...state, documentType: e })}
        />
        <div className="file-list-container">
          {state?.fileList?.length > 0 &&
            state?.fileList?.map((item, index) => (
              <div key={index} className="file-list">
                <img
                  src={images?.closeIcon}
                  alt={item.name}
                  className="remove-file-icon"
                  onClick={() => removeFile(item)}
                />
                <div className="flex flex-row gap-3 items-center">
                  <img
                    src={URL.createObjectURL(item.originFileObj)}
                    alt={item.name}
                    className="file-thumbnail"
                  />
                  <div className="file-details">
                    <p className="file-name-text" title={item?.name}>
                      {truncateString(item?.name)}
                    </p>
                    <p className="file-name-subtext">
                      {(item.size / 1024).toFixed(2)} KB
                    </p>
                  </div>
                </div>
                <Progress percent={100} className="m-0" />
              </div>
            ))}
        </div>
      </div>
      <div className="change-email-btn">
        {state.fileList.length > 0 ? (
          <PrimaryButton text="Save" onClick={handleSubmit} />
        ) : (
          <FileUploaderButton
            setState={setState}
            state={state}
            formats=".jpg,.png,.pdf"
          />
        )}
      </div>
    </>
  );
};

export default TrustBadgeForm;
