import { put } from "redux-saga/effects";

import SubscriptionApiCaller from "../../config/api/subscriptionapicaller";

import { SubscriptionAction } from "../actions";

export default class SubscriptionMiddleware {
  static *getSubscriptionInfo({ payload, cb }) {
    try {
      const res = yield SubscriptionApiCaller.Get(
        `/payment/order?transactionId=${payload?.transactionId}`
      );

      if (res?.status === 200) {
        const data = {
          name: res?.data?.plan?.name,
          updatedAt: res?.data?.order?.updatedAt,
          contactView: res?.data?.order?.contactView,
          duration: res?.data?.order?.duration,
          amount: res?.data?.order?.amount,
          expireDate: res?.data?.order?.expireDate,
          id: res?.data?.plan?.id,
          paymentGateway: res?.data?.order?.paymentgateway,
        };
        yield put(SubscriptionAction.getSubscriptionInfoSuccess(data));
        if (cb) {
          cb(data);
        }
      } else {
        yield put(SubscriptionAction.getSubscriptionInfoFailure());
      }
    } catch (err) {
      yield put(SubscriptionAction.getSubscriptionInfoFailure());
    }
  }
  static *getPaymentPlan({ cb }) {
    try {
      const res = yield SubscriptionApiCaller.Get(`/payment/plan`);

      if (res?.status === 200) {
        yield put(SubscriptionAction.getPaymentPlanSuccess(res?.data?.data));
      } else {
        yield put(SubscriptionAction.getPaymentPlanFailure());
      }
      if (cb) {
        cb(res?.data?.data);
      }
    } catch (err) {
      yield put(SubscriptionAction.getPaymentPlanFailure());
    }
  }
}
