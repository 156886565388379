import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import EditProfileInput from "../../components/inputbox/editprofileinput";
import Spacer from "../../components/spacer/spacer";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import ModalForm from "../../components/modalform/modalform";

import { CountriesAction } from "../../store/actions";

import { countryChangeReason } from "../../utils/arrayitems";

const CountryForm = (props) => {
  const dispatch = useDispatch();

  const { countries, states } = useSelector((state) => state.countries);

  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [otherReason, setOtherReason] = useState("");

  useEffect(() => {
    dispatch(CountriesAction.getCountries());
  }, []);

  return (
    <ModalForm
      titletext="Country Change Request"
      subtitle="Please select your Country. We will review your request and update it, and you will be notified soon."
      btntext="Submit Request"
      onClick={() => {
        props?.handleCountry({ ...props.state, otherReason });
      }}
    >
      <div>Country</div>
      <EditProfileDropdown
        placeholder="Please select"
        data={countries}
        onChange={(e) => {
          const country = JSON.parse(e);
          props?.setState({
            ...props?.state,
            country: country.name,
            stateProvince: "",
          });
        }}
        list="country"
        value={props?.state?.country || undefined}
      />
      <Spacer height="32px" />
      <div>State/Province</div>
      <EditProfileDropdown
        placeholder="Please select"
        data={states}
        list="stateProvince"
        onChange={(e) => {
          props?.setState({ ...props?.state, stateProvince: e });
        }}
        value={props?.state?.stateProvince || undefined}
      />
      <Spacer height="32px" />
      <div>Reason</div>
      <EditProfileDropdown
        placeholder="Please select"
        data={countryChangeReason}
        list="reason"
        onChange={(e) => {
          setIsOtherSelected(e === "Other");
          setOtherReason("");
          props?.setState({ ...props?.state, reason: e });
        }}
        value={props?.state?.reason || undefined}
      />
      {isOtherSelected && (
        <>
          <Spacer height="16px" />
          <EditProfileInput
            type="text"
            placeholder="Please specify your reason"
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
          />
        </>
      )}
    </ModalForm>
  );
};

export default CountryForm;
