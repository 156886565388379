import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "antd";

import { ProfileAction } from "../../store/actions";

import { PrimaryButton, WhiteButton } from "../../components/button";
import Spacer from "../../components/spacer/spacer";

import images from "../../assets";
export default function PhotoModal({ children, item }) {
  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(false);

  const handleDelete = () => {
    const payload = {
      key: item?.url,
    };

    dispatch(ProfileAction.deleteTrustBadge(payload));
  };

  return (
    <div>
      <div className="photo-modal-head">{children}</div>
      <Spacer height="24px" />

      <div className="photo-modal-img">
        <div className="photo-gallery-modal">
          <img src={item.url} alt="glry" />
        </div>
      </div>
      <Spacer height="24px" />
      <div className="trustbadge-delete">
        <img
          onClick={() => setOpen(true)}
          src={images.deleteBtnIcon}
          alt="Delete"
        />
      </div>

      <Modal
        centered={true}
        open={isOpen}
        onCancel={() => setOpen(false)}
        footer={false}
        closable={false}
      >
        <div className="gap-4 set-profile-photo-content">
          <div className="set-profile-photo-icon">
            <img src={images?.deleteIcon2} alt="" />
          </div>
          <div className="set-profile-photo-heading">
            <h2>Are you sure you want to delete this document?</h2>
            <p>
              This action cannot be undone. Deleting this document will
              permanently remove it from your profile.
            </p>
          </div>
        </div>
        <Spacer height="16px" />
        <div className="flex w-full justify-end">
          <div className="flex gap-4 set-profile-photo-btn">
            <WhiteButton text="Skip" onClick={() => setOpen(false)} />
            <PrimaryButton
              style={{
                background: "#F31260",
              }}
              text="Delete"
              onClick={handleDelete}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
