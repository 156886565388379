import { put } from "redux-saga/effects";

import SearchApiCaller from "../../config/api/searchapicaller";
import { SearchAction } from "../actions";

export default class SearchMiddleware {
  static *advancedSearch({ payload, cb }) {
    try {
      const res = yield SearchApiCaller.Post("/search?type=primary", payload);
      if (res.status === 200) {
        yield put(SearchAction.advancedSearchSuccess(res.data));
      } else {
        yield put(SearchAction.advancedSearchFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(SearchAction.advancedSearchFailure());
    }
  }
  static *basicSearch({ payload, cb }) {
    try {
      const res = yield SearchApiCaller.Post("/search?type=primary", payload);
      if (res.status === 200) {
        yield put(SearchAction.basicSearchSuccess(res.data));
      } else {
        yield put(SearchAction.basicSearchFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(SearchAction.basicSearchFailure());
    }
  }
  static *getUniversities({ payload, cb }) {
    try {
      const res = yield SearchApiCaller.Post("/search/getuniversity", payload);
      if (res.status === 200) {
        yield put(SearchAction.getUniversitiesSuccess(res.data));
      } else {
        yield put(SearchAction.getUniversitiesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(SearchAction.getUniversitiesFailure());
    }
  }
  static *getMod({ cb }) {
    try {
      const res = yield SearchApiCaller.Get("/search/matchoftheday");
      if (res.status === 200) {
        yield put(
          SearchAction.getModSuccess(
            res.data.data === "empty result" ? {} : res.data.data
          )
        );
      } else {
        yield put(SearchAction.getModFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(SearchAction.getModFailure());
    }
  }
  static *searchPayload({ payload, cb }) {
    try {
      yield put(SearchAction.searchPayloadSuccess(payload));
    } catch (err) {
      yield put(SearchAction.searchPayloadFailure());
    }
  }
  static *advancedSearchPayload({ payload, cb }) {
    try {
      yield put(SearchAction.advancedSearchPayloadSuccess(payload));
    } catch (err) {
      yield put(SearchAction.advancedSearchPayloadFailure());
    }
  }
  static *quickSearchPayload({ payload, cb }) {
    try {
      yield put(SearchAction.quickSearchPayloadSuccess(payload));
    } catch (err) {
      yield put(SearchAction.quickSearchPayloadFailure());
    }
  }
  static *quickSearch({ payload, cb }) {
    try {
      const res = yield SearchApiCaller.Post("/search/quick", payload);
      if (res.status === 200) {
        yield put(SearchAction.quickSearchSuccess(res.data));
      } else {
        yield put(SearchAction.quickSearchFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(SearchAction.quickSearchFailure());
    }
  }
  static *recommendedSearch({ payload, cb }) {
    try {
      const res = yield SearchApiCaller.Post("/search?type=recomend", payload);

      if (res.status === 200) {
        yield put(SearchAction.recommendedSearchSuccess(res.data));
      } else {
        yield put(SearchAction.recommendedSearchFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(SearchAction.recommendedSearchFailure());
    }
  }
}
