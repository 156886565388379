import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "antd";

import NotificationNavs from "./notificationnavs";

import images from "../../../../assets";

const items = [
  {
    label: <NotificationNavs />,
    key: "0",
  },
];

const NotificationDropdownContainer = (props) => {
  const { notifications } = useSelector((state) => state.notifications);

  const [allRead, setAllRead] = useState(true);

  useEffect(() => {
    const hasUnread = notifications.some(
      (notification) => notification.status === "unread"
    );
    setAllRead(!hasUnread);
  }, [notifications]);

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={["click"]}
      placement="bottom"
      overlayStyle={{
        top: props?.promotionHeader ? "130px" : "80px",
      }}
    >
      <img
        className="notification-bell"
        src={allRead ? images.notificationRead : images.notification}
        alt="Notification"
      />
    </Dropdown>
  );
};

export default NotificationDropdownContainer;
