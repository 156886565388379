import { delay, put } from "redux-saga/effects";

import AuthApiCaller from "../../config/api/authapicaller";
import { AuthAction } from "../actions";
import { message } from "antd";

export default class AuthMiddleware {
  static *SignIn({ payload, cb }) {
    try {
      const res = yield AuthApiCaller.PostSignin("/auth/signin", payload);

      if (res.status === 200) {
        yield put(
          AuthAction.SignInSuccess({
            AccessToken: res.data?.AuthenticationResult.AccessToken,
          })
        );
        yield localStorage.setItem(
          "authUser",
          JSON.stringify({
            AccessToken: res.data?.AuthenticationResult.AccessToken,
          })
        );
        message.success("Logged in successfully");
      } else if (
        res.status === 500 &&
        res.error === "No user found with the provided email."
      ) {
        yield put(AuthAction.SignInFailure());
        message.error("User does not exist");
      } else if (
        res.status === 400 &&
        res.message === "NotAuthorizedException"
      ) {
        yield put(AuthAction.SignInFailure());
        message.error("Invalid email or password");
      } else if (
        res.status === 400 &&
        res.message === "User is blocked or deleted"
      ) {
        yield put(AuthAction.SignInFailure());
        message.error(res.message);
      } else if (
        res.status === 401 &&
        res.message === "Incorrect password for migrated user"
      ) {
        yield put(AuthAction.SignInFailure());
        message.error("Invalid email or password");
      } else if (
        res.status === 404 &&
        res.message === "No user found with the provided email."
      ) {
        yield put(AuthAction.SignInFailure());
        message.error("No user found with the provided email.");
      } else if (
        res.status === 400 &&
        res.message === "User is not confirmed. OTP resend initiated."
      ) {
        yield put(AuthAction.SignInFailure());
      } else if (res.status === 301) {
        yield put(AuthAction.SignInFailure());
      } else {
        yield put(AuthAction.SignInFailure());
        message.error(res.message);
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(AuthAction.SignInFailure());
    }
  }
  static *forgotPassword({ payload, cb }) {
    try {
      const res = yield AuthApiCaller.Post("/auth/forgotpassword", payload);

      if (res.data.Data["$metadata"].httpStatusCode === 200) {
        yield put(AuthAction.forgotPasswordSuccess());
        message.success("OTP sent to email.");
      } else if (
        res.data.Data["$metadata"].httpStatusCode === 400 &&
        res.data.Data.name === "LimitExceededException"
      ) {
        yield put(AuthAction.forgotPasswordFailure());
        message.error("Request limit exceeded.");
      } else {
        yield put(AuthAction.forgotPasswordFailure());
        message.error("Something went wrong");
      }
      if (cb) {
        cb(res.data.Data["$metadata"].httpStatusCode);
      }
    } catch (err) {
      message.error("Something went wrong");
      yield put(AuthAction.forgotPasswordFailure());
    }
  }
  static *confirmOtpRecover({ payload, cb }) {
    try {
      const res = yield AuthApiCaller.Post("/auth/confirmrecover", payload);
      if (res.status === 200) {
        yield put(AuthAction.confirmOtpRecoverSuccess());
      }
      if (res.status === 400) {
        message.error("Invalid OTP");
        yield put(AuthAction.confirmOtpRecoverFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      message.error("Something went wrong");
      yield put(AuthAction.confirmOtpRecoverFailure());
    }
  }
  static *resendOtp({ payload, cb }) {
    try {
      const res = yield AuthApiCaller.Post("/auth/resendotp", payload);
      if (res.data["$metadata"].httpStatusCode === 200) {
        yield put(AuthAction.resendOtpSuccess());
        message.success("OTP resent to email.");
      } else {
        yield put(AuthAction.resendOtpFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      message.error("Something went wrong");
      yield put(AuthAction.resendOtpFailure());
    }
  }
  static *confirmPassword({ payload, cb }) {
    try {
      const res = yield AuthApiCaller.Post("/auth/confirmpassword", payload);
      if (res.data.Data["$metadata"].httpStatusCode === 200) {
        yield put(AuthAction.confirmPasswordSuccess());
        message.success("Password changed successfully.");
      } else if (
        res.data.Data["$metadata"].httpStatusCode === 400 &&
        res.data.Data.name === "LimitExceededException"
      ) {
        yield put(AuthAction.confirmPasswordFailure());
        message.error("Request limit exceeded.");
      } else if (
        res.data.Data["$metadata"].httpStatusCode === 400 &&
        res.data.Data.name === "InvalidParameterException"
      ) {
        yield put(AuthAction.confirmPasswordFailure());
        message.error("Invalid parameter.");
      } else if (
        res.data.Data["$metadata"].httpStatusCode === 400 &&
        res.data.Data.name === "CodeMismatchException"
      ) {
        yield put(AuthAction.confirmPasswordFailure());
        message.error("Invalid OTP.");
      } else if (
        res.data.Data["$metadata"].httpStatusCode === 400 &&
        res.data.Data.name === "InvalidPasswordException"
      ) {
        yield put(AuthAction.confirmPasswordFailure());
        message.error("Password should be at least 6 characters.");
      } else {
        yield put(AuthAction.confirmPasswordFailure());
        message.error("Something went wrong");
      }
      if (cb) {
        cb(res.data.Data["$metadata"].httpStatusCode);
      }
    } catch (err) {
      message.error("Something went wrong");
      yield put(AuthAction.confirmPasswordFailure());
    }
  }
  static *emailChangeReq({ payload, cb }) {
    try {
      const res = yield AuthApiCaller.Post("/auth/changeemailrequest", payload);
      if (res.data.Data.httpStatusCode === 200) {
        yield put(AuthAction.emailChangeReqSuccess());
        message.success("OTP sent to email.");
      } else {
        yield put(AuthAction.emailChangeReqFailure());
        message.error("Something went wrong");
      }
      if (cb) {
        cb(res.data.Data.httpStatusCode);
      }
    } catch (err) {
      message.error("Something went wrong");
      yield put(AuthAction.emailChangeReqFailure());
    }
  }
  static *emailChangeVerify({ payload, cb }) {
    try {
      const res = yield AuthApiCaller.Post("/auth/changeemailverify", payload);
      if (res.status === 200) {
        yield put(AuthAction.emailChangeVerifySuccess());
        message.success("Email changed successfully");
      } else if (
        res.status === 400 &&
        res.data.data === "CodeMismatchException"
      ) {
        yield put(AuthAction.emailChangeVerifyFailure());
        message.error("Invalid OTP");
      } else {
        yield put(AuthAction.emailChangeVerifyFailure());
        message.error("Something went wrong");
      }
      if (cb) {
        cb(res.status);
      }
    } catch (err) {
      message.error("Something went wrong");
      yield put(AuthAction.emailChangeVerifyFailure());
    }
  }

  static *Logout() {
    try {
      yield delay(1000);
      localStorage.removeItem("authUser");
      yield put(AuthAction.LogoutSuccess());
    } catch (err) {
      yield put(AuthAction.LogoutFailure());
    }
  }
}
