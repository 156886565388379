import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Empty } from "antd";
import moment from "moment";

import { ShortlistAction } from "../../store/actions";
import useScreenSize from "../../utils/usescreensize";
import ProfileTab from "../../components/tab/profiletab";
import DashboardCard from "../../components/dashboardcard/dashboardcard";
import { WhitePurpleButton } from "../../components/button";

import SortDropdown from "../../components/dropdown/sortdropdown";

import Cardloader from "../../components/loader/cardloader";
import Spacer from "../../components/spacer/spacer";

import "./styles.css";
import UnderHeaderMenu from "../../components/underheadermenu";

const Shortlists = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const { shortlists, isLoading, shortlistCount } = useSelector(
    (state) => state.shortlists
  );

  const [paginationStack, setPaginationStack] = useState([null]);
  const [state, setState] = useState({
    activeTab: "Shortlisted Me",
    sortOption: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  useEffect(() => {
    setPaginationStack([null]);
    getShortlist([null]);
  }, [state]);

  const getShortlist = (prevKey) => {
    const payload = {
      filter: state?.activeTab,
      limit: pageSize,
      lastEvaluatedKey: prevKey,
    };
    setPageNo(1);
    dispatch(
      ShortlistAction.getShortlist(payload, (res) => {
        const exists = paginationStack?.some(
          (item) =>
            item?.username["S"] === res.data.lastEvaluatedKey?.username["S"]
        );
        if (!exists) {
          setPaginationStack([...paginationStack, res.data.lastEvaluatedKey]);
        }
      })
    );
  };

  const handleTab = (name) => {
    setPaginationStack([null]);
    setState({ ...state, activeTab: name });
  };

  const handlePageChange = async (pageNumber) => {
    setPageNo(pageNumber);
    if (pageNumber < pageNo) {
      await goBack();
    } else {
      getShortlist(paginationStack[pageNumber - 1]);
    }
  };
  const goBack = async () => {
    if (paginationStack.length > 1) {
      const prevKey = paginationStack[pageNo - 2];
      setPaginationStack(paginationStack.slice(0, -1));
      getShortlist(prevKey);
    }
  };

  const tabItems = [
    { name: "Shortlisted Me", title: "Shortlisted Me" },
    { name: "Shortlisted by Me", title: "Shortlisted by Me" },
  ];

  return (
    <div>
      {screenSize.width < 1023 && (
        <UnderHeaderMenu titlename="Shortlists">
          <div style={{ width: "110px" }}>
            <SortDropdown
              data={[
                { label: "Newest First", value: "desc" },
                { label: "Oldest First", value: "asc" },
              ]}
              onChange={(e) => setState({ ...state, sortOption: e })}
              value={state?.sortOption || undefined}
              placeholder="Sort by"
            />
          </div>
        </UnderHeaderMenu>
      )}
      <Spacer height="16px" />
      <div className="shortlist-section-top-part-container">
        <div></div>
        <div className="shortlist-section-header-container">
          {screenSize.width > 1023 && (
            <h1 className="shortlist-header">Shortlists</h1>
          )}
          <ProfileTab
            tabItems={tabItems}
            handleTab={handleTab}
            activeTab={state?.activeTab}
            style={{
              background: "white",
              border: "1px solid #EAE6ED",
              width: "250px",
            }}
          />
        </div>
        {screenSize.width > 1023 && (
          <div className="shortlist-sort-container">
            <div style={{ width: "150px" }}>
              <SortDropdown
                data={[
                  { label: "Newest First", value: "desc" },
                  { label: "Oldest First", value: "asc" },
                ]}
                onChange={(e) => setState({ ...state, sortOption: e })}
                value={state?.sortOption || undefined}
                placeholder="Sort by"
              />
            </div>
          </div>
        )}
      </div>
      <Spacer height="12px" />
      <div className="shortlist-results-container">
        {isLoading ? (
          <Cardloader />
        ) : (
          <>
            {shortlists?.length > 0 ? (
              <>
                <div className="shortlist-results">
                  {shortlists?.map((item, index) => (
                    <DashboardCard
                      item={item}
                      index={index}
                      key={index}
                      redirectedFrom="shortlists"
                    >
                      <div className="flex flex-col w-full gap-3">
                        {state?.activeTab === "Shortlisted Me" && (
                          <div className="shortlist-card-text">
                            <p>Shortlisted me on:</p>
                            <p>{moment(item?.updatedAt).fromNow()}</p>
                          </div>
                        )}
                        {state?.activeTab === "Shortlisted by Me" && (
                          <div className="shortlist-card-text">
                            <p>Shortlisted on:</p>
                            <p>{moment(item?.updatedAt).fromNow()}</p>
                          </div>
                        )}

                        <WhitePurpleButton
                          text="View Profile"
                          onClick={() =>
                            navigate(`/view-profile/${item?.username}`)
                          }
                        />
                      </div>
                    </DashboardCard>
                  ))}
                </div>
                <Spacer height="36px" />
                <div className="flex justify-center items-center">
                  <Pagination
                    simple={{ readOnly: true }}
                    pageSize={pageSize}
                    total={shortlistCount}
                    current={pageNo}
                    showSizeChanger={false}
                    onChange={handlePageChange}
                  />
                </div>
                <Spacer height="24px" />
              </>
            ) : (
              <Empty description={<p>No Results found</p>} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Shortlists;
