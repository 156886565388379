import { put } from "redux-saga/effects";
import { message } from "antd";

import { MessageAction } from "../actions";

export default class MessageMiddleware {
  static *setMessageList({ payload, cb }) {
    try {
      if (payload) {
        yield put(MessageAction.setMessageListSuccess(payload));
      } else {
        yield put(MessageAction.setMessageListFailure());
      }
      if (cb) {
        cb(payload);
      }
    } catch (err) {
      message.error("Something went wrong");
      yield put(MessageAction.setMessageListFailure());
    }
  }
}
