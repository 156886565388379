import React from "react";

import {
  hairColor,
  bodyType,
  bloodGroup,
  foodHabit,
} from "../../utils/arrayitems";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import Spacer from "../../components/spacer/spacer";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";
import HeightSlider from "../../components/rangeslider/heightslider";

import "./styles.css";

export default function AppeLifeStyleFrom(props) {
  return (
    <div className="adv-search-basic-info-area">
      <div
        style={{
          background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
          backgroundSize: "100%",
          backgroundPosition: "center",
        }}
        className="adv-search-basic-info"
      >
        <h2 className="adv-search-info-title">Their Appearance & Lifestyle</h2>
      </div>
      <div className="adv-search-from-content">
        <div className="adv-search-from-left">
          {/* Hair Color */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Hair Color" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Hair Color"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...hairColor,
            ]}
            onChange={(e) => props?.setState({ ...props?.state, hairColor: e })}
            value={props?.state.hairColor || undefined}
          />

          <Spacer height="12px" />
          {/* Height Range */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Height Range" />
          </div>
          <HeightSlider
            onChange={(e) =>
              props?.setState({
                ...props?.state,
                heightMin: e[0],
                heightMax: e[1],
              })
            }
            value={
              [props?.state.heightMin, props?.state.heightMax] || undefined
            }
          />

          <Spacer height="12px" />
          {/* Body Type */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Body Type" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Body Type"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...bodyType,
            ]}
            onChange={(e) => props?.setState({ ...props?.state, bodyType: e })}
            value={props?.state.bodyType || undefined}
          />
        </div>
        {/* Right From Area */}
        <div className="adv-search-from-right">
          {/* Food Habit */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Food Habit" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Food Habit"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...foodHabit,
            ]}
            onChange={(e) => props?.setState({ ...props?.state, foodHabit: e })}
            value={props?.state.foodHabit || undefined}
          />
          <Spacer height="8px" />
          {/* Blood Group */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Blood Group" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Blood Group"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...bloodGroup,
            ]}
            onChange={(e) =>
              props?.setState({ ...props?.state, bloodGroup: e })
            }
            value={props?.state.bloodGroup || undefined}
          />
        </div>
      </div>
    </div>
  );
}
