import { Link } from "react-router-dom";

const NotifiMainInbox = ({
  className,
  pinkColor,
  onlineGreen,
  title,
  subText,
  time,
  route,
  children,
}) => {
  return (
    <Link
      className={`${className} notifi-main-inbox`}
      to={route}
      key={Math.random()}
    >
      <div className="notifi-imageicon">
        <div className="notifi-profile-img-area">
          {children}
          <div className="notifi-online-icon">
            <div className={`${onlineGreen} notifi-green-icon`} />
          </div>
        </div>
      </div>
      <div className="notifi-viewed-area">
        <div className="flex flex-col gap-1">
          <span className="notifi-viewed-name line-clamp-1 overflow-hidden text-ellipsis">
            {title}
          </span>
          <span className="notifi-viewed-text line-clamp-2 overflow-hidden text-ellipsis">
            {subText}
          </span>
        </div>
        <div className="notifi-pink-area">
          <div className={`${pinkColor} notifi-pink-color`} />
        </div>
        <div className="notifi-minute">{time}</div>
      </div>
    </Link>
  );
};
export default NotifiMainInbox;
