import { useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";

import { Input } from "antd";
import {
  createMessage,
  updateRoom,
  updateMessageStatuses,
} from "../../graphql/mutations";

import validateDescription from "../../utils/validate-description";

import images from "../../assets";

const { TextArea } = Input;

export default function MessageSendButton(props) {
  const profileData = useSelector((state) => state?.profile?.profile);

  const [msgText, setMsgText] = useState("");

  const createSingleMessage = async () => {
    try {
      const trimmedMsgText = msgText.trim();
      if (trimmedMsgText === "") {
        return;
      }
      // if (!validateDescription(trimmedMsgText)) {
      //   message.error(
      //     "Invalid input. Please avoid phone numbers, email formats, addresses, and numeric patterns."
      //   );
      //   return;
      // }

      setMsgText("");
      const result = await props?.client.graphql({
        query: createMessage,
        variables: {
          input: {
            content: {
              sender: profileData?.username,
              text: trimmedMsgText,
            },
            roomId: props?.activeUser?.id,
          },
        },
        headers: {
          "x-api-key": props?.apiKey,
        },
      });
      await updateRoomLastMsg();
    } catch (e) {
      console.error("in create message error", e);
    }
  };

  const updateRoomLastMsg = async () => {
    try {
      const result = await props?.client.graphql({
        query: updateRoom,
        variables: {
          input: {
            lastMessage: msgText,
            id: props?.activeUser?.id,
          },
        },
        headers: {
          "x-api-key": props?.apiKey,
        },
      });
    } catch (e) {
      console.error("in update room error", e);
    }
  };
  const updateMessageStatus = async () => {
    if (
      props.lastMsgObj &&
      props.lastMsgObj?.content?.sender !== profileData?.username
    ) {
      try {
        const result = await props?.client.graphql({
          query: updateMessageStatuses,
          variables: {
            input: {
              roomId: props?.lastMsgObj?.roomId,
              username: profileData?.username,
            },
          },
          headers: {
            "x-api-key": props?.apiKey,
          },
        });
      } catch (e) {
        console.error("in message status update room error", e);
      }
    }
  };

  const EnterPress = (e) => {
    if (msgText !== "") {
      if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault();
        createSingleMessage();
      }
    }
  };

  return (
    <div className="premium-message-submit">
      <div className="premium-message-submit-box">
        <TextArea
          className="premium-message-inputtext"
          style={{
            width: "100%",
            outline: "none",
            background: "transparent",
            border: "none",
            paddingLeft: "0px",
          }}
          rows="2"
          placeholder="Type your message here ..."
          onKeyDown={EnterPress}
          variant="borderless"
          onChange={(e) => setMsgText(e.target.value)}
          value={msgText}
          onFocus={() => {
            updateMessageStatus();
          }}
        />
        <div className="submit-btn-icon shadow" onClick={createSingleMessage}>
          <img src={images.submitBtnIcon} alt="Send Message" />
        </div>
      </div>
    </div>
  );
}
