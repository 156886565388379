import { put } from "redux-saga/effects";
import { message } from "antd";

import NotificationApiCaller from "../../config/api/notificationapicaller";

import { NotificationAction } from "../actions";

export default class NotificationMiddleware {
  static *getNotifications({ cb }) {
    try {
      const res = yield NotificationApiCaller.Get(`/notification/getsns`);

      if (res?.status === 200) {
        yield put(
          NotificationAction.getNotificationsSuccess({
            count: res.data?.count,
            data: res.data?.Data,
          })
        );
      } else {
        yield put(NotificationAction.getNotificationsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(NotificationAction.getNotificationsFailure());
    }
  }
  static *updateNotification({ payload, cb }) {
    try {
      // const res = yield NotificationApiCaller.Delete(
      //   `/activity/block-profile/${payload.blockedId}`
      // );
      // if (res.status === 200) {
      //   yield put(NotificationAction.updateNotificationSuccess(res.data.Data));
      // } else {
      //   // message.error("Something went wrong while unblocking.");
      //   yield put(NotificationAction.updateNotificationFailure());
      // }
      // if (cb) {
      //   cb(res.data.Data);
      // }
    } catch (err) {
      // message.error("Something went wrong while unblocking.");
      yield put(NotificationAction.updateNotificationFailure());
    }
  }
}
