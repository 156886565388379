import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Input, Modal, Empty } from "antd";
import moment from "moment";

import { MyNotesAction } from "../../store/actions";

import DashboardCard from "../../components/dashboardcard/dashboardcard";
import { PrimaryButton, WhiteButton } from "../../components/button";
import Cardloader from "../../components/loader/cardloader";
import Spacer from "../../components/spacer/spacer";
import UnderHeaderMenu from "../../components/underheadermenu";

import useScreenSize from "../../utils/usescreensize";
import SortDropdown from "../../components/dropdown/sortdropdown";

import images from "../../assets";
import "./styles.css";

const { TextArea } = Input;

const MyNotes = () => {
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const { myNotes, myNotesCount, isLoading } = useSelector(
    (state) => state?.myNotes
  );
  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });

  const [paginationStack, setPaginationStack] = useState([null]);
  const [note, setNote] = useState("");
  const [noteObject, setNoteObject] = useState("");
  const [name, setName] = useState("");
  const [state, setState] = useState({
    sortOption: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setPaginationStack([null]);
    getNotes([null]);
  }, [state]);

  const getNotes = (prevKey) => {
    const payload = {
      limit: pageSize,
      sort: state?.sortOption,
      lastEvaluatedKey: prevKey,
    };
    setPageNo(1);
    dispatch(
      MyNotesAction.getNotes(payload, (res) => {
        const exists = paginationStack?.some(
          (item) =>
            item?.username["S"] === res.data.lastEvaluatedKey?.username["S"]
        );
        if (!exists) {
          setPaginationStack([...paginationStack, res.data.lastEvaluatedKey]);
        }
      })
    );
  };

  const handlePageChange = async (pageNumber) => {
    setPageNo(pageNumber);
    if (pageNumber < pageNo) {
      await goBack();
    } else {
      getNotes(paginationStack[pageNumber - 1]);
    }
  };
  const goBack = async () => {
    if (paginationStack.length > 1) {
      const prevKey = paginationStack[pageNo - 2];
      setPaginationStack(paginationStack.slice(0, -1));
      getNotes(prevKey);
    }
  };

  const getNote = (username) => {
    const payload = { username };
    dispatch(
      MyNotesAction.getNote(payload, (res) => {
        setIsModalOpen(true);
        setNoteObject(res);
        setNote(res?.note);
      })
    );
  };

  const handleSendNote = () => {
    setIsModalOpen(false);
    const payload = { note, receiverId: noteObject?.receiverId };
    dispatch(MyNotesAction.setNotes(payload));
  };

  return (
    <div>
      {screenSize.width < 1023 && (
        <UnderHeaderMenu titlename="My Notes">
          <div style={{ width: "110px" }}>
            <SortDropdown
              data={[
                { label: "Newest First", value: "desc" },
                { label: "Oldest First", value: "asc" },
              ]}
              onChange={(e) => setState({ ...state, sortOption: e })}
              value={state?.sortOption || undefined}
              placeholder="Sort by"
            />
          </div>
        </UnderHeaderMenu>
      )}
      <Spacer height="16px" />
      <div className="mynote-section-top-part-container">
        <div></div>
        {screenSize.width > 1023 && (
          <div className="mynote-section-header-container">
            <h1 className="mynote-header">My Notes</h1>
          </div>
        )}
        {screenSize.width > 1023 && (
          <div className="mynote-sort-container">
            <div style={{ width: "150px" }}>
              <SortDropdown
                data={[
                  { label: "Newest First", value: "desc" },
                  { label: "Oldest First", value: "asc" },
                ]}
                onChange={(e) => setState({ ...state, sortOption: e })}
                value={state?.sortOption || undefined}
                placeholder="Sort by"
              />
            </div>
          </div>
        )}
      </div>
      <Spacer height="12px" />
      <div className="mynote-results-container">
        {isLoading ? (
          <Cardloader />
        ) : (
          <>
            {myNotes.length > 0 ? (
              <>
                <div className="mynote-results">
                  {myNotes?.map((item, index) => (
                    <DashboardCard
                      item={item}
                      index={index}
                      key={index}
                      redirectedFrom="myNotes"
                    >
                      <div className="flex flex-col w-full gap-3">
                        <div className="mynote-card-text">
                          <p>Last Edited:</p>
                          <p>{moment(item?.updatedAt).fromNow()}</p>
                        </div>

                        <PrimaryButton
                          style={{ height: "32px" }}
                          text="View Note"
                          onClick={() => {
                            getNote(item?.username);
                            setName(`${item?.firstName} ${item?.lastName}`);
                          }}
                        />
                      </div>
                    </DashboardCard>
                  ))}
                </div>
                <Spacer height="36px" />
                <div className="flex justify-center items-center">
                  <Pagination
                    simple={{ readOnly: true }}
                    pageSize={pageSize}
                    total={myNotesCount}
                    current={pageNo}
                    showSizeChanger={false}
                    onChange={handlePageChange}
                  />
                </div>
                <Spacer height="24px" />
              </>
            ) : (
              <Empty description={<p>No Results found</p>} />
            )}
          </>
        )}

        <Modal
          centered={true}
          open={isModalOpen}
          onCancel={() => {
            setNote("");
            setNoteObject(null);
            setName(null);
            setIsModalOpen(false);
          }}
          footer={false}
          closable={false}
          className="forgot-password-modal"
        >
          <h1
            style={{
              position: "absolute",
              top: "10px",
            }}
            className="mynote-header"
          >
            My Notes
          </h1>
          <img
            src={images?.closeIcon}
            className="modal-close-icon"
            alt=""
            onClick={() => {
              setIsModalOpen(false);
              setNote("");
              setNoteObject(null);
              setName(null);
            }}
          />
          <div className="user-report-user-content">
            <div className="user-report-body">
              <div className="user-report-heading">
                <p
                  style={{
                    color: "#222",
                    fontFamily: " Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Add your personal note about {name}
                </p>
              </div>
              <div className="user-report-message">
                <TextArea
                  showCount
                  maxLength={1000}
                  rows="10"
                  placeholder="Type your personal note ..."
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
            <Spacer height="48px" />
            <div className="user-report-footer">
              <div className="user-report-btn">
                <WhiteButton
                  style={{
                    border: "2px solid var #EAE6ED",
                  }}
                  text={"Close"}
                  onClick={() => {
                    setNote("");
                    setNoteObject(null);
                    setName(null);
                    setIsModalOpen(false);
                  }}
                />
                <PrimaryButton text={"Save"} onClick={handleSendNote} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default MyNotes;
