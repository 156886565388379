import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import calculateage from "../../utils/calculateage";

import images from "../../assets";
import "./styles.css";

const DashboardCard = (props) => {
  const [viewed, setViewed] = useState(false);

  const { profileViewedAll } = useSelector((state) => state.profileView);

  // useEffect(() => {
  //   const viewedProfile = profileViewedAll?.some(
  //     (profile) => profile.username === props?.item?.username
  //   );
  //   setViewed(viewedProfile);
  // }, []);

  return (
    <div
      {...props}
      className={`card-container ${props?.item?.matchOfTheDay && "span2"}`}
    >
      {props?.item?.matchOfTheDay && (
        <div className="match-of-the-day">
          <div className="top-text">Match of the Day</div>
          <div className="bottom-text">
            Your handpicked match exclusively for today
          </div>
          <img src={images.newtag} alt="new" className="new-tag" />
        </div>
      )}
      {viewed && (
        <div className="card-viewtag">
          <img src={images.viewedTag} alt="viewtag" />
          <div className="card-viewtag-text">Viewed</div>
        </div>
      )}

      {props?.item?.matchOfTheDay ? (
        <div className="match-of-the-day-layout">
          <Link
            to={`/view-profile/${props?.item?.username}?from=${props?.redirectedFrom}`}
            className={`${props?.blocklist && "pointer-events-none"} w-[80%]`}
          >
            <div className="match-of-the-day-layout-top">
              <div className="match-of-the-day-photo">
                <img
                  src={
                    props?.item?.profilePicture &&
                    props?.item?.profilePicture !== "null"
                      ? props?.item?.profilePicture
                      : props?.item?.gender === "male"
                      ? images?.defaultMaleIcon
                      : images?.defaultFemaleIcon
                  }
                  alt={props?.index}
                  className="match-of-the-day-layout-profileimg"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      props?.item?.gender === "male"
                        ? images?.defaultMaleIcon
                        : images?.defaultFemaleIcon;
                  }}
                />
                {props?.item?.active && (
                  <img
                    src={images.cardActive}
                    className="match-of-the-day-active"
                    alt="active"
                  />
                )}
              </div>
              <div className="match-of-the-day-layout-top-right">
                <div className="match-of-the-day-badge-container">
                  {(props?.profileData?.trustbadgeStatus === "approved" ||
                    props?.profileData?.premiumStatus === true) && (
                    <div className="match-of-the-day-card-badge">
                      {props?.profileData?.trustbadgeStatus === "approved" && (
                        <img
                          src={images.cardTick}
                          alt="pro"
                          className="card-badge-icon"
                        />
                      )}
                      {props?.profileData?.premiumStatus === true && (
                        <img
                          src={images.premiumIcon}
                          alt="verified"
                          className="card-badge-icon"
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="match-of-the-day-details-container">
                  <div className="card-name-age">
                    {props?.item?.firstName} {props?.item?.lastName},{" "}
                    {props?.item?.dateOfBirth !== "null"
                      ? `${calculateage(props?.item?.dateOfBirth)} yrs`
                      : "N/A"}
                  </div>
                  <div className="card-location-profession">
                    <img src={images.cardLocation} alt="location" />
                    <div>
                      {props?.item?.stateProvince}, {props?.item?.country}
                    </div>
                  </div>
                  {props?.item?.profession &&
                    props?.item?.profession !== "null" && (
                      <div className="card-location-profession">
                        <img src={images.cardProfession} alt="profession" />
                        <div>{props?.item?.profession}</div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </Link>
          <div className="match-of-the-day-layout-bottom">{props.children}</div>
        </div>
      ) : (
        <>
          <div>
            <Link
              to={`/view-profile/${props?.item?.username}?from=${props?.redirectedFrom}`}
              className={`${props?.blocklist && "pointer-events-none"}`}
            >
              <img
                src={
                  props?.item?.profilePicture &&
                  props?.item?.profilePicture !== "null"
                    ? props?.item?.profilePicture
                    : props?.item?.gender === "male"
                    ? images?.defaultMaleIcon
                    : images?.defaultFemaleIcon
                }
                alt={props?.index}
                className="card-container-profileimg"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    props?.item?.gender === "male"
                      ? images?.defaultMaleIcon
                      : images?.defaultFemaleIcon;
                }}
              />
            </Link>
          </div>
          <div className="card-bottom-part">
            {(props?.profileData?.trustbadgeStatus === "approved" ||
              props?.profileData?.premiumStatus === true) && (
              <div className="card-badge">
                {props?.profileData?.trustbadgeStatus === "approved" && (
                  <img
                    src={images.cardTick}
                    alt="pro"
                    className="card-badge-icon"
                  />
                )}
                {props?.profileData?.premiumStatus === true && (
                  <img
                    src={images.premiumIcon}
                    alt="verified"
                    className="card-badge-icon"
                  />
                )}
              </div>
            )}
            {props?.item?.active && (
              <img
                src={images.cardActive}
                className="card-active"
                alt="active"
              />
            )}
            <div className="card-bottom-top">
              <Link
                to={`/view-profile/${props?.item?.username}?from=${props?.redirectedFrom}`}
                className={`${props?.blocklist && "pointer-events-none"}`}
              >
                <div className="card-name-age">
                  {props?.item?.firstName} {props?.item?.lastName},{" "}
                  {props?.item?.dateOfBirth !== "null"
                    ? `${calculateage(props?.item?.dateOfBirth)} yrs`
                    : "N/A"}
                </div>
              </Link>
              <div className="card-location-profession">
                <img src={images.cardLocation} alt="location" />
                <div>
                  {props?.item?.stateProvince}, {props?.item?.country}
                </div>
              </div>
              {props?.item?.profession &&
                props?.item?.profession !== "null" && (
                  <div className="card-location-profession">
                    <img src={images.cardProfession} alt="profession" />
                    <div>{props?.item?.profession}</div>
                  </div>
                )}
            </div>
            <div className="card-bottom-bottom">{props.children}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardCard;
