import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ProfileAction } from "../../store/actions";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import EditProfileInput from "../../components/inputbox/editprofileinput";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";
import PrimaryButton from "../../components/button/primarybutton";
import ErrorText from "../../components/errortext/errortext";
import Loader from "../../components/loader/loader";
import Spacer from "../../components/spacer/spacer";

import {
  beard,
  bloodGroup,
  bodyType,
  complexion,
  foodHabit,
  hairColor,
  hairLengthMale,
  hairLengthFemale,
  hairTypeMale,
  hairTypeFemale,
  healthCondition,
  heightList,
  hijab,
  smoking,
  waliItems,
} from "../../utils/arrayitems";

import images from "../../assets";

import TabHeading from "./tabheading";
import "./styles.css";

const PhyscialLifestyle = (props) => {
  const dispatch = useDispatch();
  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [isDisabled, setDisabled] = useState(false);
  const [state, setState] = useState({
    height: "",
    hairColor: "",
    hairType: "",
    hairLength: "",
    complexion: "",
    bloodGroup: "",
    bodyType: "",
    healthCondition: "",
    beard: "",
    hijab: "",
    wali: "",
    waliEmail: "",
    foodHabit: [],
    smoking: "",
  });
  const [error, setError] = useState({
    height: "",
    healthCondition: "",
    beard: "",
    wali: "",
    hijab: "",
  });

  useEffect(() => {
    setState({
      ...state,
      height: profileData?.height ?? "",
      hairColor: profileData?.hairColor ?? "",
      hairType: profileData?.hairType ?? "",
      hairLength: profileData?.hairLength ?? "",
      complexion: profileData?.complexion ?? "",
      bloodGroup: profileData?.bloodGroup ?? "",
      bodyType: profileData?.bodyType ?? "",
      physicalStatus: profileData?.physicalStatus ?? "",
      beard: profileData?.beard ?? "",
      hijab: profileData?.hijab ?? "",
      wali: profileData?.wali ?? "",
      waliEmail: profileData?.waliEmail ?? "",
      foodHabit: profileData?.foodHabit ?? "",
      smoking: profileData?.smoking ?? "",
    });
  }, [profileLoading]);

  const handleSubmit = () => {
    if (!state?.height) {
      setError({
        ...error,
        height: "height cannot be empty",
      });
    } else if (!state?.physicalStatus) {
      setError({
        ...error,
        physicalStatus: "Health condition cannot be empty",
      });
    } else if (profileData?.gender?.toLowerCase() === "male" && !state?.beard) {
      setError({
        ...error,
        beard: "Beard cannot be empty",
      });
    } else if (
      profileData?.gender?.toLowerCase() === "female" &&
      !state?.wali
    ) {
      setError({
        ...error,
        wali: "Wali cannot be empty",
      });
    } else if (
      profileData?.gender?.toLowerCase() === "female" &&
      !state?.hijab
    ) {
      setError({
        ...error,
        hijab: "Hijab cannot be empty",
      });
    } else {
      setDisabled(true);
      const payload = state;

      dispatch(
        ProfileAction.updateProfile(payload, () => {
          setDisabled(false);
          dispatch(ProfileAction.getProfile());
          props?.handleTab("familyDetails");
        })
      );
    }
  };
  return (
    <>
      <TabHeading
        image={images.editProfilePhysicalLifestyle}
        title="Physical Appearance & Lifestyle"
        subtitle="Complete to easily find your ideal partner!"
      />
      <div className="edit-profile-content">
        {profileLoading ? (
          <Loader />
        ) : (
          <>
            {/* height */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Height" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={heightList}
              onChange={(e) => {
                setState({ ...state, height: e });
                setError({ ...error, height: "" });
              }}
              value={state.height || undefined}
            />
            {error?.height && <ErrorText text={error?.height} />}
            <Spacer height="12px" />

            {/* hair color */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Hair Color" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={hairColor}
              onChange={(e) => setState({ ...state, hairColor: e })}
              value={state.hairColor || undefined}
            />

            <Spacer height="12px" />

            {/* Hair Type */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Hair Type" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={
                profileData?.gender === "male" ? hairTypeMale : hairTypeFemale
              }
              onChange={(e) => setState({ ...state, hairType: e })}
              value={state.hairType || undefined}
            />

            <Spacer height="12px" />

            {/* Hair Length */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Hair Length" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={
                profileData?.gender === "male"
                  ? hairLengthMale
                  : hairLengthFemale
              }
              onChange={(e) => setState({ ...state, hairLength: e })}
              value={state.hairLength || undefined}
            />

            <Spacer height="12px" />

            {/* Complexion */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Complexion" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={complexion}
              onChange={(e) => setState({ ...state, complexion: e })}
              value={state.complexion || undefined}
            />

            <Spacer height="12px" />

            {/* Blood Group */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Blood Group" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={bloodGroup}
              onChange={(e) => setState({ ...state, bloodGroup: e })}
              value={state.bloodGroup || undefined}
            />

            <Spacer height="12px" />

            {/* Body Type */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Body Type" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={bodyType}
              onChange={(e) => setState({ ...state, bodyType: e })}
              value={state.bodyType || undefined}
            />

            <Spacer height="12px" />

            {/* Health Condition */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Health Condition" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={healthCondition}
              onChange={(e) => {
                setState({ ...state, physicalStatus: e });
                setError({ ...error, physicalStatus: "" });
              }}
              value={state.physicalStatus || undefined}
            />
            {error?.physicalStatus && (
              <ErrorText text={error?.physicalStatus} />
            )}

            <Spacer height="12px" />

            {/* Beard or hijab*/}
            {profileData?.gender?.toLowerCase() === "male" ? (
              <>
                <div className="edit-profile-label-container">
                  <EditProfileBoxLabel label="Beard" required={true} />
                </div>
                <Spacer height="8px" />
                <EditProfileDropdown
                  placeholder="Please select"
                  data={beard}
                  onChange={(e) => {
                    setState({ ...state, beard: e });
                    setError({ ...error, beard: "" });
                  }}
                  value={state.beard || undefined}
                />
                {error?.beard && <ErrorText text={error?.beard} />}
              </>
            ) : (
              <>
                <div className="edit-profile-label-container">
                  <EditProfileBoxLabel label="Hijab" required={true} />
                </div>
                <Spacer height="8px" />
                <EditProfileDropdown
                  placeholder="Please select"
                  data={hijab}
                  onChange={(e) => {
                    setState({ ...state, hijab: e });
                    setError({ ...error, hijab: "" });
                  }}
                  value={state.hijab || undefined}
                />
                {error?.hijab && <ErrorText text={error?.hijab} />}
              </>
            )}

            <Spacer height="12px" />
            {/* wali */}
            {profileData?.gender?.toLowerCase() === "female" && (
              <>
                <div className="edit-profile-label-container">
                  <EditProfileBoxLabel label="Wali" required={true} />
                </div>
                <Spacer height="8px" />
                <EditProfileDropdown
                  placeholder="Please select"
                  data={waliItems}
                  onChange={(e) => {
                    setState({ ...state, wali: e });
                    setError({ ...error, wali: "" });
                  }}
                  value={state.wali || undefined}
                />
                {error?.wali && <ErrorText text={error?.wali} />}
                <Spacer height="12px" />
                <div className="edit-profile-label-container">
                  <EditProfileBoxLabel label="Wali Email" />
                </div>
                <Spacer height="8px" />
                <EditProfileInput
                  placeholder="Wali email"
                  value={state.waliEmail || undefined}
                  onChange={(e) => {
                    setState({ ...state, waliEmail: e.target.value });
                    setError({ ...error, waliEmail: "" });
                  }}
                />
                {error?.wali && <ErrorText text={error?.wali} />}
                <Spacer height="12px" />
              </>
            )}

            {/* Food Habit */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Food Habit" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Please select"
              data={foodHabit}
              onChange={(e) => setState({ ...state, foodHabit: e })}
              value={state.foodHabit || undefined}
            />

            <Spacer height="12px" />

            {/* Smoking */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Smoking" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={smoking}
              onChange={(e) => setState({ ...state, smoking: e })}
              value={state.smoking || undefined}
            />
          </>
        )}
        <Spacer height="32px" />
        <PrimaryButton
          text="Save & Continue"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default PhyscialLifestyle;
