import { put } from "redux-saga/effects";
import { message } from "antd";

import UserApiCaller from "../../config/api/userapicaller";

import { MatchAction } from "../actions";

export default class MatchMiddleware {
  static *updateMatchPreference({ payload, cb }) {
    try {
      const res = yield UserApiCaller.Post("/user/matchpreference", payload);
      if (res.status === 200) {
        message.success("Successfully updated match preferences.");
        yield put(MatchAction.updateMatchPreferenceSuccess(res.data.Data));
      } else {
        message.error("Could not update match preferences.");
        yield put(MatchAction.updateMatchPreferenceFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      message.error("Something went wrong");
      yield put(MatchAction.updateMatchPreferenceFailure());
    }
  }
}
