import { put } from "redux-saga/effects";
import { message } from "antd";

import UserApiCaller from "../../config/api/userapicaller";
import MediaApiCaller from "../../config/api/mediaapicaller";

import { ProfileAction } from "../actions";

export default class ProfileMiddleware {
  static *getProfile({ cb }) {
    try {
      const res = yield UserApiCaller.Get("/user/profile");
      if (res.data.data.responseMeta.httpStatusCode === 200) {
        yield put(ProfileAction.getProfileSuccess(res.data.data?.user));
      } else {
        yield put(ProfileAction.getProfileFailure());
      }
      if (cb) {
        cb(res.data.data?.user);
      }
    } catch (err) {
      // message.error("Network Error");
      yield put(ProfileAction.getProfileFailure());
    }
  }
  static *removeProfileStore({ cb }) {
    try {
      yield put(ProfileAction.removeProfileStoreSuccess());
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(ProfileAction.removeProfileStoreFailure());
    }
  }
  static *changeRequest({ payload, cb }) {
    try {
      const res = yield UserApiCaller.Post("/user/attrchange", payload);
      if (res.status === 200) {
        message.success("Request sent to admin successfully.");
        yield put(ProfileAction.changeRequestSuccess());
      } else {
        yield put(ProfileAction.changeRequestFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      message.error("Network Error");
      yield put(ProfileAction.changeRequestFailure());
    }
  }
  static *changeProfileStatus({ payload, cb }) {
    try {
      const res = yield UserApiCaller.Put("/user/profile", payload);
      if (res.data?.Data?.httpStatusCode === 200) {
        yield put(ProfileAction.changeProfileStatusSuccess());
      } else {
        yield put(ProfileAction.changeProfileStatusFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      message.error("Network Error");
      yield put(ProfileAction.changeProfileStatusFailure());
    }
  }
  static *updateProfile({ payload, cb }) {
    try {
      const res = yield UserApiCaller.Put("/user/profile", payload);
      if (res.status === 200) {
        message.success("Profile updated successfully");
        yield put(ProfileAction.updateProfileSuccess());
      } else {
        message.error("Profile update failed");
        yield put(ProfileAction.updateProfileFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      message.error("Network Error");
      yield put(ProfileAction.updateProfileFailure());
    }
  }
  static *updateProfilePic({ payload, cb }) {
    try {
      const res = yield UserApiCaller.Put("/user/profilepicture", payload);
      if (res.data.Data.httpStatusCode === 200) {
        message.success("Profile picture updated successfully");
        yield put(ProfileAction.updateProfilePicSuccess());
      } else {
        message.error("Something went wrong.");
        yield put(ProfileAction.updateProfilePicFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      message.error("Network Error");
      yield put(ProfileAction.updateProfilePicFailure());
    }
  }

  static *uploadPhoto({ payload, cb }) {
    try {
      const res = yield MediaApiCaller.Post("/media/uploadphoto", payload);

      if (res.status === 200) {
        yield put(ProfileAction.uploadPhotoSuccess());
      } else {
        yield put(ProfileAction.uploadPhotoFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      message.error("Network Error");
      yield put(ProfileAction.uploadPhotoFailure());
    }
  }
  static *uploadTrustBadge({ payload, cb }) {
    try {
      const res = yield MediaApiCaller.Post("/media/uploadidentity", payload);
      if (res.status === 200) {
        message.success("Your trust badge has been sent for approval");
        yield put(ProfileAction.uploadTrustBadgeSuccess());
      } else {
        message.error("Something went wrong");
        yield put(ProfileAction.uploadTrustBadgeFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      message.error("Network Error");
      yield put(ProfileAction.uploadTrustBadgeFailure());
    }
  }
  static *getTrustBadge({ payload, cb }) {
    try {
      const res = yield UserApiCaller.Get("/user/identity");

      if (res.status === 200) {
        yield put(ProfileAction.getTrustBadgeSuccess(res.data.Data));
      } else {
        yield put(ProfileAction.getTrustBadgeFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(ProfileAction.getTrustBadgeFailure());
    }
  }
  static *deleteTrustBadge({ payload, cb }) {
    try {
      const res = yield UserApiCaller.Post("/user/deleteidentity", payload);

      if (res.status === 200) {
        yield put(ProfileAction.deleteTrustBadgeSuccess(res.data.Data));
      } else {
        yield put(ProfileAction.deleteTrustBadgeFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(ProfileAction.deleteTrustBadgeFailure());
    }
  }
  static *deletePhoto({ payload, cb }) {
    try {
      const res = yield UserApiCaller.Put("/user/deletegallery", payload);

      if (res.status === 200) {
        message.success("Photo deleted successfully");
        yield put(ProfileAction.deletePhotoSuccess());
      } else {
        message.error("Something went wrong");
        yield put(ProfileAction.deletePhotoFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      message.error("Network Error");
      yield put(ProfileAction.deletePhotoFailure());
    }
  }
}
