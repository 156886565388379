import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { ProfileLayout } from "../../components/layout";
import PrimaryButton from "../../components/button/primarybutton";
import Spacer from "../../components/spacer/spacer";
import PhotoGallery from "../../components/photogallery/photogallery";

import useScreenSize from "../../utils/usescreensize";

import images from "../../assets";

import DocumentUpload from "./documentupload";

import "./styles.css";

const Gallery = () => {
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const profileData = useSelector((state) => state?.profile?.profile);

  return (
    <ProfileLayout>
      <div className="edit-profile-container">
        {screenSize.width > 1023 && (
          <div className="edit-profile-header">
            <div className="gallery-btn">
              <PrimaryButton
                text="Back"
                icon={images.arrowIconLeft}
                iconalign="left"
                style={{
                  width: "auto",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  height: "32px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
                onClick={() => window.history.back()}
              />
            </div>
            <h2 className="edit-profile-heading">Photo Gallery</h2>
          </div>
        )}
        <Spacer height="32px" />
        <div className="gallery-content-container">
          <h5 className="edit-profile-content-heading-subtitle">
            NobleMarriage allows users to upload up to 4 photos. You can delete
            any photo to upload new photo.
            <br />
            If you do not hear from us within 24 hours, please send an email
            with your username or user ID to
            <span> support@noblemarriage.com</span>
          </h5>
          <a
            className="need-help-whatsapp"
            href={
              "https://api.whatsapp.com/send/?phone=447501247364&text=I have a question about the Photo Gallery."
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={images.whatsAppBtn} alt="" />
          </a>
          <Spacer height="16px" />
          {(!profileData?.imageGallery ||
            profileData?.imageGallery?.length <= 3) && <DocumentUpload />}

          {(!profileData?.imageGallery ||
            profileData?.imageGallery?.length === 0) && (
            <>
              <Spacer height="60px" />
              <div className="gallery-footer">
                <div className="gallery-document-verified">
                  <img src={images.photoGalleryIcon} alt="Document Verified" />
                </div>
                <div className="gallery-footer-content">
                  <p>
                    You have not uploaded a photo yet! Users having a clear
                    picture are <b>80%</b> more likely to discover their desired
                    match.
                  </p>
                </div>
              </div>
            </>
          )}
          <Spacer height="40px" />
        </div>
        <PhotoGallery />
        <Spacer height="40px" />
      </div>
    </ProfileLayout>
  );
};

export default Gallery;
