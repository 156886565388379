// ui
export const COLLAPSED = "COLLAPSED";
export const COLLAPSED_SUCCESS = "COLLAPSED_SUCCESS";
export const COLLAPSED_FAILURE = "COLLAPSED_FAILURE";

//auth
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";
export const CONFIRM_PASSWORD_SUCCESS = "CONFIRM_PASSWORD_SUCCESS";
export const CONFIRM_PASSWORD_FAILURE = "CONFIRM_PASSWORD_FAILURE";

export const CONFIRM_OTP_RECOVER = "CONFIRM_OTP_RECOVER";
export const CONFIRM_OTP_RECOVER_SUCCESS = "CONFIRM_OTP_RECOVER_SUCCESS";
export const CONFIRM_OTP_RECOVER_FAILURE = "CONFIRM_OTP_RECOVER_FAILURE";

export const RESEND_OTP = "RESEND_OTP";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";

export const EMAIL_CHANGE_REQ = "EMAIL_CHANGE_REQ";
export const EMAIL_CHANGE_REQ_SUCCESS = "EMAIL_CHANGE_REQ_SUCCESS";
export const EMAIL_CHANGE_REQ_FAILURE = "EMAIL_CHANGE_REQ_FAILURE";

export const EMAIL_CHANGE_VERIFY = "EMAIL_CHANGE_VERIFY";
export const EMAIL_CHANGE_VERIFY_SUCCESS = "EMAIL_CHANGE_VERIFY_SUCCESS";
export const EMAIL_CHANGE_VERIFY_FAILURE = "EMAIL_CHANGE_VERIFY_FAILURE";

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";

// search
export const QUICK_SEARCH = "QUICK_SEARCH";
export const QUICK_SEARCH_SUCCESS = "QUICK_SEARCH_SUCCESS";
export const QUICK_SEARCH_FAILURE = "QUICK_SEARCH_FAILURE";

export const BASIC_SEARCH = "BASIC_SEARCH";
export const BASIC_SEARCH_SUCCESS = "BASIC_SEARCH_SUCCESS";
export const BASIC_SEARCH_FAILURE = "BASIC_SEARCH_FAILURE";

export const ADVANCED_SEARCH = "ADVANCED_SEARCH";
export const ADVANCED_SEARCH_SUCCESS = "ADVANCED_SEARCH_SUCCESS";
export const ADVANCED_SEARCH_FAILURE = "ADVANCED_SEARCH_FAILURE";

export const QUICK_SEARCH_PAYLOAD = "QUICK_SEARCH_PAYLOAD";
export const QUICK_SEARCH_PAYLOAD_SUCCESS = "QUICK_SEARCH_PAYLOAD_SUCCESS";
export const QUICK_SEARCH_PAYLOAD_FAILURE = "QUICK_SEARCH_PAYLOAD_FAILURE";

export const SEARCH_PAYLOAD = "SEARCH_PAYLOAD";
export const SEARCH_PAYLOAD_SUCCESS = "SEARCH_PAYLOAD_SUCCESS";
export const SEARCH_PAYLOAD_FAILURE = "SEARCH_PAYLOAD_FAILURE";

export const ADVANCED_SEARCH_PAYLOAD = "ADVANCED_SEARCH_PAYLOAD";
export const ADVANCED_SEARCH_PAYLOAD_SUCCESS =
  "ADVANCED_SEARCH_PAYLOAD_SUCCESS";
export const ADVANCED_SEARCH_PAYLOAD_FAILURE =
  "ADVANCED_SEARCH_PAYLOAD_FAILURE";

export const RECOMMENDED_SEARCH = "RECOMMENDED_SEARCH";
export const RECOMMENDED_SEARCH_SUCCESS = "RECOMMENDED_SEARCH_SUCCESS";
export const RECOMMENDED_SEARCH_FAILURE = "RECOMMENDED_SEARCH_FAILURE";

export const GET_MOD = "GET_MOD";
export const GET_MOD_SUCCESS = "GET_MOD_SUCCESS";
export const GET_MOD_FAILURE = "GET_MOD_FAILURE";

// profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const CHANGE_REQ = "CHANGE_REQ";
export const CHANGE_REQ_SUCCESS = "CHANGE_REQ_SUCCESS";
export const CHANGE_REQ_FAILURE = "CHANGE_REQ_FAILURE";

export const UPLOAD_PHOTO = "UPLOAD_PHOTO";
export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS";
export const UPLOAD_PHOTO_FAILURE = "UPLOAD_PHOTO_FAILURE";

export const UPLOAD_TRUST_BADGE = "UPLOAD_TRUST_BADGE";
export const UPLOAD_TRUST_BADGE_SUCCESS = "UPLOAD_TRUST_BADGE_SUCCESS";
export const UPLOAD_TRUST_BADGE_FAILURE = "UPLOAD_TRUST_BADGE_FAILURE";

export const GET_TRUST_BADGE = "GET_TRUST_BADGE";
export const GET_TRUST_BADGE_SUCCESS = "GET_TRUST_BADGE_SUCCESS";
export const GET_TRUST_BADGE_FAILURE = "GET_TRUST_BADGE_FAILURE";

export const DELETE_TRUST_BADGE = "DELETE_TRUST_BADGE";
export const DELETE_TRUST_BADGE_SUCCESS = "DELETE_TRUST_BADGE_SUCCESS";
export const DELETE_TRUST_BADGE_FAILURE = "DELETE_TRUST_BADGE_FAILURE";

export const DELETE_PHOTO = "DELETE_PHOTO";
export const DELETE_PHOTO_SUCCESS = "DELETE_PHOTO_SUCCESS";
export const DELETE_PHOTO_FAILURE = "DELETE_PHOTO_FAILURE";

export const UPDATE_PROFILE_PIC = "UPDATE_PROFILE_PIC";
export const UPDATE_PROFILE_PIC_SUCCESS = "UPDATE_PROFILE_PIC_SUCCESS";
export const UPDATE_PROFILE_PIC_FAILURE = "UPDATE_PROFILE_PIC_FAILURE";

export const REMOVE_PROFILE_STORE = "REMOVE_PROFILE_STORE";
export const REMOVE_PROFILE_STORE_SUCCESS = "REMOVE_PROFILE_STORE_SUCCESS";
export const REMOVE_PROFILE_STORE_FAILURE = "REMOVE_PROFILE_STORE_FAILURE";

export const CHANGE_PROFILE_STATUS = "CHANGE_PROFILE_STATUS";
export const CHANGE_PROFILE_STATUS_SUCCESS = "CHANGE_PROFILE_STATUS_SUCCESS";
export const CHANGE_PROFILE_STATUS_FAILURE = "CHANGE_PROFILE_STATUS_FAILURE";

// others profile
export const GET_OTHERS_PROFILE = "GET_OTHERS_PROFILE";
export const GET_OTHERS_PROFILE_SUCCESS = "GET_OTHERS_PROFILE_SUCCESS";
export const GET_OTHERS_PROFILE_FAILURE = "GET_OTHERS_PROFILE_FAILURE";

// match
export const UPDATE_MATCH_PREFERENCE = "UPDATE_MATCH_PREFERENCE";
export const UPDATE_MATCH_PREFERENCE_SUCCESS =
  "UPDATE_MATCH_PREFERENCE_SUCCESS";
export const UPDATE_MATCH_PREFERENCE_FAILURE =
  "UPDATE_MATCH_PREFERENCE_FAILURE";

// messages
export const SET_MESSAGES_LIST = "SET_MESSAGES_LIST";
export const SET_MESSAGES_LIST_SUCCESS = "SET_MESSAGES_LIST_SUCCESS";
export const SET_MESSAGES_LIST_FAILURE = "SET_MESSAGES_LIST_FAILURE";

// interests
export const GET_INTERESTS = "GET_INTERESTS";
export const GET_INTERESTS_SUCCESS = "GET_INTERESTS_SUCCESS";
export const GET_INTERESTS_FAILURE = "GET_INTERESTS_FAILURE";

export const GET_INTERESTS_ALL = "GET_INTERESTS_ALL";
export const GET_INTERESTS_ALL_SUCCESS = "GET_INTERESTS_ALL_SUCCESS";
export const GET_INTERESTS_ALL_FAILURE = "GET_INTERESTS_ALL_FAILURE";

export const SEND_INTEREST = "SEND_INTEREST";
export const SEND_INTEREST_SUCCESS = "SEND_INTEREST_SUCCESS";
export const SEND_INTEREST_FAILURE = "SEND_INTEREST_FAILURE";

export const UPDATE_INTEREST = "UPDATE_INTEREST";
export const UPDATE_INTEREST_SUCCESS = "UPDATE_INTEREST_SUCCESS";
export const UPDATE_INTEREST_FAILURE = "UPDATE_INTEREST_FAILURE";

// photo requests
export const GET_PHOTOREQ = "GET_PHOTOREQ";
export const GET_PHOTOREQ_SUCCESS = "GET_PHOTOREQ_SUCCESS";
export const GET_PHOTOREQ_FAILURE = "GET_PHOTOREQ_FAILURE";

export const GET_PHOTOREQ_ALL = "GET_PHOTOREQ_ALL";
export const GET_PHOTOREQ_ALL_SUCCESS = "GET_PHOTOREQ_ALL_SUCCESS";
export const GET_PHOTOREQ_ALL_FAILURE = "GET_PHOTOREQ_ALL_FAILURE";

export const SEND_PHOTOREQ = "SEND_PHOTOREQ";
export const SEND_PHOTOREQ_SUCCESS = "SEND_PHOTOREQ_SUCCESS";
export const SEND_PHOTOREQ_FAILURE = "SEND_PHOTOREQ_FAILURE";

export const UPDATE_PHOTOREQ = "UPDATE_PHOTOREQ";
export const UPDATE_PHOTOREQ_SUCCESS = "UPDATE_PHOTOREQ_SUCCESS";
export const UPDATE_PHOTOREQ_FAILURE = "UPDATE_PHOTOREQ_FAILURE";

// shortlist
export const GET_SHORTLIST = "GET_SHORTLIST";
export const GET_SHORTLIST_SUCCESS = "GET_SHORTLIST_SUCCESS";
export const GET_SHORTLIST_FAILURE = "GET_SHORTLIST_FAILURE";

export const GET_SHORTLIST_ALL = "GET_SHORTLIST_ALL";
export const GET_SHORTLIST_ALL_SUCCESS = "GET_SHORTLIST_ALL_SUCCESS";
export const GET_SHORTLIST_ALL_FAILURE = "GET_SHORTLIST_ALL_FAILURE";

export const SEND_SHORTLIST = "SEND_SHORTLIST";
export const SEND_SHORTLIST_SUCCESS = "SEND_SHORTLIST_SUCCESS";
export const SEND_SHORTLIST_FAILURE = "SEND_SHORTLIST_FAILURE";

// profile viewers
export const GET_PROFILE_VIEWED = "GET_PROFILE_VIEWED";
export const GET_PROFILE_VIEWED_SUCCESS = "GET_PROFILE_VIEWED_SUCCESS";
export const GET_PROFILE_VIEWED_FAILURE = "GET_PROFILE_VIEWED_FAILURE";

export const GET_ALL_PROFILE_VIEWED = "GET_ALL_PROFILE_VIEWED";
export const GET_ALL_PROFILE_VIEWED_SUCCESS = "GET_ALL_PROFILE_VIEWED_SUCCESS";
export const GET_ALL_PROFILE_VIEWED_FAILURE = "GET_ALL_PROFILE_VIEWED_FAILURE";

export const GET_PROFILE_VIEWER = "GET_PROFILE_VIEWER";
export const GET_PROFILE_VIEWER_SUCCESS = "GET_PROFILE_VIEWER_SUCCESS";
export const GET_PROFILE_VIEWER_FAILURE = "GET_PROFILE_VIEWER_FAILURE";

export const SET_PROFILE_VIEW = "SET_PROFILE_VIEW";
export const SET_PROFILE_VIEW_SUCCESS = "SET_PROFILE_VIEW_SUCCESS";
export const SET_PROFILE_VIEW_FAILURE = "SET_PROFILE_VIEW_FAILURE";

// contact viewers
export const GET_CONTACT_VIEW = "GET_CONTACT_VIEW";
export const GET_CONTACT_VIEW_SUCCESS = "GET_CONTACT_VIEW_SUCCESS";
export const GET_CONTACT_VIEW_FAILURE = "GET_CONTACT_VIEW_FAILURE";

export const GET_ALL_CONTACT_VIEW = "GET_ALL_CONTACT_VIEW";
export const GET_ALL_CONTACT_VIEW_SUCCESS = "GET_ALL_CONTACT_VIEW_SUCCESS";
export const GET_ALL_CONTACT_VIEW_FAILURE = "GET_ALL_CONTACT_VIEW_FAILURE";

export const SET_CONTACT_VIEW = "SET_CONTACT_VIEW";
export const SET_CONTACT_VIEW_SUCCESS = "SET_CONTACT_VIEW_SUCCESS";
export const SET_CONTACT_VIEW_FAILURE = "SET_CONTACT_VIEW_FAILURE";

// subscription
export const GET_PAYMENT_PLAN = "GET_PAYMENT_PLAN";
export const GET_PAYMENT_PLAN_SUCCESS = "GET_PAYMENT_PLAN_SUCCESS";
export const GET_PAYMENT_PLAN_FAILURE = "GET_PAYMENT_PLAN_FAILURE";

export const GET_SUBSCRIPTION_INFO = "GET_SUBSCRIPTION_INFO";
export const GET_SUBSCRIPTION_INFO_SUCCESS = "GET_SUBSCRIPTION_INFO_SUCCESS";
export const GET_SUBSCRIPTION_INFO_FAILURE = "GET_SUBSCRIPTION_INFO_FAILURE";

// universities
export const GET_UNIVERSITIES = "GET_UNIVERSITIES";
export const GET_UNIVERSITIES_SUCCESS = "GET_UNIVERSITIES_SUCCESS";
export const GET_UNIVERSITIES_FAILURE = "GET_UNIVERSITIES_FAILURE";

// new members
export const GET_NEW_MEMBERS = "GET_NEW_MEMBERS";
export const GET_NEW_MEMBERS_SUCCESS = "GET_NEW_MEMBERS_SUCCESS";
export const GET_NEW_MEMBERS_FAILURE = "GET_NEW_MEMBERS_FAILURE";

// blocklist
export const GET_BLOCKLIST = "GET_BLOCKLIST";
export const GET_BLOCKLIST_SUCCESS = "GET_BLOCKLIST_SUCCESS";
export const GET_BLOCKLIST_FAILURE = "GET_BLOCKLIST_FAILURE";

export const UPDATE_BLOCKLIST = "UPDATE_BLOCKLIST";
export const UPDATE_BLOCKLIST_SUCCESS = "UPDATE_BLOCKLIST_SUCCESS";
export const UPDATE_BLOCKLIST_FAILURE = "UPDATE_BLOCKLIST_FAILURE";

export const DELETE_BLOCKLIST = "DELETE_BLOCKLIST";
export const DELETE_BLOCKLIST_SUCCESS = "DELETE_BLOCKLIST_SUCCESS";
export const DELETE_BLOCKLIST_FAILURE = "DELETE_BLOCKLIST_FAILURE";

// my notes
export const GET_NOTE = "GET_NOTE";
export const GET_NOTE_SUCCESS = "GET_NOTE_SUCCESS";
export const GET_NOTE_FAILURE = "GET_NOTE_FAILURE";

export const GET_NOTES = "GET_NOTES";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_FAILURE = "GET_NOTES_FAILURE";

export const SET_NOTES = "SET_NOTES";
export const SET_NOTES_SUCCESS = "SET_NOTES_SUCCESS";
export const SET_NOTES_FAILURE = "SET_NOTES_FAILURE";

// my notes
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILURE = "UPDATE_NOTIFICATION_FAILURE";

// country state
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_STATES = "GET_STATES";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILURE = "GET_STATES_FAILURE";
