import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";

import {
  SearchAction,
  InterestAction,
  PhotoreqAction,
} from "../../store/actions";

import useScreenSize from "../../utils/usescreensize";
import DashboardCard from "../../components/dashboardcard/dashboardcard";
import CardBottomButton from "../../components/dashboardcard/cardbottombutton";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";

import Cardloader from "../../components/loader/cardloader";

import images from "../../assets";

import "./styles.css";
import Spacer from "../../components/spacer/spacer";
import UnderHeaderMenu from "../../components/underheadermenu";
import DashboardMobileCard from "../../components/dashboardcard/dashboardmobilecard";

const BasicSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const { basicMatches, basicCount, searchPayload, isBasicLoading } =
    useSelector((state) => state.search);
  const gender = useSelector((state) => state.profile?.profile?.gender);
  const { photoreq } = useSelector((state) => state.photoreq);
  const { interests } = useSelector((state) => state.interests);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [sortOption, setSortOption] = useState(null);
  const [matchedUsers, setMatchedUsers] = useState([]);

  useEffect(() => {
    setMatchedUsers(basicMatches);
  }, [basicMatches]);

  useEffect(() => {
    const payload = {
      ...searchPayload,
      from: 0,
      size: pageSize,
      gender,
      sort: sortOption,
    };
    setPageNo(1);

    if (gender) {
      dispatch(SearchAction.basicSearch(payload));
    }
  }, [searchPayload, sortOption]);

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    const payload = {
      ...searchPayload,
      from: (pageNumber - 1) * pageSize,
      size: pageSize,
      gender,
      sort: sortOption,
    };

    if (gender) {
      dispatch(SearchAction.basicSearch(payload));
    }
  };

  const handleSendInterest = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(InterestAction.sendInterest(payload));
  };

  const handleSendPhotoReq = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(PhotoreqAction.sendPhotoreq(payload));
  };

  return (
    <>
      {screenSize.width < 768 && (
        <UnderHeaderMenu onClick={() => navigate("/dashboard")}>
          <div style={{ width: "150px" }}>
            <EditProfileDropdown
              data={[
                { label: "Newest First", value: "desc" },
                { label: "Oldest First", value: "asc" },
              ]}
              onChange={(e) => setSortOption(e)}
              value={sortOption}
              placeholder="Sort by"
            />
          </div>
        </UnderHeaderMenu>
      )}
      <div className="recommended-container">
        <div className="header-container standard-search">
          <h1 className="section-heading">Search Results</h1>
          {screenSize.width > 768 && (
            <div style={{ width: "150px" }}>
              <EditProfileDropdown
                data={[
                  { label: "Newest First", value: "desc" },
                  { label: "Oldest First", value: "asc" },
                ]}
                onChange={(e) => setSortOption(e)}
                value={sortOption}
                placeholder="Sort by"
              />
            </div>
          )}
        </div>
        {isBasicLoading ? (
          <Cardloader />
        ) : (
          <>
            {screenSize.width < 768 && (
              <>
                {matchedUsers?.map((item, index) => (
                  <DashboardMobileCard item={item} index={index} key={index}>
                    <CardBottomButton icon={images.cardmatch} />
                  </DashboardMobileCard>
                ))}
              </>
            )}
            {screenSize.width > 768 && (
              <div className="recommended-matches-container">
                {matchedUsers?.map((item, index) => {
                  const interestSent = interests?.some(
                    (profile) => profile.username === item?.username
                  );

                  const photoreqSent = photoreq?.some(
                    (profile) => profile.username === item?.username
                  );
                  return (
                    <DashboardCard
                      item={item}
                      index={index}
                      key={index}
                      redirectedFrom="standardSearch"
                    >
                      {item?.matchOfTheDay && (
                        <CardBottomButton icon={images.cardButtonTick} />
                      )}
                      <CardBottomButton
                        icon={images.cardMessage}
                        onClick={() =>
                          navigate(
                            `/messages?username=${item?.username}&firstName=${item?.firstName}&lastName=${item?.lastName}&photo=${item?.profilePicture}`
                          )
                        }
                        title="Send Message"
                      />
                      <CardBottomButton
                        icon={images.cardPhotoReq}
                        onClick={() => handleSendPhotoReq(item?.username)}
                        title="Send Photo Request"
                        disabled={photoreqSent}
                      />
                      <CardBottomButton
                        icon={images.cardmatch}
                        onClick={() => handleSendInterest(item?.username)}
                        title="Send Interest"
                        disabled={interestSent}
                      />
                    </DashboardCard>
                  );
                })}
              </div>
            )}
          </>
        )}
        <Spacer height="36px" />
        <div className="flex justify-center items-center">
          <Pagination
            current={pageNo}
            total={basicCount}
            defaultPageSize={pageSize}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
        <Spacer height="24px" />
      </div>
    </>
  );
};

export default BasicSearch;
