import { put } from "redux-saga/effects";

import SearchApiCaller from "../../config/api/searchapicaller";

import { NewMembersAction } from "../actions";

export default class NewMembersMiddleware {
  static *getNewMembers({ payload, cb }) {
    try {
      const res = yield SearchApiCaller.Post(`/search?type=newmember`, payload);

      if (res?.status === 200) {
        yield put(
          NewMembersAction.getNewMembersSuccess({
            count: res.data?.total,
            data: res.data?.data,
          })
        );
      } else {
        yield put(NewMembersAction.getNewMembersFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(NewMembersAction.getNewMembersFailure());
    }
  }
}
