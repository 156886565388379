import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, Radio, Space, Modal } from "antd";

import { ProfileAction, AuthAction } from "../../store/actions";

import {
  GreyButton,
  PrimaryButton,
  WhiteButton,
} from "../../components/button";
import Spacer from "../../components/spacer/spacer";

import useScreenSize from "../../utils/usescreensize";

import images from "../../assets";

import "./styles.css";

const { TextArea } = Input;

const Deactivate = () => {
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(1);
  const [otherReason, setOtherReason] = useState("");

  const handleDelete = () => {
    const payload = { status: "delete" };

    dispatch(
      ProfileAction.changeProfileStatus(payload, () => {
        dispatch(AuthAction.Logout());
      })
    );
  };

  const handleDeactivate = () => {
    let reason = "";

    if (value === 1) {
      reason = "I met a partner on NobleMarriage";
    } else if (value === 2) {
      reason = "I met a partner elsewhere";
    } else if (value === 3) {
      reason = "I want to take a break";
    } else if (value === 4) {
      reason = "I didn’t like NobleMarriage";
    } else if (value === 5) {
      reason = otherReason;
    }

    const payload = { status: "deactivate", deactivateMessage: reason };

    dispatch(
      ProfileAction.changeProfileStatus(payload, () => {
        dispatch(AuthAction.Logout());
      })
    );
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="settings-container">
      <div className="settings-top-part-container">
        <h1 className="settings-title">Deactivate Account</h1>
        <Spacer height="4px" />
        <p className="settings-subtitle">
          Please let us know your reason for leaving. We are always looking to
          improve and welcome your honest feedback.
        </p>
      </div>
      <div className="deactivate-content-container">
        <div className="deactivate-back-btn">
          <GreyButton
            text="Back"
            icon={images.whiteButtonBack}
            iconalign="left"
            onClick={() => navigate("/settings")}
          />
        </div>
        <div className="deactivate-content-area">
          <div className="deactivate-form-area">
            <div className="deactivate-subtitle">
              <p>
                Please let us know your reason for leaving.
                <br /> We are always looking to improve and welcome your honest
                feedback.
              </p>
            </div>
            <div className="deactivate-form">
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <Radio value={1}>I met a partner on NobleMarriage</Radio>
                  <Radio value={2}>I met a partner elsewhere</Radio>
                  <Radio value={3}>I want to take a break</Radio>
                  <Radio value={4}>I didn’t like NobleMarriage</Radio>
                  <div className="show-textarea">
                    <Radio value={5}>Other</Radio>
                    {value === 5 ? (
                      <>
                        <TextArea
                          style={{
                            marginLeft: "24px",
                            marginTop: "10px",
                          }}
                          value={otherReason}
                          showCount
                          maxLength={1000}
                          rows={3}
                          placeholder="Please write your reason here ..."
                          onChange={(e) => setOtherReason(e.target.value)}
                        />
                      </>
                    ) : null}
                  </div>
                </Space>
              </Radio.Group>
            </div>
          </div>
          <Spacer height="36px" />
          <div className="deactivate-btn-area">
            <PrimaryButton
              style={{
                width: screenSize.width < 768 ? "" : "518px",
                padding: "0px 16px",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
                width: "80%",
              }}
              text="Deactivate Account"
              onClick={handleDeactivate}
            />
            <div className="want-to-delete-btn">
              <span onClick={() => setOpen(true)}>
                I want to delete my account
              </span>
            </div>
          </div>
        </div>
      </div>
      <Spacer height="36px" />

      <Modal
        centered={true}
        open={isOpen}
        onCancel={() => setOpen(false)}
        footer={false}
        closable={true}
      >
        <div className="gap-4 set-profile-photo-content">
          <div className="set-profile-photo-icon">
            <img style={{ maxWidth: "40px" }} src={images?.deleteIcon} alt="" />
          </div>
          <div className="set-profile-photo-heading">
            <h2>Delete your account?</h2>
            <p>
              Deleting your account is permanent and will erase all your data on
              our platform, including your profile and interactions. You’ll have
              30 days to reactivate. After that, your account and all associated
              data will be permanently deleted and cannot be restored. To
              reactivate, please reach out to us.
            </p>
          </div>
        </div>
        <Spacer height="16px" />
        <div className="flex w-full justify-end">
          <div className="flex gap-4 set-profile-photo-btn">
            <WhiteButton text="Cancel" onClick={() => setOpen(false)} />
            <PrimaryButton
              style={{ background: "#F31260" }}
              text="Delete"
              onClick={handleDelete}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Deactivate;
