import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Radio, Modal } from "antd";
import { loadStripe } from "@stripe/stripe-js";

import { ProfileAction, SubscriptionAction } from "../../store/actions";

import MembershipPlanLayout from "../../components/layout/membershipplan/membershipplanlayout";
import MembershipPlanCard from "../../components/membershipplan/membershipplancard";
import NeedHelpCard from "../../components/needhelp/needhelpcard";
import { PrimaryButton, WhiteButton } from "../../components/button";
import Loader from "../../components/loader/loader";

import PaymentButton from "./paymentbutton";

import images from "../../assets";
import "./styles.css";

const url =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_SUBSCRIPTION_BACKEND_URL_LOCAL
    : process.env.REACT_APP_ENV === "staging"
      ? process.env.REACT_APP_SUBSCRIPTION_BACKEND_URL_STAGING
      : process.env.REACT_APP_SUBSCRIPTION_BACKEND_URL_PROD;

const apiURL = `${url}/payment/pay`;
const BkashapiURL = `${url}/payment/bkashpay`;
const SSLCommerzURL = `${url}/payment/sslcommerzpay`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_PROD);

export default function Checkout(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.profile);
  const profileLoading = useSelector((state) => state.profile?.isLoading);
  const subscriptionData = useSelector((state) => state.subscription?.plan);
  const subscriptionLoading = useSelector(
    (state) => state.subscription?.isLoading
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState(false);
  const [value, setValue] = useState("");
  const [buttonPrice, setButtonPrice] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");

  const membershipData = JSON.parse(localStorage.getItem("membershipPlan"));

  const plan = membershipData?.title || "";
  const planId = membershipData?.id || "";

  const currentUrl = window.location.href;

  useEffect(() => {
    if (
      !searchParams.get("redirected") &&
      !localStorage.getItem("membershipPlan")
    ) {
      navigate("/membership-plan");
    } else {
      setButtonPrice(membershipData?.amount);
    }
  }, []);

  useEffect(() => {
    const status = searchParams.get("return");
    if (status === "success") {
      setTransactionId(searchParams.get("transactionId"));
      setIsModalOpen(true);
      setSearchParams("");
    } else if (status === "failed") {
      setIsFailModalOpen(true);
      setSearchParams("");
    }
  }, [searchParams, currentUrl]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleCheckout = async () => {
    if (!membershipData?.title) {
      navigate("/membership-plan");
    } else {
      try {
        localStorage.setItem(
          "checkout",
          JSON.stringify({
            plan: plan.toLowerCase(),
            amount: buttonPrice,
            method: "Credit / Debit Card",
          })
        );
        setLoading(true);
        const stripe = await stripePromise;

        const body = {
          plan: planId,
        };

        const response = await axios.post(apiURL, body);

        setLoading(false);

        const result = await stripe.redirectToCheckout({
          sessionId: response.data.Data?.id,
        });

        if (result.error) {
          console.error(result.error.message);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleBkashCheckout = async () => {
    if (!membershipData?.title) {
      navigate("/membership-plan");
    } else {
      try {
        localStorage.setItem(
          "checkout",
          JSON.stringify({
            plan: plan.toLowerCase(),
            amount: buttonPrice,
            method: "bKash",
          })
        );
        setLoading(true);

        const body = {
          plan: planId,
        };

        const response = await axios.post(BkashapiURL, body);

        if (response?.data && response?.data?.bkashURL) {
          const checkoutUrl = response.data.bkashURL;
          window.open(checkoutUrl);
        } else {
          console.error("Error: URL not found in the response data");
        }
        setLoading(false);
      } catch (error) {
        console.error("bkash error", error.message || error);
      }
    }
  };

  const handleSSLcommerzCheckout = async () => {
    if (!membershipData?.title) {
      navigate("/membership-plan");
    } else {
      try {
        localStorage.setItem(
          "checkout",
          JSON.stringify({
            plan: plan.toLowerCase(),
            amount: buttonPrice,
            method: "SSL Commerz",
          })
        );
        setLoading(true);
        const body = {
          plan: planId,
        };

        const response = await axios.post(SSLCommerzURL, body);

        if (response?.data && response?.data?.URL) {
          const checkoutUrl = response.data.URL;
          window.open(checkoutUrl, "_blank");
        } else {
          console.error("Error: URL not found in the response data");
        }
        setLoading(false);
      } catch (error) {
        console.error("ssl commerz error:", error.message || error);
      }
    }
  };

  const handleContinue = () => {
    setIsModalOpen(false);
    localStorage.removeItem("checkout");
    dispatch(
      ProfileAction.getProfile({}, () => {
        const payload = { transactionId: transactionId };
        dispatch(SubscriptionAction.getSubscriptionInfo(payload));
      })
    );
  };

  const planInfo = [
    { title: "Plan Name", value: membershipData?.title },
    {
      title: "Price",
      value: membershipData?.amount,
    },
    {
      title: "Duration",
      value: membershipData?.validity,
    },
    {
      title: "Contact Limit",
      value: membershipData?.contactLimit,
    },
    {
      title: "Message Limit",
      value: "Unlimited",
    },
    {
      title: "Expires",
      value: membershipData?.validity,
    },
    // {
    //   title: "Payment Method",
    //   value: subscriptionData?.name
    //     ? subscriptionData?.paymentGateway === "stripe"
    //       ? "Visa / Credit Card"
    //       : subscriptionData?.paymentGateway === "bkash"
    //       ? "bKash"
    //       : "SSL Commerz"
    //     : "Not used yet",
    // },
  ];

  return (
    <>
      <MembershipPlanLayout
        breadcrumbs={[
          { label: "Home", route: "/" },
          { label: "Membership Plans", route: "/membership-plan" },
          { label: "Order Information", route: "/order-information" },
          { label: "Checkout", route: "/checkout" },
        ]}
        titlename="Checkout"
      >
        <div className="checkout-container">
          <div className="checkout-content-left">
            {profileLoading || subscriptionLoading ? (
              <Loader />
            ) : (
              <div
                style={{
                  width: "100%",
                  background: `url(${images.membershipBg})`,
                  backgroundSize: "100% 100%",
                  marginBottom: "0px",
                }}
                className="membership-plan-area"
              >
                <div className="membership-plan-heading">
                  <h1>Membership Plan Details</h1>
                </div>
                <div className="membership-plan-content">
                  <div className="membership-plan-left">
                    {planInfo?.map((item, index) => (
                      <MembershipPlanCard
                        key={index}
                        titlename={item?.title}
                        values={item?.value}
                      />
                    ))}
                  </div>
                  <div className="membership-plan-logo">
                    <img src={images.membershipPlanIcon} alt="" />
                  </div>
                </div>
              </div>
            )}
            <NeedHelpCard
              style={{ width: "100%" }}
              whatsapplink="https://api.whatsapp.com/send/?phone=447501247364&text=I have a query about the payment gateways. Can you help me understand the available options and how to proceed with the payment?"
            />
          </div>
          <div className="checkout-content-right">
            <div className="checkout-content-right-heading">
              <h2>
                Choose a payment method
                <br /> to get the Premium Membership
              </h2>
            </div>
            <div className="checkout-payment-area">
              <div className="checkout-payment-btn">
                <Radio.Group
                  className="checkout-payment-redio"
                  onChange={onChange}
                  value={value}
                  size="small"
                >
                  {profileData?.country === "Bangladesh" && (
                    <PaymentButton
                      value="bkash"
                      titlename="bKash"
                      icon={images.bkashLogo}
                      alt="bKash"
                    />
                  )}
                  {profileData?.country === "Bangladesh" && (
                    <PaymentButton
                      value="pay-with"
                      titlename="Pay With"
                      icon={images.payWithLogo}
                      alt="Pay With"
                    />
                  )}
                  {profileData?.country !== "Bangladesh" && (
                    <PaymentButton
                      value="credit-debit"
                      titlename="Credit / Debit Card"
                      icon={images.creditDebitLogo}
                      alt="Credit / Debit Card"
                    />
                  )}
                </Radio.Group>
                <div className="checkout-btn">
                  <PrimaryButton
                    onClick={() => {
                      if (value === "credit-debit") {
                        handleCheckout();
                      }
                      if (value === "pay-with") {
                        handleSSLcommerzCheckout();
                      }
                      if (value === "bkash") {
                        handleBkashCheckout();
                      }
                    }}
                    style={{
                      fontSize: "16px",
                      width: "100%",
                      fontWeight: 500,
                    }}
                    text={`Pay ${buttonPrice ?? ""}`}
                    disabled={isLoading || !value}
                    loading={isLoading.toString()}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal
            centered={true}
            open={isModalOpen}
            onCancel={() => {
              setIsModalOpen(false);
              localStorage.removeItem("checkout");
            }}
            footer={false}
            closable={false}
            className="forgot-password-modal"
          >
            <div className="payment-sucess-modal">
              <div className="payment-modal-heading">
                <img width="150px" src={images?.paymentSucess} alt="" />
                <div className="payment-modal-top">
                  <h2 className="payment-modal-title">Payment Successful!</h2>
                  <p>
                    Congratulations! Your payment has been successfully
                    processed.
                  </p>
                </div>
                <div className="payment-modal-details">
                  <p>
                    <span>Transaction ID:</span> {transactionId}
                  </p>
                  <p>
                    <span>Amount:</span>{" "}
                    {JSON.parse(localStorage.getItem("checkout"))?.amount}
                  </p>
                  <p>
                    <span>Payment Method:</span>{" "}
                    {JSON.parse(localStorage.getItem("checkout"))?.method}
                  </p>
                </div>
              </div>
              <div className="payment-modal-btn">
                <WhiteButton
                  style={{
                    fontSize: "16px",
                    width: "120px",
                    height: "40px",
                    fontWeight: 500,
                    border: "2px solid #EAE6ED",
                  }}
                  text="Close"
                  onClick={() => {
                    setIsModalOpen(false);
                    localStorage.removeItem("checkout");
                  }}
                />
                <PrimaryButton
                  style={{
                    fontSize: "16px",
                    width: "157px",
                    height: "40px",
                    fontWeight: 500,
                  }}
                  text="Continue"
                  onClick={() => handleContinue()}
                />
              </div>
            </div>
          </Modal>

          <Modal
            centered={true}
            open={isFailModalOpen}
            onCancel={() => setIsFailModalOpen(true)}
            footer={false}
            closable={false}
            className="forgot-password-modal"
          >
            <div className="payment-sucess-modal">
              <div className="payment-modal-heading">
                <img width="150px" src={images?.paymentFailBtn} alt="" />
                <div className="payment-modal-top">
                  <h2
                    style={{ color: "#AD0D44" }}
                    className="payment-modal-title"
                  >
                    Payment Failed!
                  </h2>
                  <p>
                    Oops! It looks like there was an issue processing your
                    payment.
                  </p>
                </div>
                <div className="payment-modal-details">
                  {/* <p>
                      <span>Reason:</span> Insufficient Fund.
                    </p> */}
                  <p style={{ color: "#222" }} className="pt-2">
                    If the issue persists, please contact our support team for
                    assistance.
                  </p>
                </div>
              </div>
              <div className="payment-modal-btn">
                <WhiteButton
                  style={{
                    fontSize: "16px",
                    width: "120px",
                    height: "40px",
                    fontWeight: 500,
                    border: "2px solid #EAE6ED",
                  }}
                  text="Close"
                  onClick={() => setIsFailModalOpen(false)}
                />
                <PrimaryButton
                  style={{
                    fontSize: "16px",
                    width: "157px",
                    height: "40px",
                    fontWeight: 500,
                  }}
                  text="Try Again"
                  onClick={() => setIsFailModalOpen(false)}
                />
              </div>
            </div>
          </Modal>
        </div>
      </MembershipPlanLayout>
    </>
  );
}
