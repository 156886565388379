import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Drawer, Space } from "antd";

import useScreenSize from "../../../utils/usescreensize";

import ProfileDropdownContainer from "./navdropdown/profiledropdowncontainer";
import NotificationDropdownContainer from "./notifidropdown/notificationdropdowncontainer";
import DefaultItems from "./sidebar/defaultitems";

import UpgradeButton from "./upgrade/upgradebutton";
import SearchField from "./searchfield";

import images from "../../../assets";

const NavHeader = (props) => {
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const location = useLocation();

  const subscriptionData = useSelector((state) => state.subscription?.plan);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    onClose();
  }, [location]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="header">
      {screenSize.width < 1023 && (
        <>
          <div
            onClick={showDrawer}
            className="p-2 rounded-md border border-n20 bg-n10 cursor-pointer"
          >
            <img src={images.hamburger} alt="hamburger" />
          </div>
          <Drawer
            closable={false}
            placement="left"
            width="100%"
            onClose={onClose}
            open={open}
            extra={<Space></Space>}
          >
            <DefaultItems onClick={onClose} />
          </Drawer>
        </>
      )}
      <SearchField promotionHeader={props?.promotionHeader} />
      {screenSize.width > 1024 && (
        <div className="header-notification">
          <NotificationDropdownContainer
            promotionHeader={props?.promotionHeader}
          />
        </div>
      )}
      {screenSize.width > 1024 && (
        <div className="upgrade-profile-area">
          {!subscriptionData?.name ? (
            <UpgradeButton
              onClick={() => navigate("/membership-plan")}
              className="upgrade"
              title="Upgrade"
              disscunt="20% OFF"
            />
          ) : subscriptionData?.name !== "platinum" ? (
            <UpgradeButton
              onClick={() => navigate("/membership-plan")}
              className="premium"
              title="Premium"
            />
          ) : null}
        </div>
      )}
      <ProfileDropdownContainer promotionHeader={props?.promotionHeader} />
    </div>
  );
};

export default NavHeader;
