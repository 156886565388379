import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { QRCode } from "antd";
import { useSearchParams } from "react-router-dom";
import generatePDF from "react-to-pdf";

import { ProfileAction, SubscriptionAction } from "../../store/actions";

import calculateage from "../../utils/calculateage";
import getMaritalStatusLabel from "../../utils/getmaritalstatuslabel";
import convertedCentoFeet from "../../utils/convertdenttofeet";

import ImproveMatchInfo from "../../components/profilecard/improvematchinfo";
import PersonalityHobColumn from "../../components/profilecard/personalityhobcolumn";
import BasicInfoColumn from "../../components/profilecard/basicinfocolumn";
import CardBottomButton from "../../components/dashboardcard/cardbottombutton";
import Spacer from "../../components/spacer/spacer";

import AboutMeContact from "../viewprofile/aboutmecontact";
import GetresidencyStatusLabel from "../../utils/getresidencystatuslavel";

import images from "../../assets/index";

import "./styles.css";

const renderValues = (array) => {
  const value = array?.map((item, index) => {
    if (index === 0) {
      return item;
    } else {
      return `, ${item}`;
    }
  });
  return value;
};

const ExportPDF = () => {
  const targetRef = useRef();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth?.user);
  const profile = useSelector((state) => state.profile?.profile);
  const subscriptionData = useSelector((state) => state?.subscription?.plan);

  const [searchParams, setSearchParams] = useSearchParams();

  const profileData = JSON.parse(localStorage.getItem("downloadData"));

  const url = window.location.href;
  const baseUrl = new URL(url).origin;

  const text = `${baseUrl}/view-profile/${profileData?.username}`;

  useEffect(() => {
    dispatch(
      ProfileAction.getProfile({}, (res) => {
        if (res?.activePlan) {
          dispatch(
            SubscriptionAction.getSubscriptionInfo({
              transactionId: res?.activePlan?.orderId,
            })
          );
        }
      })
    );
  }, [user]);

  useEffect(() => {
    dispatch(
      ProfileAction.getProfile({}, (res) => {
        const functionality = searchParams.get("function");

        if (res?.activePlan) {
          dispatch(
            SubscriptionAction.getSubscriptionInfo(
              {
                transactionId: res?.activePlan?.transactionId,
              },
              () => {
                handleFunctionality(functionality);
              }
            )
          );
        } else {
          handleFunctionality(functionality);
        }
      })
    );

    const handleFunctionality = (functionality) => {
      if (functionality === "download") {
        generatePDF(targetRef, { filename: "profile.pdf" });
        setTimeout(() => closeWindow(), 6000);
      } else if (functionality === "print") {
        window.print();
        closeWindow();
      }
    };

    const closeWindow = () => {
      window.close();
    };
  }, [searchParams, user]);

  return (
    <div ref={targetRef} className="px-2">
      <div className="profile-layout">
        <div className="profile-layout-inner">
          <div
            style={{
              background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
              backgroundSize: "100% 100%",
            }}
            className="profile-head p-3 h-[290px]"
          >
            <div className="profile-head-inner">
              <div className="profile-info">
                <div className="profile-info-thumb">
                  <img
                    src={profileData?.profilePicture}
                    className="object-cover"
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        profileData?.gender === "male"
                          ? images.defaultMaleIcon
                          : images.defaultFemaleIcon;
                    }}
                  />

                  <p className="profile-id">
                    User ID: {profileData?.username}&nbsp;
                  </p>
                </div>
                <div className="profile-info-content">
                  <div className="profile-info-main-content">
                    <h2>
                      {profileData?.firstName}&nbsp;{profileData?.lastName}
                    </h2>
                    {profileData?.dateOfBirth && (
                      <p>
                        <img src={images.calenderIcon} alt="Calender" />
                        &nbsp;
                        {`${calculateage(profileData?.dateOfBirth)} years`}
                      </p>
                    )}
                    {profileData?.height && (
                      <p>
                        <img src={images.heightIcon} alt="Height" />
                        &nbsp;
                        {`${convertedCentoFeet(profileData?.height)} (${
                          profileData?.height
                        } cm)`}
                      </p>
                    )}
                    {profileData?.country && profileData?.stateProvince && (
                      <p>
                        <img src={images.locationIcon} alt="Location" />
                        &nbsp;{profileData?.stateProvince},{" "}
                        {profileData?.country}
                      </p>
                    )}
                    {profileData?.maritalStatus && (
                      <p style={{ textTransform: "capitalize" }}>
                        <img src={images.neverMarride} alt="Marrital Status" />
                        &nbsp;
                        {getMaritalStatusLabel(profileData?.maritalStatus)}
                      </p>
                    )}
                    {profileData?.profession && (
                      <p>
                        <img src={images.computerProfession} alt="Calender" />
                        &nbsp;{profileData?.profession}
                      </p>
                    )}
                  </div>
                </div>
                <div className="bg-white rounded-2xl">
                  <QRCode value={text || "-"} />
                </div>
              </div>
            </div>
          </div>

          <div className="profile-body">
            <div className="profile-body-inner mx-3">
              <div className="profile-body-content p-4">
                <div className="profile-body-left">
                  {/* about me start */}
                  <div className="about-me-card">
                    <div className="about-me-main">
                      <div className="about-me">
                        <h2>About {profileData?.firstName}</h2>
                        <p>
                          Profile created by {profileData?.profileCreatedBy}
                        </p>
                      </div>
                      <div className="edit-profile"></div>
                    </div>
                    <div className="about-me-number-email">
                      <AboutMeContact>
                        <CardBottomButton icon={images.phoneCallIcon} />
                        <div className="otherpro-phone-call-icon">
                          <p>
                            {profileData?.phone}
                            {profile?.username !== profileData?.username &&
                              profileData?.gender === "female" &&
                              profileData?.isContactHidden && (
                                <>
                                  <br />
                                  <span>
                                    The user has chosen to keep her number
                                    hidden, but you can still use the chat and
                                    send her an email to reach out.
                                  </span>
                                </>
                              )}
                          </p>
                        </div>
                      </AboutMeContact>
                      <hr />
                      <Spacer height="15px" />

                      <AboutMeContact>
                        <CardBottomButton icon={images.emailIcon} />
                        <p>{profileData?.email}</p>
                      </AboutMeContact>
                    </div>
                    {profileData?.profileDescription && (
                      <div
                        className="about-me-desc-area"
                        title={profileData?.profileDescription}
                      >
                        {profileData?.profileDescription && (
                          <p
                            className="about-me-content"
                            title={profileData?.profileDescription}
                          >
                            {profileData?.profileDescription}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  {/* about me end */}
                  {/* basic info start */}
                  <div className="basic-info-card">
                    <div className="basic-info-head">
                      <img src={images.basicInfoIcon} alt="Basic Info" />{" "}
                      <span>Basic Info</span>
                    </div>
                    <div className="basic-info-content">
                      {profileData?.gender && (
                        <BasicInfoColumn
                          style={{ textTransform: "capitalize" }}
                          titlename="Gender"
                          values={profileData?.gender}
                        />
                      )}
                      {profileData?.dateOfBirth && (
                        <BasicInfoColumn
                          titlename="Age"
                          values={`${calculateage(
                            profileData?.dateOfBirth
                          )} Years`}
                        />
                      )}
                      {profileData?.stateProvince && profileData?.country && (
                        <BasicInfoColumn
                          titlename="Lives In"
                          values={`${profileData?.stateProvince}, ${profileData?.country}`}
                        />
                      )}
                      {profileData?.homeType && (
                        <BasicInfoColumn
                          style={{ textTransform: "capitalize" }}
                          titlename="Home Type"
                          values={profileData?.homeType}
                        />
                      )}
                      {profileData?.relocationPlan && (
                        <BasicInfoColumn
                          titlename="Relocation Plan"
                          values={profileData?.relocationPlan}
                        />
                      )}
                      {profileData?.ethnicOrigin && (
                        <BasicInfoColumn
                          titlename="Ethnic Origin"
                          values={profileData?.ethnicOrigin}
                        />
                      )}
                      {profileData?.residencyStatus && (
                        <BasicInfoColumn
                          style={{ textTransform: "capitalize" }}
                          titlename="Residency Status"
                          values={GetresidencyStatusLabel(
                            profileData?.residencyStatus
                          )}
                        />
                      )}
                      {profileData?.maritalStatus && (
                        <BasicInfoColumn
                          style={{ textTransform: "capitalize" }}
                          titlename="Marital Status"
                          values={profileData?.maritalStatus}
                        />
                      )}
                      {profileData?.spokenLanguage?.length > 0 && (
                        <BasicInfoColumn
                          titlename="Spoken Language"
                          values={profileData?.spokenLanguage?.map(
                            (item, index) => {
                              if (index === 0) {
                                return item;
                              } else {
                                return `, ${item}`;
                              }
                            }
                          )}
                        />
                      )}
                    </div>
                  </div>
                  {/* basic info end */}
                  {/* physcial lifestyle start */}
                  <div className="physical-appearance basic-info-card">
                    <div className="basic-info-head">
                      <img
                        src={images.physicalAppreIcon}
                        alt="Physical Appearance & Lifestyle"
                      />{" "}
                      <span>Physical Appearance & Lifestyle</span>
                    </div>
                    <div className="basic-info-content">
                      {profileData?.height && (
                        <BasicInfoColumn
                          titlename="Height"
                          values={`${convertedCentoFeet(
                            profileData?.height
                          )} (${profileData?.height} cm)`}
                        />
                      )}
                      {profileData?.hairColor && (
                        <BasicInfoColumn
                          titlename="Hair Color"
                          values={profileData?.hairColor}
                        />
                      )}
                      {profileData?.hairType && (
                        <BasicInfoColumn
                          titlename="Hair Type"
                          values={profileData?.hairType}
                        />
                      )}
                      {profileData?.hairLength && (
                        <BasicInfoColumn
                          titlename="Hair Length"
                          values={profileData?.hairLength}
                        />
                      )}
                      {profileData?.complexion && (
                        <BasicInfoColumn
                          titlename="Complexion"
                          values={profileData?.complexion}
                        />
                      )}
                      {profileData?.bloodGroup && (
                        <BasicInfoColumn
                          titlename="Blood Group"
                          values={profileData?.bloodGroup}
                        />
                      )}
                      {profileData?.foodHabit?.length > 0 && (
                        <BasicInfoColumn
                          titlename="Food Habit"
                          values={profileData?.foodHabit?.map((item, index) => {
                            if (index === 0) {
                              return item;
                            } else {
                              return `, ${item}`;
                            }
                          })}
                        />
                      )}
                      {profileData?.healthCondition && (
                        <BasicInfoColumn
                          titlename="Health Condition"
                          values={profileData?.healthCondition}
                        />
                      )}
                      {profileData?.bodyType && (
                        <BasicInfoColumn
                          titlename="Body Type"
                          values={profileData?.bodyType}
                        />
                      )}
                      {profileData?.physicalStatus && (
                        <BasicInfoColumn
                          titlename="Physical Health Status"
                          values={profileData?.physicalStatus}
                        />
                      )}
                      {profileData?.smoking && (
                        <BasicInfoColumn
                          titlename="Smoking"
                          values={profileData?.smoking}
                        />
                      )}
                      {profileData?.beard && (
                        <BasicInfoColumn
                          titlename="Beard"
                          values={profileData?.beard}
                        />
                      )}
                    </div>
                  </div>
                  {/* physcial lifestyle end */}
                  {/* family deails start */}
                  <div className="family-details basic-info-card">
                    <div className="basic-info-head">
                      <img src={images.familyIcon} alt="Family Details" />{" "}
                      <span>Family Details</span>
                    </div>
                    {profile?.username === profileData?.username ? (
                      <div className="basic-info-content">
                        {profileData?.fatherStatus && (
                          <BasicInfoColumn
                            titlename="Father’s Status"
                            values={profileData?.fatherStatus}
                          />
                        )}
                        {profileData?.motherStatus && (
                          <BasicInfoColumn
                            titlename="Mother’s Status"
                            values={profileData?.motherStatus}
                          />
                        )}
                        {profileData?.financialStatus && (
                          <BasicInfoColumn
                            titlename="Financial Status"
                            values={profileData?.financialStatus}
                          />
                        )}
                        {profileData?.familyCountry &&
                          profileData?.familyStateProvince && (
                            <BasicInfoColumn
                              titlename="Family Location"
                              values={`${profileData?.familyStateProvince}, ${profileData?.familyCountry}`}
                            />
                          )}
                        {profileData?.fatherEthnicOrigin && (
                          <BasicInfoColumn
                            titlename="Father’s Ethnic Origin"
                            values={profileData?.fatherEthnicOrigin}
                          />
                        )}
                        {profileData?.motherEthnicOrigin && (
                          <BasicInfoColumn
                            titlename="Mother’s Ethnic Origin"
                            values={profileData?.motherEthnicOrigin}
                          />
                        )}
                        {profileData?.maleSiblingMarried > 0 && (
                          <BasicInfoColumn
                            titlename="Male Sibling (Married)"
                            values={profileData?.maleSiblingMarried}
                          />
                        )}
                        {profileData?.maleSiblingUnmarried > 0 && (
                          <BasicInfoColumn
                            titlename="Male Sibling (Unmarried)"
                            values={profileData?.maleSiblingUnmarried}
                          />
                        )}
                        {profileData?.femaleSiblingMarried > 0 && (
                          <BasicInfoColumn
                            titlename="Female Sibling (Married)"
                            values={profileData?.femaleSiblingMarried}
                          />
                        )}
                        {profileData?.femaleSiblingUnmarried > 0 && (
                          <BasicInfoColumn
                            titlename="Female Sibling (Unmarried)"
                            values={profileData?.femaleSiblingUnmarried}
                          />
                        )}
                        {profileData?.maleChildren > 0 && (
                          <BasicInfoColumn
                            titlename="Male Children"
                            values={profileData?.maleChildren}
                          />
                        )}
                        {profileData?.femaleChildren > 0 && (
                          <BasicInfoColumn
                            titlename="Female Children"
                            values={profileData?.femaleChildren}
                          />
                        )}
                        {profileData?.familyType && (
                          <BasicInfoColumn
                            titlename="Family Type"
                            values={profileData?.familyType}
                          />
                        )}
                        {profileData?.familyValues && (
                          <BasicInfoColumn
                            titlename="Family Values"
                            values={profileData?.familyValues}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        {subscriptionData?.name ? (
                          <div className="basic-info-content">
                            {profileData?.fatherStatus && (
                              <BasicInfoColumn
                                titlename="Father’s Status"
                                values={profileData?.fatherStatus}
                              />
                            )}
                            {profileData?.motherStatus && (
                              <BasicInfoColumn
                                titlename="Mother’s Status"
                                values={profileData?.motherStatus}
                              />
                            )}
                            {profileData?.financialStatus && (
                              <BasicInfoColumn
                                titlename="Financial Status"
                                values={profileData?.financialStatus}
                              />
                            )}
                            {profileData?.familyCountry &&
                              profileData?.familyStateProvince && (
                                <BasicInfoColumn
                                  titlename="Family Location"
                                  values={`${profileData?.familyStateProvince}, ${profileData?.familyCountry}`}
                                />
                              )}
                            {profileData?.fatherEthnicOrigin && (
                              <BasicInfoColumn
                                titlename="Father’s Ethnic Origin"
                                values={profileData?.fatherEthnicOrigin}
                              />
                            )}
                            {profileData?.motherEthnicOrigin && (
                              <BasicInfoColumn
                                titlename="Mother’s Ethnic Origin"
                                values={profileData?.motherEthnicOrigin}
                              />
                            )}
                            {profileData?.maleSiblingMarried > 0 && (
                              <BasicInfoColumn
                                titlename="Male Sibling (Married)"
                                values={profileData?.maleSiblingMarried}
                              />
                            )}
                            {profileData?.maleSiblingUnmarried > 0 && (
                              <BasicInfoColumn
                                titlename="Male Sibling (Unmarried)"
                                values={profileData?.maleSiblingUnmarried}
                              />
                            )}
                            {profileData?.femaleSiblingMarried > 0 && (
                              <BasicInfoColumn
                                titlename="Female Sibling (Married)"
                                values={profileData?.femaleSiblingMarried}
                              />
                            )}
                            {profileData?.femaleSiblingUnmarried > 0 && (
                              <BasicInfoColumn
                                titlename="Female Sibling (Unmarried)"
                                values={profileData?.femaleSiblingUnmarried}
                              />
                            )}
                            {profileData?.maleChildren > 0 && (
                              <BasicInfoColumn
                                titlename="Male Children"
                                values={profileData?.maleChildren}
                              />
                            )}
                            {profileData?.femaleChildren > 0 && (
                              <BasicInfoColumn
                                titlename="Female Children"
                                values={profileData?.femaleChildren}
                              />
                            )}
                            {profileData?.familyType && (
                              <BasicInfoColumn
                                titlename="Family Type"
                                values={profileData?.familyType}
                              />
                            )}
                            {profileData?.familyValues && (
                              <BasicInfoColumn
                                titlename="Family Values"
                                values={profileData?.familyValues}
                              />
                            )}
                          </div>
                        ) : (
                          <div className="basic-info-content">
                            <div className="basic-info-column">
                              <div className="basic-info-title">
                                <p>Father’s Status</p>
                                <p>Mother’s Status</p>
                                <p>Financial Status</p>
                                <p>Family Location</p>
                                <p>Father’s Ethnic Origin</p>
                                <p>Mother’s Ethnic Origin</p>
                                <p>Family Type</p>
                                <p>Family Values</p>
                                <p>Male Sibling</p>
                                <p>Female Sibling</p>
                              </div>
                              <div className="basic-info-value-hidden">
                                <div className="blure-bg">
                                  <p>: Unknown</p>
                                  <p>: Unknown xx</p>
                                  <p>: Unknown xx</p>
                                  <p>: Unknownxx</p>
                                  <p>: Unknown</p>
                                  <p>: Unknown xxx xx</p>
                                  <p>: Unknown</p>
                                  <p>: Unknown x</p>
                                  <p>: xx</p>
                                  <p>: xx</p>
                                </div>
                                <div className="upgrade-family-info">
                                  <div className="upgrade-family-content">
                                    <img src={images.upgradeLock} alt="" />
                                    <h2>UPGRADE</h2>
                                    <p>to see Family details</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {/* family deails end */}
                  {/* religious status start */}
                  <div className="religious-status basic-info-card">
                    <div className="basic-info-head">
                      <img src={images.religiousIcon} alt="Religious Status" />{" "}
                      <span>Religious Status</span>
                    </div>
                    <div className="basic-info-content">
                      {profileData?.religiousHistory && (
                        <BasicInfoColumn
                          titlename="Religious History"
                          values={profileData?.religiousHistory}
                        />
                      )}
                      {profileData?.religiousSect && (
                        <BasicInfoColumn
                          titlename="Sect"
                          values={profileData?.religiousSect}
                        />
                      )}
                      {profileData?.polygamy && (
                        <BasicInfoColumn
                          titlename="Polygamy"
                          values={profileData?.polygamy}
                        />
                      )}
                      {profileData?.dailyPrayer && (
                        <BasicInfoColumn
                          titlename="Pray"
                          values={profileData?.dailyPrayer}
                        />
                      )}
                      {profileData?.readQuran && (
                        <BasicInfoColumn
                          titlename="How often do you read Quran?"
                          values={profileData?.readQuran}
                        />
                      )}
                      {profileData?.readIslamicBook && (
                        <BasicInfoColumn
                          titlename="Do you read Islamic books, lectures?"
                          values={profileData?.readIslamicBook}
                        />
                      )}
                      {profileData?.religiousCommitment && (
                        <BasicInfoColumn
                          titlename="Relgious Commitment Level"
                          values={profileData?.religiousCommitment}
                        />
                      )}
                    </div>
                  </div>
                  {/* religious status end */}
                  {/* education career start */}
                  <div className="education-career basic-info-card">
                    <div className="basic-info-head">
                      <img src={images.educationIcon} alt="Religious Status" />{" "}
                      <span>Education & Career</span>
                    </div>
                    <div className="basic-info-content">
                      {profileData?.education && (
                        <BasicInfoColumn
                          titlename="Education"
                          values={profileData?.education}
                        />
                      )}
                      {profileData?.instituteName && (
                        <BasicInfoColumn
                          titlename="Institute Name"
                          values={profileData?.instituteName}
                          icon={images.upgradeLock}
                        />
                      )}
                      {profileData?.profession && (
                        <BasicInfoColumn
                          titlename="Profession"
                          values={profileData?.profession}
                        />
                      )}
                      {profileData?.occupationStatus && (
                        <BasicInfoColumn
                          titlename="Occupation Status"
                          values={profileData?.occupationStatus}
                        />
                      )}
                      {profileData?.annualIncomeMin && (
                        <BasicInfoColumn
                          titlename="Annual Income"
                          values={`${
                            profileData?.incomeCurrency
                          } ${profileData?.annualIncomeMin?.toLocaleString()} to
            ${profileData?.annualIncomeMax?.toLocaleString()}`}
                          icon={images.upgradeLock}
                        />
                      )}
                    </div>
                  </div>
                  {/* education career end */}
                  {/* ideal match start */}
                  <div className="myideal-match basic-info-card">
                    <div className="basic-info-head">
                      <img src={images.idealMatchIcon} alt="Religious Status" />{" "}
                      <span>{profileData?.firstName}'s Ideal Match</span>
                    </div>
                    <div className="ideal-match-desc-area">
                      {profileData?.matchPreference?.idealMatchDescription && (
                        <p className="ideal-match-content">
                          {profileData?.matchPreference?.idealMatchDescription}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* ideal match end */}
                </div>
                <div className="profile-body-right">
                  {/* gallery start */}
                  <div className="my-photo-card">
                    <div
                      style={{ justifyContent: "center" }}
                      className="my-photo-text"
                    >
                      <span>{profileData?.firstName}'s Photos</span>
                    </div>
                    <div className="my-photo-gallery">
                      <div className="main-photo-gallery">
                        {profileData?.imageGallery?.map((item, index) => (
                          <div key={index} className="galley-images">
                            <img
                              src={item.url}
                              alt="Gallery Images"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  profileData?.gender === "male"
                                    ? images.defaultMaleIcon
                                    : images.defaultFemaleIcon;
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* gallery end */}
                  {/* personality hobbies start */}
                  <div className="personality-hobbies hobbies-info-card">
                    <div className="basic-info-head">
                      <div className="h-[25px] w-[35px] border-[2px]  border-basePrimary rounded-md bg-[#eae6ed] flex justify-center items-center">
                        <img
                          src={images.personalityHobbiesIcon}
                          alt=""
                          className="h-[12px]"
                        />{" "}
                      </div>
                      <span>Personality & Hobbies</span>
                    </div>
                    <div className="hobbies-info-content">
                      {profileData?.foodPreference?.length > 0 && (
                        <>
                          <div className="hobbies-info-title">
                            What sort of food do you like?
                          </div>
                          <PersonalityHobColumn>
                            {profileData?.foodPreference?.map((item, index) => {
                              if (index === 0) {
                                return <p key={index}>{item}</p>;
                              } else {
                                return <p key={index}>{item}</p>;
                              }
                            })}
                          </PersonalityHobColumn>
                        </>
                      )}
                      {profileData?.musicPreference?.length > 0 && (
                        <>
                          <div
                            style={{ paddingTop: "10px" }}
                            className="hobbies-info-title"
                          >
                            What sort of music do you like?
                          </div>

                          <PersonalityHobColumn>
                            {profileData?.musicPreference?.map(
                              (item, index, key) => {
                                if (index === 0) {
                                  return <p key={index}>{item}</p>;
                                } else {
                                  return <p key={index}>{item}</p>;
                                }
                              }
                            )}
                          </PersonalityHobColumn>
                        </>
                      )}
                      {profileData?.personalityTraits?.length > 0 && (
                        <>
                          <div
                            style={{ paddingTop: "10px" }}
                            className="hobbies-info-title"
                          >
                            What are your main personality traits?
                          </div>

                          <PersonalityHobColumn>
                            {profileData?.personalityTraits?.map(
                              (item, index, key) => {
                                if (index === 0) {
                                  return <p key={index}>{item}</p>;
                                } else {
                                  return <p key={index}>{item}</p>;
                                }
                              }
                            )}
                          </PersonalityHobColumn>
                        </>
                      )}
                      {profileData?.visitedCountries?.length > 0 && (
                        <>
                          <div
                            style={{ paddingTop: "10px" }}
                            className="hobbies-info-title"
                          >
                            What are the countries you visited?
                          </div>

                          <PersonalityHobColumn>
                            {profileData?.visitedCountries?.map(
                              (item, index, key) => {
                                if (index === 0) {
                                  return <p key={index}>{item}</p>;
                                } else {
                                  return <p key={index}>{item}</p>;
                                }
                              }
                            )}
                          </PersonalityHobColumn>
                        </>
                      )}
                      {profileData?.weekendPreference?.length > 0 && (
                        <>
                          <div
                            style={{ paddingTop: "10px" }}
                            className="hobbies-info-title"
                          >
                            How would you like to spend weekend with your
                            family?
                          </div>

                          <PersonalityHobColumn>
                            {profileData?.weekendPreference?.map(
                              (item, index, key) => {
                                if (index === 0) {
                                  return <p key={index}>{item}</p>;
                                } else {
                                  return <p key={index}>{item}</p>;
                                }
                              }
                            )}
                          </PersonalityHobColumn>
                        </>
                      )}
                      {profileData?.bookPreference?.length > 0 && (
                        <>
                          <div
                            style={{ paddingTop: "10px" }}
                            className="hobbies-info-title"
                          >
                            What are the types of books you like?
                          </div>

                          <PersonalityHobColumn>
                            {profileData?.bookPreference?.map(
                              (item, index, key) => {
                                if (index === 0) {
                                  return <p key={index}>{item}</p>;
                                } else {
                                  return <p key={index}>{item}</p>;
                                }
                              }
                            )}
                          </PersonalityHobColumn>
                        </>
                      )}
                      {profileData?.hobbies?.length > 0 && (
                        <>
                          <div
                            style={{ paddingTop: "10px" }}
                            className="hobbies-info-title"
                          >
                            What are your hobbies?
                          </div>

                          <PersonalityHobColumn>
                            {profileData?.hobbies?.map((item, index, key) => {
                              if (index === 0) {
                                return <p key={index}>{item}</p>;
                              } else {
                                return <p key={index}>{item}</p>;
                              }
                            })}
                          </PersonalityHobColumn>
                        </>
                      )}
                    </div>
                  </div>
                  {/* personality hobbies end */}
                  {/* improve match start */}
                  <div className="improve-match-card-area">
                    <div
                      style={{
                        background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
                        backgroundSize: "100% 100%",
                        height: "140px",
                      }}
                      className="improve-match-card p-3 !h-[120px]"
                    >
                      <div className="other-improve-match-head">
                        <img
                          src={profile?.profilePicture}
                          className="h-[80px] w-[80px] object-cover aspect-square rounded-lg"
                          alt=""
                        />
                        <div className="improve-match-title text-center">
                          My Preferences
                        </div>
                      </div>

                      {profile?.username !== profileData?.username && (
                        <div className="other-improve-match-head">
                          <img
                            src={profileData?.profilePicture}
                            className="h-[80px] w-[80px] object-cover aspect-square rounded-lg"
                            alt=""
                          />
                          <div className="improve-match-title text-center">
                            {profileData?.gender === "female"
                              ? "Her Match"
                              : "His Match"}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="improve-match-info">
                      <div className="improve-match-info-inner">
                        {calculateage(profileData?.dateOfBirth) >=
                          profile?.matchPreference?.ageMin &&
                        calculateage(profileData?.dateOfBirth) <=
                          profile?.matchPreference?.ageMax ? (
                          <ImproveMatchInfo
                            title="Age"
                            subtext={`${profile?.matchPreference?.ageMin} to ${profile?.matchPreference?.ageMax}`}
                          />
                        ) : null}
                        {profileData?.height >=
                          profile?.matchPreference?.heightMin &&
                        profileData?.height <=
                          profile?.matchPreference?.heightMax ? (
                          <ImproveMatchInfo
                            title="Height"
                            subtext={`${convertedCentoFeet(
                              profile?.matchPreference?.heightMin
                            )} (${
                              profile?.matchPreference?.heightMin
                            }cm) to ${convertedCentoFeet(
                              profile?.matchPreference?.heightMax
                            )} (${profile?.matchPreference?.heightMax}cm)`}
                          />
                        ) : null}
                        {profile?.matchPreference?.maritalStatus &&
                          profile?.matchPreference?.maritalStatus?.length > 0 &&
                          profileData?.maritalStatus &&
                          profile?.matchPreference?.maritalStatus?.includes(
                            profileData?.maritalStatus
                          ) && (
                            <ImproveMatchInfo
                              title="Marital Status"
                              subtext={profile?.matchPreference?.maritalStatus?.map(
                                (item, index) => {
                                  if (index === 0) {
                                    return getMaritalStatusLabel(item);
                                  } else {
                                    return `, ${getMaritalStatusLabel(item)}`;
                                  }
                                }
                              )}
                            />
                          )}
                        {profile?.matchPreference?.religiousCommitment &&
                          profile?.matchPreference?.religiousCommitment
                            ?.length > 0 &&
                          profileData?.religiousCommitment &&
                          profile?.matchPreference?.religiousCommitment?.includes(
                            profileData?.religiousCommitment
                          ) && (
                            <ImproveMatchInfo
                              title="Religious Commitment Level"
                              subtext={renderValues(
                                profile?.matchPreference?.religiousCommitment
                              )}
                            />
                          )}
                        {profile?.matchPreference?.religiousHistory &&
                          profile?.matchPreference?.religiousHistory?.length >
                            0 &&
                          profileData?.religiousHistory &&
                          profile?.matchPreference?.religiousHistory?.includes(
                            profileData?.religiousHistory
                          ) && (
                            <ImproveMatchInfo
                              title="Religion / Community"
                              subtext={renderValues(
                                profile?.matchPreference?.religiousHistory
                              )}
                            />
                          )}
                        {profile?.matchPreference?.spokenLanguage &&
                          profile?.matchPreference?.spokenLanguage?.length >
                            0 &&
                          profileData?.spokenLanguage &&
                          profile?.matchPreference?.spokenLanguage?.includes(
                            profileData?.spokenLanguage
                          ) && (
                            <ImproveMatchInfo
                              title="Mother Tongue"
                              subtext={renderValues(
                                profile?.matchPreference?.spokenLanguage
                              )}
                            />
                          )}
                        {profile?.matchPreference?.country &&
                          profile?.matchPreference?.country?.length > 0 &&
                          profileData?.country &&
                          profile?.matchPreference?.country?.includes(
                            profileData?.country
                          ) && (
                            <ImproveMatchInfo
                              title="Country Living in"
                              subtext={renderValues(
                                profile?.matchPreference?.country
                              )}
                            />
                          )}
                        {profileData?.incomeCurrency ===
                          profile?.matchPreference?.incomeCurrency &&
                        profileData?.annualIncomeMin >=
                          profile?.matchPreference?.annualIncomeMin &&
                        profileData?.annualIncomeMax <=
                          profile?.matchPreference?.annualIncomeMax ? (
                          <ImproveMatchInfo
                            title="Annual Income"
                            subtext={`${
                              profile?.matchPreference?.incomeCurrency
                            } ${profile?.matchPreference?.annualIncomeMin?.toLocaleString()} to ${profile?.matchPreference?.annualIncomeMax?.toLocaleString()}`}
                          />
                        ) : null}
                        {profile?.matchPreference?.bloodGroup &&
                          profile?.matchPreference?.bloodGroup?.length > 0 &&
                          profileData?.bloodGroup &&
                          profile?.matchPreference?.bloodGroup?.includes(
                            profileData?.bloodGroup
                          ) && (
                            <ImproveMatchInfo
                              title="Blood Group"
                              subtext={renderValues(
                                profile?.matchPreference?.bloodGroup
                              )}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                  {/* improve match end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportPDF;
