import { put } from "redux-saga/effects";

import ActivityApiCaller from "../../config/api/activityapicaller";

import { ContactViewerAction } from "../actions";

export default class ContactViewerMiddleware {
  static *getContactView({ payload, cb }) {
    try {
      const action =
        payload?.filter === "My Contact Viewers" ? "viewed" : "viewer";

      const res = yield ActivityApiCaller.Get(
        `/activity/contact-view/${action}?limit=${payload.limit ?? ""}&sort=${
          payload.sort ?? ""
        }&lastEvaluatedKey=${payload.lastEvaluatedKey ?? ""}`
      );

      if (res?.status === 200) {
        if (res.data.data?.responseMeta) {
          yield put(
            ContactViewerAction.getContactViewSuccess({
              count: 0,
              data: [],
            })
          );
        } else {
          yield put(
            ContactViewerAction.getContactViewSuccess({
              count: res.data?.count,
              data: res.data?.data,
            })
          );
        }
      } else {
        yield put(ContactViewerAction.getContactViewFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(ContactViewerAction.getContactViewFailure());
    }
  }
  static *getAllContactView({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Get(`/activity/contact-view/viewer`);

      if (res?.status === 200) {
        if (res.data.data?.responseMeta) {
          yield put(
            ContactViewerAction.getAllContactViewSuccess({
              count: 0,
              data: [],
            })
          );
        } else {
          yield put(
            ContactViewerAction.getAllContactViewSuccess({
              count: res.data?.count,
              data: res.data?.data,
            })
          );
        }
      } else {
        yield put(ContactViewerAction.getAllContactViewFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(ContactViewerAction.getAllContactViewFailure());
    }
  }
  static *setContactView({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Post(
        `/activity/contact-view`,
        payload
      );

      if (res.status === 200) {
        yield put(ContactViewerAction.setContactViewSuccess(res.data.Data));
      } else if (res.status === 409) {
        // message.error("Contact already viewed.");
        yield put(ContactViewerAction.setContactViewFailure());
      } else {
        yield put(ContactViewerAction.setContactViewFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      // message.error("Something went wrong");
      yield put(ContactViewerAction.setContactViewFailure());
    }
  }
}
