import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Switch, Table } from "antd";
import moment from "moment";

import { FaCircleCheck } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";

import MembershipPlanLayout from "../../components/layout/membershipplan/membershipplanlayout";
import { PrimaryButton } from "../../components/button";
import NeedHelpCard from "../../components/needhelp/needhelpcard";

import images from "../../assets";

import "./styles.css";

export default function OrderInformation() {
  const location = useLocation();
  const navigate = useNavigate();

  const profileData = useSelector((state) => state.profile?.profile);

  const [autoRenew, setAutoRenew] = useState(false);
  const [promo, setPromo] = useState("");
  const [discount, setDiscount] = useState(0);
  const [promoMessage, setPromoMessage] = useState("");

  const storedData = JSON.parse(localStorage.getItem("membershipPlan"));

  const onChange = (checked) => {
    setAutoRenew(checked);
  };

  const handlePromo = () => {
    setDiscount(0);
    // if (promo === "Fast20") {
    //   setDiscount(20);
    //   setPromoMessage("Promo code applied!");
    // } else {
    setPromoMessage("Invalid promo code!");
    // }
  };

  const handleNext = () => {
    const data = { ...storedData, discount, promo, autoRenew };

    localStorage.setItem("membershipPlan", JSON.stringify(data));
    navigate("/checkout");
  };

  const columns = [
    {
      title: <b>Plan</b>,
      dataIndex: "plan",
      align: "center",
    },
    {
      title: <b>Duration</b>,
      className: "column-money",
      dataIndex: "duration",
      align: "center",
    },
    {
      title: <b>Price</b>,
      dataIndex: "price",
      align: "center",
    },
  ];
  const data = [
    {
      key: "1",
      plan: storedData?.title,
      duration:
        storedData?.title === "Gold"
          ? `${storedData?.validity} (${moment(new Date()).format(
              "DD-MMM-YYYY"
            )} to ${moment().add(3, "months").format("DD-MMM-YYYY")})`
          : storedData?.title === "Silver"
          ? `${storedData?.validity} (${moment(new Date()).format(
              "DD-MMM-YYYY"
            )} to ${moment().add(1, "months").format("DD-MMM-YYYY")})`
          : "Lifetime Deal",
      price: storedData?.amount,
      align: "center",
    },
  ];
  return (
    <>
      <MembershipPlanLayout
        titlename="Order Information"
        breadcrumbs={[
          { label: "Home", route: "/" },
          { label: "Membership Plans", route: "/membership-plan" },
          { label: "Order Information", route: "/order-information" },
        ]}
      >
        <div className="order-billing-info-area">
          <div className="order-billinginfo-container">
            <div className="order-billing-info-content">
              <div className="order-billing-details-area">
                <div className="order-billing-to">
                  <div className="order-billing-to-left">
                    <h2>Billing To</h2>
                    <p>:</p>
                  </div>
                  <div className="order-billing-to-right">
                    <p>
                      {profileData?.firstName} {profileData?.lastName}
                    </p>
                    <p>{profileData?.email}</p>
                    <p>
                      {profileData?.stateProvince}, {profileData?.country}
                    </p>
                    <p>{profileData?.phone}</p>
                  </div>
                </div>

                {storedData?.title !== "Platinum" && (
                  <>
                    <div className="order-billing-to">
                      <div className="order-billing-to-left">
                        <h2>Auto Renew</h2>
                        <p>:</p>
                      </div>
                      <div className="order-billing-to-right">
                        <Switch onChange={onChange} />
                      </div>
                    </div>
                    <p>
                      (If you select “Auto Renew”, there will be 25% discount in
                      the upcoming renewal.)
                    </p>
                  </>
                )}
              </div>
            </div>

            <NeedHelpCard
              style={{ width: "350px" }}
              whatsapplink="https://api.whatsapp.com/send/?phone=447501247364&text=I+want+to+purchase+a+Premium+membership+plan.+Can+you+help+me+with+the+details?"
            />
          </div>
          <div className="orderinfo-membership-plan-area">
            <div className="orderinfo-history-content">
              <div className="orderinfo-history-right">
                <div className="orderinfo-history-area">
                  <div className="orderinfo-history-top">
                    <h2>Promo Code</h2>
                    <p>
                      <b>Note:</b> One promo code can be redeemed per order.
                    </p>
                    <div className="promo-code-area">
                      <div className="flex gap-2 border border-dashed border-secondary rounded-[50px]">
                        <input
                          placeholder="Enter Code"
                          onChange={(e) => {
                            setPromoMessage("");
                            setPromo(e.target.value);
                          }}
                          value={promo}
                          className="outline-none py-2 px-3 bg-transparent flex-1 text-[14px]"
                        />
                        <button
                          className="py-3 px-[30px] rounded-[50px] text-white apply-btn"
                          onClick={handlePromo}
                          disabled={!promo}
                        >
                          Apply
                        </button>
                      </div>
                      {promoMessage && (
                        <div className="promo-code-error">
                          {promoMessage === "Promo code applied!" ? (
                            <FaCircleCheck className="text-green-400 text-[20px]" />
                          ) : (
                            <MdCancel className="text-red-600 text-[20px]" />
                          )}
                          <p>{promoMessage}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="orderinfo-history-content">
                    <div className="orderinfo-table">
                      <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        bordered
                      />
                      <div className="subtotal-box-area">
                        <div className="subtotal-box-column">
                          <p>Subtotal</p>
                          <p className="w-[70px] text-right">
                            {storedData?.amount}
                          </p>
                        </div>

                        <div className="subtotal-box-column">
                          <p>Discount</p>
                          <p className="w-[70px] text-right">{discount}</p>
                        </div>
                        <div className="divider"></div>
                        <div className="total-box-column">
                          <p>Total</p>
                          <p className="w-[70px] text-right">
                            {/* {parseFloat(
                              parseFloat(storedData?.price) -
                                parseFloat(discount)
                            ).toFixed(2)} */}
                            {storedData?.amount}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="orderinfo-btn">
                      <PrimaryButton
                        onClick={handleNext}
                        style={{
                          fontSize: "16px",
                          width: "260px",
                          fontWeight: 500,
                        }}
                        iconalign="right"
                        text={"Continue"}
                        icon={images.arrowIconRight}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MembershipPlanLayout>
    </>
  );
}
