import { put } from "redux-saga/effects";
import { message } from "antd";

import ActivityApiCaller from "../../config/api/activityapicaller";

import { BlocklistAction } from "../actions";

export default class BlocklistMiddleware {
  static *getBlocklist({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Get(
        `/activity/block-profile/sender?limit=${payload.limit ?? ""}&sort=${
          payload.sort ?? ""
        }&lastEvaluatedKey=${payload.lastEvaluatedKey ?? ""}`
      );

      if (res?.status === 200) {
        yield put(
          BlocklistAction.getBlocklistSuccess({
            count: res.data?.count,
            data: res.data?.data,
          })
        );
      } else {
        yield put(BlocklistAction.getBlocklistFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(BlocklistAction.getBlocklistFailure());
    }
  }
  static *deleteBlocklist({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Delete(
        `/activity/block-profile/${payload.blockedId}`
      );
      if (res.status === 200) {
        yield put(BlocklistAction.deleteBlocklistSuccess(res.data.Data));
      } else {
        // message.error("Something went wrong while unblocking.");
        yield put(BlocklistAction.deleteBlocklistFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      // message.error("Something went wrong while unblocking.");
      yield put(BlocklistAction.deleteBlocklistFailure());
    }
  }
  static *updateBlocklist({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Post(
        `/activity/block-profile`,
        payload
      );
      if (res.status === 200) {
        message.success("Blocked successfully.");
        yield put(BlocklistAction.updateBlocklistSuccess(res.data.Data));
      } else if (res.status === 409) {
        // message.error("This user is already blocked");
        yield put(BlocklistAction.updateBlocklistFailure());
      } else {
        // message.error("Something went wrong while blocking.");
        yield put(BlocklistAction.updateBlocklistFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      // message.error("Something went wrong while updating blocklist.");
      yield put(BlocklistAction.updateBlocklistFailure());
    }
  }
}
