import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Empty } from "antd";
import moment from "moment";

import { PhotoreqAction } from "../../store/actions";
import useScreenSize from "../../utils/usescreensize";
import ProfileTab from "../../components/tab/profiletab";
import DashboardCard from "../../components/dashboardcard/dashboardcard";
import { PrimaryButton } from "../../components/button";

import SortDropdown from "../../components/dropdown/sortdropdown";

import Cardloader from "../../components/loader/cardloader";
import Spacer from "../../components/spacer/spacer";

import "./styles.css";
import UnderHeaderMenu from "../../components/underheadermenu";

const PhotoRequests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const { photoreq, isLoading, photoreqCount } = useSelector(
    (state) => state.photoreq
  );

  const [paginationStack, setPaginationStack] = useState([null]);
  const [state, setState] = useState({
    activeTab: "Pending",
    sortOption: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  useEffect(() => {
    setPaginationStack([null]);
    getPhotoreq([null]);
  }, [state]);

  const getPhotoreq = (prevKey) => {
    const payload = {
      filter: state?.activeTab,
      limit: pageSize,
      sort: state?.sortOption,
      lastEvaluatedKey: prevKey,
    };
    setPageNo(1);
    dispatch(
      PhotoreqAction.getPhotoreq(payload, (res) => {
        const exists = paginationStack?.some(
          (item) =>
            item?.username["S"] === res.data.lastEvaluatedKey?.username["S"]
        );
        if (!exists) {
          setPaginationStack([...paginationStack, res.data.lastEvaluatedKey]);
        }
      })
    );
  };

  const handleTab = (name) => {
    setPaginationStack([null]);
    setState({ ...state, activeTab: name });
  };

  const handlePageChange = async (pageNumber) => {
    setPageNo(pageNumber);
    if (pageNumber < pageNo) {
      await goBack();
    } else {
      getPhotoreq(paginationStack[pageNumber - 1]);
    }
  };
  const goBack = async () => {
    if (paginationStack.length > 1) {
      const prevKey = paginationStack[pageNo - 2];
      setPaginationStack(paginationStack.slice(0, -1));
      getPhotoreq(prevKey);
    }
  };

  const handleUpdatePhotoreq = (id, status) => {
    const payload = { senderId: id, status };

    dispatch(
      PhotoreqAction.updatePhotoreq(payload, () => {
        getPhotoreq();
      })
    );
  };

  const tabItems = [
    { name: "Pending", title: "Pending" },
    { name: "Allowed", title: "Allowed" },
  ];

  return (
    <div>
      {screenSize.width < 1023 && (
        <UnderHeaderMenu titlename="Photo Requests">
          <div style={{ width: "110px" }}>
            <SortDropdown
              data={[
                { label: "Newest First", value: "desc" },
                { label: "Oldest First", value: "asc" },
              ]}
              onChange={(e) => setState({ ...state, sortOption: e })}
              value={state?.sortOption || undefined}
              placeholder="Sort by"
            />
          </div>
        </UnderHeaderMenu>
      )}
      <Spacer height="16px" />
      <div className="photoreq-section-top-part-container">
        <div></div>
        <div className="photoreq-section-header-container">
          {screenSize.width > 1023 && (
            <h1 className="photoreq-header">Photo Requests</h1>
          )}
          <ProfileTab
            tabItems={tabItems}
            handleTab={handleTab}
            activeTab={state?.activeTab}
            style={{
              background: "white",
              border: "1px solid #EAE6ED",
              width: "160px",
            }}
          />
        </div>
        {screenSize.width > 1023 && (
          <div className="photoreq-sort-container">
            <div style={{ width: "150px" }}>
              <SortDropdown
                data={[
                  { label: "Newest First", value: "desc" },
                  { label: "Oldest First", value: "asc" },
                ]}
                onChange={(e) => setState({ ...state, sortOption: e })}
                value={state?.sortOption || undefined}
                placeholder="Sort by"
              />
            </div>
          </div>
        )}
      </div>
      <Spacer height="12px" />
      <div className="photoreq-results-container">
        {isLoading ? (
          <Cardloader />
        ) : (
          <>
            {photoreq?.length > 0 ? (
              <>
                <div className="photoreq-results">
                  {photoreq?.map((item, index) => (
                    <DashboardCard
                      item={item}
                      index={index}
                      key={index}
                      redirectedFrom="photoRequests"
                    >
                      <div className="flex flex-col w-full gap-3">
                        {state?.activeTab === "Pending" && (
                          <div className="photoreq-card-text">
                            <p>Pending Since:</p>
                            <p>{moment(item?.updatedAt).fromNow()}</p>
                          </div>
                        )}
                        {state?.activeTab === "Allowed" && (
                          <div className="photoreq-card-text">
                            <p>Allowed on:</p>
                            <p>{moment(item?.updatedAt).fromNow()}</p>
                          </div>
                        )}
                        {state?.activeTab === "Pending" ? (
                          <div className="interest-mobile-btn">
                            <PrimaryButton
                              style={{
                                background: "#F31260",
                              }}
                              text="Deny"
                              onClick={() =>
                                handleUpdatePhotoreq(item?.username, "rejected")
                              }
                            />
                            <PrimaryButton
                              style={{
                                background: "#17C964",
                              }}
                              text="Allow"
                              onClick={() =>
                                handleUpdatePhotoreq(item?.username, "accepted")
                              }
                            />
                          </div>
                        ) : (
                          <PrimaryButton
                            style={{
                              background: "#F5A524",
                              height: "32px",
                              fontSize: "14px",
                            }}
                            onClick={() =>
                              handleUpdatePhotoreq(item?.username, "rejected")
                            }
                            text="Remove Access"
                          />
                        )}
                      </div>
                    </DashboardCard>
                  ))}
                </div>
                <Spacer height="36px" />
                <div className="flex justify-center items-center">
                  <Pagination
                    simple={{ readOnly: true }}
                    pageSize={pageSize}
                    total={photoreqCount}
                    current={pageNo}
                    showSizeChanger={false}
                    onChange={handlePageChange}
                  />
                </div>
                <Spacer height="24px" />
              </>
            ) : (
              <Empty description={<p>No Results found</p>} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PhotoRequests;
