export default function QuestionSection(props) {
  return (
    <>
      <div {...props} className="w-full flex flex-col">
        <h2 className="text-xl text-center lg:text-left leading-7 font-semibold text-basePrimary">
          {props.heading}
        </h2>
      </div>
      <div className="w-full flex flex-col rounded-md px-4 py-2.5 shadow-md">
        {props.children}
      </div>
    </>
  );
}
