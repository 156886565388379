import { put } from "redux-saga/effects";
import { message } from "antd";

import ActivityApiCaller from "../../config/api/activityapicaller";

import { ShortlistAction } from "../actions";

export default class ShortlistMiddleware {
  static *getShortlist({ payload, cb }) {
    try {
      const action =
        payload?.filter === "Shortlisted Me" ? "receiver" : "sender";

      const res = yield ActivityApiCaller.Get(
        `/activity/shortlist/${action}?limit=${payload.limit ?? ""}&sort=${
          payload.sort ?? ""
        }&lastEvaluatedKey=${payload.lastEvaluatedKey ?? ""}`
      );

      if (res?.status === 200) {
        yield put(
          ShortlistAction.getShortlistSuccess({
            count: res.data?.count,
            data: res.data?.data,
          })
        );
      } else {
        yield put(ShortlistAction.getShortlistFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(ShortlistAction.getShortlistFailure());
    }
  }
  static *getShortlistAll({ payload, cb }) {
    try {
      const action = "sender";

      const res = yield ActivityApiCaller.Get(`/activity/shortlist/${action}`);

      if (res?.status === 200) {
        yield put(
          ShortlistAction.getShortlistAllSuccess({
            count: res.data?.count,
            data: res.data?.data,
          })
        );
      } else {
        yield put(ShortlistAction.getShortlistAllFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(ShortlistAction.getShortlistAllFailure());
    }
  }
  static *sendShortlist({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Post("/activity/shortlist", payload);

      if (res.status === 200) {
        message.success("Shortlisted succesfully.");
        yield put(ShortlistAction.sendShortlistSuccess(res.data.Data));
        yield put(
          ShortlistAction.getShortlist({
            filter: "Shortlisted by Me",
          })
        );
      } else if (res.status === 409) {
        message.error("Already shortlisted.");
        yield put(ShortlistAction.sendShortlistFailure());
      } else {
        // message.error("Could not shortlist.");
        yield put(ShortlistAction.sendShortlistFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      // message.error("Something went wrong while shortlisting");
      yield put(ShortlistAction.sendShortlistFailure());
    }
  }
}
