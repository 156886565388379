import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Switch, Table } from "antd";
import moment from "moment";

import Loader from "../../components/loader/loader";
import UpgradeButton from "../../components/layout/dashboard/upgrade/upgradebutton";
import MembershipPlanCard from "../../components/membershipplan/membershipplancard";

import images from "../../assets";
const ExtendMembershipCard = ({ subdata }) => {
  const navigate = useNavigate();

  const profileData = useSelector((state) => state.profile?.profile);

  const onChange = (checked) => {};

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
    },
    {
      title: "Membership Details",
      className: "column-money",
      dataIndex: "membershipDetails",
      align: "center",
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      align: "center",
    },
  ];
  const data = [
    {
      key: "1",
      date: moment(subdata?.plan?.updatedAt).format("MMM DD, YYYY"),
      membershipDetails:
        `${
          subdata?.plan?.name === "gold"
            ? "Gold - 3 Months"
            : subdata?.plan?.name === "silver"
            ? "Silver - 30 Days"
            : "Lifetime"
        }` +
        (subdata?.plan?.name !== "platinum"
          ? ` (${moment(subdata?.plan?.updatedAt).format(
              "DD-MMM-YYYY"
            )} to ${moment(subdata?.plan?.expireDate).format("DD-MMM-YYYY")})`
          : ""),

      receipt: <div className="download-receipt">Download</div>,
      align: "center",
    },
  ];

  const planInfo = [
    { title: "Plan Name", value: subdata?.plan?.name },
    {
      title: "Price",
      value: subdata?.plan?.name
        ? profileData?.country === "Bangladesh"
          ? `৳${subdata?.plan?.bangladesh}`
          : `$${subdata?.plan?.amount}`
        : "FREE",
    },
    {
      title: "Duration",
      value:
        subdata?.plan?.name === "gold"
          ? `3 Months (${moment(subdata?.plan?.updatedAt).format(
              "DD-MMM-YYYY"
            )} to ${moment(subdata?.plan?.expireDate).format("DD-MMM-YYYY")})`
          : subdata?.plan?.name === "silver"
          ? `1 Month (${moment(subdata?.plan?.updatedAt).format(
              "DD-MMM-YYYY"
            )} to ${moment(subdata?.plan?.expireDate).format("DD-MMM-YYYY")})`
          : "Lifetime Deal",
    },
    {
      title: "Contact Limit",
      value: subdata?.plan?.name
        ? subdata?.plan?.name === "platinum"
          ? `${
              profileData?.activePlan?.contactViewLimit &&
              `${profileData?.activePlan?.contactViewLimit} left`
            }`
          : subdata?.plan?.name === "gold"
          ? `${
              profileData?.activePlan?.contactViewLimit &&
              `${profileData?.activePlan?.contactViewLimit} left`
            }`
          : `${
              profileData?.activePlan?.contactViewLimit &&
              `${profileData?.activePlan?.contactViewLimit} left`
            }`
        : "0",
    },
    {
      title: "Message Limit",
      value: "Unlimited",
    },
    {
      title: "Expires",
      value:
        subdata?.plan?.name && subdata?.plan?.name !== "platinum"
          ? moment(subdata?.plan?.expireDate).format("DD-MMM-YYYY")
          : "Lifetime",
    },
    {
      title: "Payment Method",
      value: subdata?.plan?.name
        ? subdata?.plan?.paymentGateway === "stripe"
          ? "Visa / Credit Card"
          : subdata?.plan?.paymentGateway === "bkash"
          ? "bKash"
          : "SSL Commerz"
        : "Not used yet",
    },
  ];

  return (
    <>
      <div className="billing-info-area">
        <div className="billing-info-content">
          <div className="billing-heading">
            <h3>My Billing Info</h3>
            <p>Manage billing information and view receipts</p>
          </div>
          {subdata?.isLoading ? (
            <Loader />
          ) : (
            <div className="current-plan-content">
              <p className="capitalize">
                Current Plan: <span>{subdata?.plan?.name}</span>
              </p>
              <p className="capitalize">
                Days left:{" "}
                {/* <span>
                  {moment(subdata?.plan?.expireDate)}{" "}
                  {subdata?.plan?.duration !== "lifetime" && "days"}
                </span> */}
                <span>
                  {subdata?.plan?.duration !== "lifetime" && (
                    <span>
                      {moment(subdata?.plan?.expireDate).diff(moment(), "days")}{" "}
                      days
                    </span>
                  )}
                </span>
              </p>
              {subdata?.plan?.name !== "platinum" && (
                <p>
                  Auto renew: <span>Off</span>
                  <Switch onChange={onChange} />
                </p>
              )}
              {subdata?.plan?.name !== "platinum" && (
                <p>
                  <span>
                    (If you select “Auto Renew”, there will be
                    <br /> 25% discount in the upcoming renewal.)
                  </span>
                </p>
              )}
            </div>
          )}
          {subdata?.plan?.name !== "platinum" && (
            <div className="extend-membership">
              <UpgradeButton
                onClick={() => navigate("/membership-plan")}
                className="premium extend-membership-btn"
                title="Extend Membership"
                disscunt="25% OFF"
              />
            </div>
          )}
        </div>
        <div
          style={{
            background: `url(${images.membershipBg})`,
            backgroundSize: "100% 100%",
          }}
          className="membership-plan-area"
        >
          <div className="membership-plan-heading">
            <h1>Membership Plan Details</h1>
          </div>
          <div className="membership-plan-content">
            <div className="membership-plan-left">
              {planInfo?.map((item, index) => (
                <MembershipPlanCard
                  key={index}
                  titlename={item?.title}
                  values={item?.value}
                />
              ))}
            </div>
            <div className="membership-plan-logo">
              <img src={images.membershipPlanIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="order-history-area">
        <h2>Order History</h2>
        <div className="order-history-content">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
          />
        </div>
      </div>
    </>
  );
};

export default ExtendMembershipCard;
