import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
} from "../constants";

export default class CountriesAction {
  static getCountries(cb) {
    return {
      type: GET_COUNTRIES,
      cb,
    };
  }
  static getCountriesSuccess(payload) {
    return {
      type: GET_COUNTRIES_SUCCESS,
      payload,
    };
  }
  static getCountriesFailure() {
    return {
      type: GET_COUNTRIES_FAILURE,
    };
  }
  static getStates(payload, cb) {
    return {
      type: GET_STATES,
      payload,
      cb,
    };
  }
  static getStatesSuccess(payload) {
    return {
      type: GET_STATES_SUCCESS,
      payload,
    };
  }
  static getStatesFailure() {
    return {
      type: GET_STATES_FAILURE,
    };
  }
}
