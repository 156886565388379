import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AuthAction } from "../../store/actions";
import EmailForm from "../changepassword/emailform";
import OtpForm from "../changepassword/otpform";

import { LoginLayout } from "../../components/layout";

import "../changepassword/styles.css";

const containsNonNumericCharacters = (str) => {
  return /\D/.test(str);
};

export default function ChangeEmailMigrated() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState("email");

  const [state, setState] = useState({
    email: "",
    otp: "",
    error: "",
  });

  const handleEmailSubmit = () => {
    if (!state.email) {
      setState({ ...state, error: "Email is required" });
    } else {
      setState({ ...state, error: "" });
      const payload = { email: state?.email };
      dispatch(
        AuthAction.emailChangeReq(payload, (res) => {
          if (res === 200) {
            setPage("otp");
          }
        })
      );
    }
  };

  const handleOtpSubmit = () => {
    if (!state.otp) {
      setState({ ...state, error: "Enter OTP" });
    } else if (containsNonNumericCharacters(state.otp)) {
      setState({ ...state, error: "OTP must be a number" });
    } else {
      setState({ ...state, error: "" });
      const payload = { code: state.otp, email: state?.email };
      dispatch(
        AuthAction.emailChangeVerify(payload, (res) => {
          if (res === 200) {
            navigate("/login");
          }
        })
      );
    }
  };
  const handleResend = () => {
    const payload = { username: state?.email };
    dispatch(AuthAction.resendOtp(payload));
  };
  return (
    <>
      <LoginLayout>
        <div className="w-[300px] lg:w-[450px]">
          <div className="forgot-password-container">
            {page === "otp" ? (
              <OtpForm
                state={state}
                setState={setState}
                page={page}
                setPage={setPage}
                handleOtpSubmit={handleOtpSubmit}
                handleResend={handleResend}
              />
            ) : (
              <EmailForm
                state={state}
                setState={setState}
                page={page}
                setPage={setPage}
                handleEmailSubmit={handleEmailSubmit}
                redirectedFrom={"migrated"}
              />
            )}
          </div>
        </div>
      </LoginLayout>
    </>
  );
}
