import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import { ProfileAction, CountriesAction } from "../../store/actions";

import CountryForm from "./countryform";
import TrustBadgeForm from "./trustbadgeform";

import "./styles.css";

const CountryChangeReq = (props) => {
  const dispatch = useDispatch();

  const trustbadgeStatus = useSelector(
    (state) => state?.profile?.profile?.trustbadgeStatus
  );

  const [state, setState] = useState({
    country: "",
    stateProvince: "",
    reason: "",
  });

  useEffect(() => {
    if (!state?.country) return;

    const payload = { country: [state?.country] };
    dispatch(CountriesAction.getStates(payload));
  }, [state?.country]);

  const handleCountry = (other) => {
    if (!state.country) {
      message.error("Country is required");
    } else if (!state.stateProvince) {
      message.error("State / Province is required");
    } else if (!state.reason) {
      message.error("Reason is required");
    } else if (state.reason === "Other" && other?.otherReason === "") {
      message.error("Reason is required");
    } else {
      setState({ ...state, error: "" });
      const payload = {
        data: {
          country: state?.country,
          stateProvince: state?.stateProvince,
          type: "countrychange",
          reason:
            other?.otherReason !== "" ? other?.otherReason : state?.reason,
        },
      };

      dispatch(ProfileAction.changeRequest(payload));

      props?.setIsCountryModalOpen(false);
    }
  };

  return (
    <div className="forgot-password-container">
      {trustbadgeStatus === undefined ||
      trustbadgeStatus === null ||
      trustbadgeStatus === "" ||
      trustbadgeStatus === "false" ||
      trustbadgeStatus === "rejected" ? (
        <TrustBadgeForm />
      ) : (
        <CountryForm
          state={state}
          setState={setState}
          handleCountry={(e) => handleCountry(e)}
        />
      )}
    </div>
  );
};

export default CountryChangeReq;
