import images from "../../assets";

import "./styles.css";

export default function MyGallery(props) {
  return (
    <div className="my-photo-card">
      <div style={{ justifyContent: "center" }} className="my-photo-text">
        <span>{props?.othersProfileData?.firstName}'s Photos</span>
      </div>
      <div className="my-photo-gallery">
        <div className="main-photo-gallery">
          {props?.othersProfileData?.imageGallery?.map((item, index) => (
            <div key={index} className="galley-images">
              <img
                src={item.url}
                alt="Gallery Images"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    props?.othersProfileData?.gender === "male"
                      ? images.defaultMaleIcon
                      : images.defaultFemaleIcon;
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
